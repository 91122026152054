export const SET_AUTH = 'set_auth';
export const LOGOUT = 'LOGOUT';
export const CHANGE_SITE = 'CHANGE_SITE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const EVENTS_LOADED = 'events_loaded';
export const EVENTS_UPDATED = 'EVENTS_UPDATED';
export const EVENT_REMOVED = 'event_removed';
export const EVENTS_MANAGED_LOADED = 'EVENTS_MANAGED_LOADED';
export const AVAILABLE_EVENTS_LOADED = 'AVAILABLE_EVENTS_LOADED';
export const EVENT_SUBMISSIONS_LOADED = 'EVENT_SUBMISSIONS_LOADED';
export const EVENT_SUBMISSION_REMOVED = 'EVENT_SUBMISSION_REMOVED';
export const EVENTS_PURGE = 'events_purge';
export const EVENT_DASHBOARD_LOADING = 'EVENT_DASHBOARD_LOADING';
export const EVENT_SUBMISSIONS_DASHBOARD_LOADING = 'EVENT_SUBMISSIONS_DASHBOARD_LOADING';

export const FEEDBACK_LOADED = 'feedback_loaded';
export const FEEDBACK_REMOVED = 'feedback_removed';

export const POLLS_LOADED = 'polls_loaded';
export const POLL_REMOVED = 'poll_removed';
export const POLL_UPDATED = 'poll_updated';

export const DISHES_LOADED = 'DISHES_LOADED';
export const UPDATE_DISH = 'UPDATE_DISH';
export const DISH_REMOVED = 'DISH_REMOVED';
export const MENU_REMOVED = 'menu_removed';

export const NEWS_LOADED = 'NEWS_LOADED';
export const NEWS_UPDATED = 'NEWS_UPDATED';
export const NEWS_REMOVED = 'NEWS_REMOVED';
export const NEWS_SUBMISSIONS_LOADED = 'NEWS_SUBMISSIONS_LOADED';
export const NEWS_SUBMISSION_REMOVED = 'NEWS_SUBMISSION_REMOVED';
export const NEWS_PURGE = 'NEWS_PURGE';
export const NEWS_DASHBOARD_LOADING = 'NEWS_DASHBOARD_LOADING';
export const NEWS_SUBMISSIONS_DASHBOARD_LOADING = 'NEWS_SUBMISSIONS_DASHBOARD_LOADING';

export const TICKETS_LOADED = 'TICKETS_LOADED';

export const SET_LOCAL = 'SET_LOCAL';
export const ADD_RECENTLY_CREATED = 'ADD_RECENTLY_CREATED';

export const JOBS_LOADED = 'JOBS_LOADED';
export const JOBS_PURGE = 'JOBS_PURGE';
export const JOBS_LOADING = 'JOBS_LOADING';

export const KEYS_LOADED = 'KEYS_LOADED';
export const KEY_REMOVED = 'KEY_REMOVED';
export const KEYS_PURGE = 'KEYS_PURGE';

export const USERS_LOADED = 'USERS_LOADED';
export const USER_REMOVED = 'USER_REMOVED';
export const USERS_PURGE = 'USERS_PURGE';
export const USERS_LOADING = 'USERS_LOADING';
export const USERS_RESIDENT_COUNT_SET = 'USERS_RESIDENT_COUNT_SET';

export const INVITE_CODES_LOADED = 'INVITE_CODES_LOADED';
export const INVITE_CODE_UPDATED = 'INVITE_CODE_UPDATED';
export const INVITE_CODE_REMOVED = 'INVITE_CODE_REMOVED';

export const CONTACTS_LOADED = 'CONTACTS_LOADED';
export const CONTACT_REMOVED = 'CONTACT_REMOVED';
export const CONTACTS_PURGE = 'CONTACTS_PURGE';

export const MAPS_LOADED = 'MAPS_LOADED';
export const MAP_REMOVED = 'MAP_REMOVED';
export const MAPS_PURGE = 'MAPS_PURGE';

export const OFFERS_LOADED = 'OFFERS_LOADED';
export const OFFER_REMOVED = 'OFFER_REMOVED';
export const OFFERS_PURGE = 'OFFERS_PURGE';

export const INFOPAGES_LOADED = 'INFOPAGES_LOADED';
export const INFOPAGE_REMOVED = 'INFOPAGE_REMOVED';
export const INFOPAGES_PURGE = 'INFOPAGES_PURGE';

export const WELCOMEPAGES_LOADED = 'WELCOMEPAGES_LOADED';
export const WELCOMEPAGE_REMOVED = 'WELCOMEPAGE_REMOVED';
export const WELCOMEPAGES_PURGE = 'WELCOMEPAGES_PURGE';

export const SERVICE_REMOVED = 'SERVICE_REMOVED';
export const SERVICES_LOADED = 'SERVICES_LOADED';
export const SERVICES_UPDATED = 'SERVICES_UPDATED';
export const SERVICES_DASHBOARD_LOADING = 'SERVICES_DASHBOARD_LOADING';

export const ALERT_REMOVED = 'ALERT_REMOVED';
export const ALERTS_LOADED = 'ALERTS_LOADED';
export const ALERTS_UPDATED = 'ALERTS_UPDATED';
export const ALERTS_DASHBOARD_LOADING = 'ALERTS_DASHBOARD_LOADING';

export const IMAGE_LIBRARY_DASHBOARD_LOADING = 'IMAGE_LIBRARY_DASHBOARD_LOADING';
export const IMAGE_LIBRARY_LOADED = 'IMAGE_LIBRARY_LOADED';
export const IMAGE_FOLDER_UPDATED = 'IMAGE_FOLDER_UPDATED';
export const IMAGE_FOLDER_REMOVED = 'IMAGE_FOLDER_REMOVED';

export const FACILITIES_LOADING = 'FACILITIES_LOADING';
export const FACILITIES_LOADED = 'FACILITIES_LOADED';
export const FACILITY_REMOVED = 'FACILITY_REMOVED';
export const FACILITIES_PURGE = 'FACILITIES_PURGE';

export const SET_NAV_DATA = 'SET_NAV_DATA';

export const DEVICES_LOADED = 'DEVICES_LOADED';
export const DEVICE_REMOVED = 'DEVICE_REMOVED';
export const DEVICE_VIEWS_LOADED = 'DEVICE_VIEWS_LOADED';
export const DEVICES_PURGE = 'DEVICES_PURGE';

export const FORMS_LOADED = 'FORMS_LOADED';
export const FORM_REMOVED = 'FORM_REMOVED';
export const FORM_UPDATED = 'FORM_UPDATED';
export const FORM_SUBMISSIONS_LOADED = 'FORM_SUBMISSIONS_LOADED';

export const WHITE_LABEL_LOADED = 'WHITE_LABEL_LOADED';
export const WHITE_LABEL_ADDED = 'WHITE_LABEL_ADDED';
export const WHITE_LABEL_PURGE = 'WHITE_LABEL_PURGE';
export const WHITE_LABEL_APP_SET = 'WHITE_LABEL_APP_SET';

export const UPDATE_STRINGS = 'UPDATE_STRINGS';
