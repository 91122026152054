import React, { Component } from 'react';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import _ from 'lodash';
import { connect } from 'react-redux';
import { GenericInput, DropdownInput, Button } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded, whiteLabelReset } from '../../actions';
import { isTheBest, getApiError } from '../../session';
import {
  renderTitle,
  renderDescription,
  renderOpenLinkStep,
  renderTextStep,
  renderImageStep,
  renderEmbed,
  renderCopyToClipboardText,
  getWhiteLabelAppUser,
} from './helper';
import { automationConfig } from '../../config';

const REVIEW_USER_PREFIX = 'apple';

class AppSetupiOS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabelInfo: _.cloneDeep(props.activeWhiteLabel),
      selectedApp: '',
      loading: false,
      loadingMessage: '',
      resetMessage: '',
      message: {},
    };
    this.steps = [
      { label: 'Select App', name: '0', render: this.renderStep1 },
      { label: 'Build IPA', name: '1', render: this.renderStep2 },
      { label: 'Set up iOS App', name: '2', render: this.renderStep3 },
      { label: 'Upload IPA', name: '3', render: this.renderStep4 },
      { label: 'Set up Review User', name: '4', render: this.renderStep5 },
      { label: 'Submit for Review', name: '5', render: this.renderStep6 },
    ];
  }

  componentDidMount() {
    // TEST: Preset environment
    // this.setState({ whiteLabelInfo: { ClientCode: 'unittesting' } }, this.onLoadWhiteLabel);
  }

  componentDidUpdate(prevProps) {
    const { activeWhiteLabel } = this.props;
    if (!_.isEqual(prevProps.activeWhiteLabel, activeWhiteLabel)) {
      this.setState({ whiteLabelInfo: activeWhiteLabel });
    }
  }

  getAppBranchName = (defaultName = '{MISSING_NAME}') => {
    const { selectedApp, whiteLabelInfo } = this.state;
    return selectedApp || whiteLabelInfo.ClientCode || defaultName;
  };

  getAppBundleId = () => this.getAppBranchName().replace('-', '');

  getAppProperty = (key) => {
    const { selectedApp, whiteLabelInfo } = this.state;
    const app = whiteLabelInfo.Apps && whiteLabelInfo.Apps.find((a) => a.ClientCode === selectedApp || whiteLabelInfo.ClientCode);
    return app ? app[key] : null;
  };

  onMoveStep = (stepIndex) => {
    if (this.props.onMoveStep) this.props.onMoveStep(stepIndex);
  };

  onBranchNameChanged = (event) => {
    const { whiteLabelInfo } = this.state;
    const newInfo = _.cloneDeep(whiteLabelInfo);
    newInfo.ClientCode = event.target.value.replace(' ', '-');
    this.setState({ whiteLabelInfo: newInfo });
  };

  onBranchChanged = (key) => {
    this.setState({ selectedApp: key, resetMessage: '' });
  };

  onClearClientCode = () => {
    this.props.whiteLabelReset();
    this.setState({ loading: false, loadingMessage: '', resetMessage: '', selectedApp: '' });
  };

  onLoadWhiteLabel = () => {
    const { whiteLabelInfo } = this.state;
    const { activeWhiteLabel } = this.props;
    const clientCode = whiteLabelInfo.ClientCode;
    if (clientCode === activeWhiteLabel.ClientCode) return;
    if (!clientCode) {
      this.onClearClientCode();
      return;
    }

    let loadingMessage = `Loading ${clientCode} details...`;
    this.setState({ loading: true, loadingMessage, resetMessage: '' }, async () => {
      try {
        const { data } = await automationActions.getWhiteLabel(clientCode);
        const selectedApp = data ? data.ClientCode : '';
        // console.log('onLoadWhiteLabel', data, 'selectedApp', selectedApp);
        loadingMessage = data ? `Details for ${data.ClientName} has been loaded` : `${clientCode} does not exist`;
        this.setState({ loading: false, loadingMessage, selectedApp }, () => {
          if (data) {
            this.props.whiteLabelLoaded(data);
          } else {
            this.props.whiteLabelReset();
          }
        });
      } catch (error) {
        this.setState({ loading: false, loadingMessage: getApiError(error).message });
      }
    });
  };

  onResetUser = () => {
    const { whiteLabelInfo, selectedApp } = this.state;
    this.setState({ loading: true, resetMessage: 'Setting up review user...' }, async () => {
      try {
        const { data } = await automationActions.resetTestUser(
          whiteLabelInfo.RowId,
          selectedApp || whiteLabelInfo.ClientCode,
          REVIEW_USER_PREFIX,
        );
        // console.log('onResetUser', data);
        this.setState({ loading: false, resetMessage: 'Review user has been set up successfully' }, () => {
          if (data) this.props.whiteLabelLoaded(data);
        });
      } catch (error) {
        this.setState({ loading: false, resetMessage: getApiError(error).message });
      }
    });
  };

  renderBranchInput = () => {
    const { whiteLabelInfo, loading, loadingMessage } = this.state;
    const { activeWhiteLabel } = this.props;
    const branchName = whiteLabelInfo ? whiteLabelInfo.ClientCode : '';
    const isBranchValid = !_.isEmpty(branchName);
    const canClear = isBranchValid && activeWhiteLabel && activeWhiteLabel.ClientCode === branchName;

    return (
      <div className="flex flex-row flex-center">
        <GenericInput
          className="marginTop-10"
          id="branch"
          style={{ width: 300 }}
          type="text"
          placeholder="Client code"
          isValid={() => isBranchValid}
          value={branchName}
          onChange={this.onBranchNameChanged}
          onBlur={this.onLoadWhiteLabel}
          isRequired
        />
        <Button
          style={{ width: 90 }}
          className="marginLeft-24"
          inline
          buttonType="primary"
          onClick={canClear ? this.onClearClientCode : null}
          isActive={branchName && !loading}
        >
          {canClear ? 'Clear' : 'Load'}
        </Button>
        <div className="marginLeft-16">{loadingMessage}</div>
      </div>
    );
  };

  renderSelectApp = () => {
    const { whiteLabelInfo, loading, selectedApp } = this.state;
    const branches = whiteLabelInfo.Apps
      ? whiteLabelInfo.Apps.map((b) => {
          return { Title: b.ClientCode, Key: b.ClientCode };
        })
      : [];

    return (
      <DropdownInput
        id={`dropdown_app`}
        style={{ width: 250, marginBottom: 'unset', marginTop: 10 }}
        placeholder="Select App"
        value={this.getAppBranchName('')}
        options={branches}
        onSelect={this.onBranchChanged}
        disabled={loading}
      />
    );
  };

  renderStep1 = () => {
    return (
      <div>
        {renderTitle('Select App')}
        {renderDescription('Select iOS app to build.')}
        <ol>
          {renderTextStep(
            <div>
              Enter client code (e.g. aveo)
              {this.renderBranchInput()}
            </div>,
          )}
          {renderTextStep(
            <div>
              Select app
              {this.renderSelectApp()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep2 = () => {
    return (
      <div>
        {renderTitle('Build IPA')}
        {renderDescription(
          'In this step, we will build an IPA file to upload. The following steps assume that you have Docker Desktop installed and have the plussdev/default image.',
        )}
        <ol>
          {renderTextStep(
            <div>
              For detailed instructions on how to set up the Docker Desktop for building the app, refer to{' '}
              <a href="https://docs.google.com/document/d/1bxv7Xvy_K4Xk16L0bolRzIsLj6EtXC8O6sHgXt5Bxr4/edit?usp=sharing" target="_blank">
                Building and Uploading Apps
              </a>
            </div>,
          )}
          {renderImageStep(
            <div>
              Ensure you are running a docker container from the <b>plussdev/default</b> image then click <b>Run</b> button without changing
              any settings.
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Run+Docker+Image.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on the <b>Containers/Apps</b> menu on the left and then click the CLI button to open the command prompt
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Open+Command+Prompt.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              Ensure you are in <b>minuss-expo</b> folder. If not, switch to the folder by typing,
              {renderEmbed('cd minuss-expo')}
            </div>,
          )}
          {renderTextStep(
            <div>
              Start building the ipa by typing,
              {renderEmbed(`npm run build ${this.getAppBranchName()} ios`)}
              This will get the latest source code and install necessary packages required for the build.
            </div>,
          )}
          {renderImageStep(
            <div>
              Log in to Expo
              <ol className="marginBottom-16">
                <li>
                  When asked <b>Do you want to log in to your Apple account?</b>, enter <b>Y</b>
                </li>
                <li>
                  Enter <b>Apple ID</b>
                </li>
                <li>
                  Enter <b>Password</b> and validate Code for Two-factor Authentication
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Build+IPA/4+-+Log+In.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Select Team
              <ol className="marginBottom-16">
                <li>
                  Select <b>Team</b> - <b>Pluss Communities PTY LTD</b>
                </li>
                <li>
                  Select <b>Provider</b> - <b>Pluss Communities PTY LTD</b>
                </li>
                <li>
                  Ensure that <b>Bundle identifier: com.pluss.{this.getAppBundleId()}</b> is registered
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Build+IPA/5+-+Team.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Select Certificates and Build
              <ol className="marginBottom-16">
                <li>
                  When prompted, enter <b>Y</b> to select the default distribution certificate (
                  <b>Cert ID: DA3TU2FXX5, Serial number: 362CE685EF27F4BD801AB19098B6D26A, Team ID: MD49LE9D3X</b>)
                </li>
                <li>
                  When prompted, enter <b>Y</b> to generate a new Apple Provisioning Profile
                </li>
                <li>
                  When prompted, enter <b>Y</b> to set up Push Notifications for the project
                </li>
                <li>
                  Select <b>Push Notification Key</b> (<b>Key ID: DU7AC24FB2, Team ID: MD49LE9D3X</b>)
                </li>
                <li>
                  If the key doesn't exist, upload from,
                  {renderEmbed('./certificates/plussAPNs.p8')}
                  Enter key <b>DU7AC24FB2</b>
                </li>
                <li>Wait until the build completes</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Build+IPA/6+-+Complete.png',
            '40%',
            '50%',
          )}
          {renderTextStep('DO NOT close the terminal window for now - We will submit the build after setting up the app in the store')}
        </ol>
      </div>
    );
  };

  renderStep3 = () => {
    const bundleId = this.getAppBundleId();

    return (
      <div>
        {renderTitle('Set up new iOS App')}
        {renderDescription('In this step, we will set up a new iOS App.')}
        <ol>
          {renderOpenLinkStep('https://appstoreconnect.apple.com/')}
          {renderImageStep(
            <div>
              Under <b>App Store Connect</b>, click <b>My Apps</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+new+iOS+App/1+-+My+Apps.png',
            '40%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Click the <b>+</b> button next to <b>Apps</b>, then click <b>New App</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+new+iOS+App/2+-+New+App.png',
            '40%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Under <b>New App</b>,
              <ol className="marginBottom-16">
                <li>
                  Under <b>Platform</b>, tick <b>iOS</b>
                </li>
                <li>
                  Enter <b>Name</b> <b>{renderCopyToClipboardText(this.getAppProperty('ClientName'))}</b>
                </li>
                <li>
                  Select <b>Primary Language</b> - <b>English (Australia)</b>
                </li>
                <li>
                  Select <b>Bundle ID</b> - <b>{`plussmaster${bundleId}`}</b>
                </li>
                <li>
                  Enter <b>SKU</b> <b>{renderCopyToClipboardText(`com.pluss.${bundleId}`)}</b>
                </li>
                <li>
                  Under <b>User Access</b>, tick <b>Full Access</b>
                </li>
              </ol>
              Then click <b>Create</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+new+iOS+App/3+-+Create.png',
            '50%',
            '40%',
          )}
          {renderTextStep('Close the Incognito window')}
        </ol>
      </div>
    );
  };

  renderStep4 = () => {
    return (
      <div>
        {renderTitle('Upload IPA to iTunes Connect')}
        {renderDescription('In this step, we will upload the IPA file we have built in the previous step to iTunes Connect.')}
        <ol>
          {renderTextStep(
            <div>
              Return to the terminal opened in the <b>Build IPA</b> step, start the submissiong by typing,
              {renderEmbed(`npm run submit ios`)}
              This will submit the latest build to the store.
            </div>,
          )}
          {renderImageStep(
            <div>
              Select App Store Connect API Key
              <ol className="marginBottom-16">
                <li>
                  When promted <b>Select what to use for your project</b>, select <b>Choose an existing ASC API Key</b> if one already
                  exists. Otherwise, select <b>Add a new ASC API Key</b>
                </li>
                <li>Wait until the submission completes</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/submitasckey.png',
            '50%',
            '40%',
          )}
          {renderTextStep('Close the terminal window')}
        </ol>
      </div>
    );
  };

  renderResetUser = () => {
    const { loading, resetMessage } = this.state;
    const canReset = !loading;

    return (
      <div className="flex flex-row flex-center marginTop-10">
        <Button style={{ width: 90 }} inline buttonType="primary" onClick={() => canReset && this.onResetUser()} isActive={canReset}>
          Start
        </Button>
        <div className="marginLeft-16">{resetMessage}</div>
      </div>
    );
  };

  renderStep5 = () => {
    return (
      <div>
        {renderTitle('Set up Review User')}
        {renderDescription(
          'In this step, we will ensure an app review user is set up and/or have the password reset for a fresh review process.',
        )}
        <ol>
          {renderTextStep(
            <div>
              Create or reset app review user
              {this.renderResetUser()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep6 = () => {
    const { whiteLabelInfo, selectedApp } = this.state;
    const appUser = getWhiteLabelAppUser(whiteLabelInfo, selectedApp, REVIEW_USER_PREFIX);
    const userName = appUser ? appUser.email : automationConfig.appleTester;
    const password = appUser ? appUser.password : `${whiteLabelInfo.ClientCode}Apple`;

    return (
      <div>
        {renderTitle('Submit version for review')}
        {renderDescription('In this step, we will submit the app to Apple for review.')}
        <ol>
          {renderOpenLinkStep('https://appstoreconnect.apple.com/apps')}
          {renderImageStep(
            <div>
              Sign in to Apple Developer
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Apple ID</b> and <b>Password</b>
                </li>
                <li>Resolve two factor authentication</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderTextStep(
            <div>
              Click on the <b>{this.getAppProperty('ClientName')}</b> app
            </div>,
          )}
          {renderImageStep(
            <div>
              Click on <b>App Information</b> from the left menu
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Name</b>
                </li>
                <li>
                  Enter <b>Subtitle</b>
                </li>
                <li>
                  Select <b>Primary Category</b> - <b>Lifestyle</b>
                </li>
                <li>
                  Select <b>Secondary Category</b> - <b>Social Networking</b>
                </li>
                <li>
                  Under <b>Content Rights</b>, click <b>Set Up Content Rights Information</b> and when it asks{' '}
                  <b>Does your app contain, show, or access third-party content?</b>, check{' '}
                  <b>No, it does not contain, show, or access third-party content</b> and click <b>Done</b>
                </li>
              </ol>
              Then click <b>Save</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/2+-+App+Information.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on <b>Pricing and Availability</b> from the left menu
              <ol className="marginBottom-16">
                <li>Select USD 0 (Free) on the Price Schedule</li>
              </ol>
              Then click <b>Save</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/3+-+Pricing.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on <b>App Privacy</b> from the left menu
              <ol className="marginBottom-16">
                <li>
                  Click <b>Edit</b> next to <b>Privacy Policy</b>
                </li>
                <li>
                  Enter <b>Privacy Policy URL</b> <b>{renderCopyToClipboardText(automationConfig.privacyUrl)}</b> and click <b>Save</b>{' '}
                  button
                </li>
                <li>
                  Click <b>Get Started</b> button at the bottom
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/4+-+App+Privacy.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Data Collection</b>,
              <ol className="marginBottom-16">
                <li>
                  Check <b>Yes, we collect data from this app</b> and click <b>Next</b>
                </li>
                <li>
                  For <b>Contact Info</b>, check,
                  <ul>
                    <li>
                      <b>Name</b>
                    </li>
                    <li>
                      <b>Email Address</b>
                    </li>
                    <li>
                      <b>Phone Number</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>User Content</b>, check,
                  <ul>
                    <li>
                      <b>Photos or Videos</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Identifier</b>, check,
                  <ul>
                    <li>
                      <b>User ID</b>
                    </li>
                    <li>
                      <b>Device ID</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Usage Data</b>, check,
                  <ul>
                    <li>
                      <b>Other Usage Data</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Diagnostis</b>, check,
                  <ul>
                    <li>
                      <b>Crash Data</b>
                    </li>
                    <li>
                      <b>Performance Data</b>
                    </li>
                    <li>
                      <b>Other Diagnostic Data</b>
                    </li>
                  </ul>
                </li>
                <li>
                  Click <b>Save</b> button
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/5+-+Data+Collection.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              For each data type selected from the previous step,
              <ol className="marginBottom-16">
                <li>
                  For <b>Name</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, names collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use names for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Email Address</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, email addresses collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use email addresses for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Phone Number</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, phone numbers collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use phone numbers for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Photos or Videos</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, photos or videos collected from this app are not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use photos or videos for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>User ID</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, user IDs collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>Yes, we use user IDs for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Device ID</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, device IDs collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>Yes, we use device IDs for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Other Usage Data</b>
                  <ul>
                    <li>
                      Check <b>Analytics</b>
                    </li>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, other usage data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>Yes, we use other usage data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Crash Data</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, crash data data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use crash data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Performance Data</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, performance data data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use performance data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Other Diagnostic Data</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, other diagnostic data data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use other diagnostic data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
              </ol>
              Then click <b>Publish</b> button and confirm publication
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/6+-+Data+Types.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on <b>Prepare for Submission</b> from the left menu
              <ol className="marginBottom-16">
                <li>
                  Under <b>Version Informaiton</b> section, upload the screenshots as required
                </li>
                <li>
                  Enter <b>Description</b>
                </li>
                <li>
                  Enter <b>Keywords</b>
                </li>
                <li>
                  Enter <b>Support URL</b>
                </li>
                <li>
                  Enter <b>Marketing URL</b>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/7+-+Version+Information.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Build</b> section,
              <ol className="marginBottom-16">
                <li>
                  Select the build uploaded from the previous step (If asked about encryption, answer <b>No</b>)
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/8+-+Build.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>General App Information</b> section,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Copyright</b> (<b>2021 Pluss Communities PTY LTD</b>)
                </li>
                <li>
                  Enter <b>Version</b> without the last version component (Revision number)
                </li>
                <li>
                  Click <b>Edit</b> button next to <b>Age Rating</b> and answer all questions with <b>No</b> - This should result in{' '}
                  <b>Ages 4+</b> rating
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/9+-+General+App+Information.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>App Review Information</b> section,
              <ol className="marginBottom-16">
                <li>
                  Under <b>Sign-in required</b>,
                  <ol>
                    <li>
                      Enter <b>User name</b> <b>{renderCopyToClipboardText(userName)}</b>
                    </li>
                    <li>
                      Enter <b>Password</b> <b>{renderCopyToClipboardText(password)}</b>
                    </li>
                  </ol>
                </li>
                <li>
                  Under <b>Contact Information</b>,
                  <ol>
                    <li>
                      Enter <b>First name</b> (<b>Martin</b>)
                    </li>
                    <li>
                      Enter <b>Last name</b> (<b>Smee</b>)
                    </li>
                    <li>
                      Enter <b>Phone number</b> (<b>+61422515677</b>)
                    </li>
                    <li>
                      Enter <b>Email</b> (<b>marty@plusscommunities.com</b>)
                    </li>
                    <li>Attach any notes to help the reviewer navigating the app</li>
                  </ol>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/10+-+App+Review+Information.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              Click <b>Submit for Review</b>
            </div>,
          )}
          {renderTextStep('Close the Incognito window')}
        </ol>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    const { stepIndex } = this.props;
    const step = stepIndex && stepIndex < this.steps.length ? stepIndex : 0;
    return (
      <div className="flex-1 automation">
        <StepProgressBar
          startingStep={step}
          progressClass="progressBar"
          primaryBtnClass="primaryBtn"
          secondaryBtnClass="secondaryBtn"
          submitBtnName="Done"
          onPrevious={() => this.onMoveStep(step - 1)}
          onNext={() => this.onMoveStep(step + 1)}
          onSubmit={() => (this.props.onDone ? this.props.onDone() : null)}
          steps={this.steps}
        />
        {this.steps[step].render()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded, whiteLabelReset })(AppSetupiOS);
