import { getUrl } from './helper';
import { authedFunction } from '../session';

export const linkedUserActions = {
    getLinkedUsers: (userID) => {
        const query = { userID };
        return authedFunction({
            method: 'GET',
            url: getUrl('linkedusers', 'getLinkedToMe', query)
        });
    },
    getLinkedToUsers: (userID) => {
        const query = { userID };
        return authedFunction({
            method: 'GET',
            url: getUrl('linkedusers', 'getLinkedTo', query)
        });
    },
    addLink: (userID, userRelationship, contact, linkedUserRelationship) => {
        /*
            userID = id of carer/family member/external,
            userRelationship = realtionship of new link to resident
            contact = object of resident,
            linkedUserRelationship = relationship of resident to new link
        */
        return authedFunction({
            method: 'POST',
            url: getUrl('linkedusers', 'addLink'),
            data: {
                userID,
                userRelationship,
                contact,
                linkedUserRelationship
            }
        });
    },
    removeLink: (userID, contactID) => {
        /*
            userID = id of carer/family member/external,
            contactID = id of resident
        */
        return authedFunction({
            method: 'POST',
            url: getUrl('linkedusers', 'removeLink'),
            data: {
                userID, //
                contactID
            }
        });
    }
};