import axios from 'axios';
import { getUrl } from './helper'
import { authedFunction } from '../session';

export const stringActions = {
    getString: (site, id, useDefault) => {
        let url = getUrl('strings', `get/${site}_${id}`, useDefault ? { useDefault } : undefined);
        return axios({
            method: 'GET',
            url
        });
    },
    setString: (site, key, value) => {
        let url = getUrl('strings', 'set');
        return authedFunction({
            method: 'POST',
            url,
            data: {
                site,
                key,
                value
            }
        });
    }
};