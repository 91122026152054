import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Header } from '../../components';
import { isTheBest, validateAccess, isFeatureEnabled } from '../../session';
import ListFeedback from '../Feedback/ListFeedback';
import EditPermissions from '../DangerZone/Permissions/EditPermissions';
import EditSites from '../DangerZone/Sites/EditSites';
import PrepareDummy from '../DangerZone/Sites/PrepareDummy';
import UserDisable from '../DangerZone/UserDisable/UserDisable';
import AppStoreVersions from '../DangerZone/Versions/AppStoreVersions';
import { COLOUR_BRANDING_OFF } from '../../js';
import { canCopyDummy } from '../../config';
import SiteBrandingConfig from './Sites/SiteBrandingConfig';
import SetWeatherCity from './Sites/SetWeatherCity';
import SetWelcome from './Sites/SetWelcome';
import ManageSiteSettings from './Sites/ManageSiteSettings';
import ActivateTV from '../TV/ActivateTV';
import ManageSubdomains from './Sites/ManageSubdomains';

class SettingsHub extends Component {
  state = {
    selectedSection: '',
  };

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? 'fontHeavy' : 'fontLight ';
  }

  renderStats(gweg, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return gweg;
  }

  renderLeftBar() {
    return (
      <div className="hub-sideContent">
        {/* Top Add Button */}
        <div className={`hub-sideContent-topButton hub-sideContent-topButton--hide`}>
          <span className="hub-sideContent-topButton-text"> </span>
          <FontAwesome className="hub-sideContent-topButton-icon" name={'plus'} />
        </div>
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            Settings
          </div>
          {isFeatureEnabled(this.props.auth.features, 'feedback') && validateAccess(this.props.auth.site, 'feedback', this.props.auth) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'Feedback' });
              }}
              className={`${this.getSideBarSectionColour('Feedback')} fontSize-16 pointer text-dark`}
              style={{ height: 'auto', margin: 24 }}
            >
              Feedback
            </div>
          )}
          {isTheBest(this.props.auth, false) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'Sites' });
              }}
              className={`${this.getSideBarSectionColour('Sites')} fontSize-16 pointer text-dark`}
              style={{ height: 'auto', margin: 24 }}
            >
              Manage sites
            </div>
          )}
          {/* {isTheBest(this.props.auth, true) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'Branding' });
              }}
              className={`${this.getSideBarSectionColour('Branding')} fontSize-16 pointer text-dark`}
              style={{ height: 'auto', margin: 24 }}
            >
              Configure Branding
            </div>
          )} */}
          {isTheBest(this.props.auth, true) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'siteSettings' });
              }}
              className={`${this.getSideBarSectionColour('siteSettings')} fontSize-16 pointer text-dark`}
              style={{ height: 'auto', margin: 24 }}
            >
              Extras
            </div>
          )}
          {isTheBest(this.props.auth, true) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'Versions' });
              }}
              className={`${this.getSideBarSectionColour('Versions')} fontSize-16 pointer text-dark`}
              style={{ height: 'auto', margin: 24 }}
            >
              Configure app store versions
            </div>
          )}
          {isTheBest(this.props.auth, true) && canCopyDummy && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'PrepareDummy' });
              }}
              className={`${this.getSideBarSectionColour('PrepareDummy')} fontSize-16 pointer text-dark`}
              style={{
                height: 'auto',
                margin: 24,
              }}
            >
              Copy dummy data to new site
            </div>
          )}
          {isTheBest(this.props.auth, false) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'SetWeatherCity' });
              }}
              className={`${this.getSideBarSectionColour('SetWeatherCity')} fontSize-16 pointer text-dark`}
              style={{
                height: 'auto',
                margin: 24,
              }}
            >
              Set weather city for site
            </div>
          )}
          {isTheBest(this.props.auth, true) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'SetWelcome' });
              }}
              className={`${this.getSideBarSectionColour('SetWelcome')} fontSize-16 pointer text-dark`}
              style={{
                height: 'auto',
                margin: 24,
              }}
            >
              Set welcome
            </div>
          )}
          {isTheBest(this.props.auth, true) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'Subdomains' });
              }}
              className={`${this.getSideBarSectionColour('Subdomains')} fontSize-16 pointer text-dark`}
              style={{ height: 'auto', margin: 24 }}
            >
              Manage subdomains
            </div>
          )}
        </div>
      </div>
    );
  }

  renderRight() {
    if (this.state.selectedSection === 'Feedback' && validateAccess(this.props.auth.site, 'feedback', this.props.auth)) {
      return <ListFeedback />;
    }
    if (this.state.selectedSection === 'Permissions' && isTheBest(this.props.auth)) {
      return <EditPermissions />;
    }
    if (this.state.selectedSection === 'Sites' && isTheBest(this.props.auth)) {
      return <EditSites />;
    }
    if (this.state.selectedSection === 'PrepareDummy' && isTheBest(this.props.auth, true) && canCopyDummy) {
      return <PrepareDummy />;
    }
    if (this.state.selectedSection === 'Disable' && isTheBest(this.props.auth, true)) {
      return <UserDisable />;
    }
    if (this.state.selectedSection === 'Branding' && isTheBest(this.props.auth, true)) {
      return <SiteBrandingConfig />;
    }
    if (this.state.selectedSection === 'siteSettings' && isTheBest(this.props.auth, true)) {
      return <ManageSiteSettings />;
    }
    if (this.state.selectedSection === 'Versions' && isTheBest(this.props.auth, true)) {
      return <AppStoreVersions />;
    }
    if (this.state.selectedSection === 'SetWeatherCity' && isTheBest(this.props.auth, true)) {
      return <SetWeatherCity />;
    }
    if (this.state.selectedSection === 'ActivateDevice' && validateAccess(this.props.auth.site, 'activateDevices', this.props.auth)) {
      return <ActivateTV />;
    }
    if (this.state.selectedSection === 'SetWelcome' && isTheBest(this.props.auth, true)) {
      return <SetWelcome />;
    }
    if (this.state.selectedSection === 'Subdomains' && isTheBest(this.props.auth, true)) {
      return <ManageSubdomains />;
    }
    return null;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div
            className="hub-contentWrapper"
            style={{ ...(this.state.selectedSection === 'Feedback' ? { padding: 0 } : {}), position: 'relative' }}
          >
            {this.renderRight()}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
    paddingRight: 32,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(SettingsHub);
