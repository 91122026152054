import { authedFunction } from '../session';
import { getUrl } from './helper'

export const activityActions = {
    getActivity: (site, limit) => {
        return authedFunction({
            method: 'GET',
            url: getUrl('activity', 'get', { site, limit })
        });
    }
}