import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Header, Button, GenericInput } from '../../components';
import { paymentActions } from '../../webapi';
import { formatCurrency, capitalize } from '../../helper';
import { paymentConfig } from '../../config';
import { COLOUR_BRANDING_OFF } from '../../js';

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      availablePlans: [],
      selectedPlans: [],
      paymentMethod: null,
      invoices: [],
      customerId: '',
      clientSecret: '',
      name: '',
      planErrors: '',
      paymentErrors: '',
      connectedAccounts: [],
    };
    this.stripe = null;
    this.cardNumber = null;
    this.cardExpiry = null;
    this.cardCvc = null;
  }

  componentDidMount() {
    this.loadPlans();
    this.loadPaymentDetails();
    this.loadInvoices();
    this.loadConnectedAccounts();
  }

  configureStripeScript = () => {
    const session = document.createElement('script');
    session.src = paymentConfig.stripeScript;
    session.async = true;
    session.onload = this.onStripeScriptLoaded;
    document.head.appendChild(session);
  };

  onStripeScriptLoaded = () => {
    this.configureSession();
  };

  configureSession = () => {
    if (!this.props.publicKey) return;

    const classes = { base: 'genericInput padded', focus: 'focused' };
    const style = { base: { fontFamily: 'sf-regular, sans-serif', fontSize: '17px', color: '#3e4245' } };
    this.stripe = window.Stripe(this.props.publicKey);
    const elements = this.stripe.elements();
    this.cardNumber = elements.create('cardNumber', { classes, style });
    this.cardNumber.mount('#cardNumber-element');
    this.cardExpiry = elements.create('cardExpiry', { classes, style });
    this.cardExpiry.mount('#cardExpiry-element');
    this.cardCvc = elements.create('cardCvc', { classes, style });
    this.cardCvc.mount('#cardCvc-element');
  };

  createPaymentSession = () => {
    this.setState({ loading: true }, async () => {
      try {
        const res = await paymentActions.createSession();
        console.log('createPaymentSession', res.data);
        if (res.data) {
          this.setState(
            {
              loading: false,
              customerId: res.data.customer,
              clientSecret: res.data.client_secret,
            },
            this.saveCard,
          );
        }
      } catch (error) {
        console.log('createPaymentSession error', error);
        this.setState({ loading: false });
      }
    });
  };

  saveCard = () => {
    this.setState({ loading: true }, async () => {
      try {
        const result = await this.stripe.confirmCardSetup(this.state.clientSecret, {
          payment_method: {
            card: this.cardNumber,
            billing_details: { name: this.state.name },
          },
        });
        console.log('saveCard', result);
        if (result.setupIntent && result.setupIntent.payment_method) {
          this.setDefaultPaymentMethod(result.setupIntent.payment_method);
        } else {
          this.setState({ loading: false });
          this.handleErrors(result.error);
        }
      } catch (error) {
        console.log('saveCard error', error);
        this.setState({ loading: false });
      }
    });
  };

  handleErrors = (error) => {
    let paymentErrors = '';
    switch (error.code) {
      case 'parameter_invalid_empty':
        paymentErrors = 'Your cardholder name is incomplete.';
        break;
      default:
        paymentErrors = error.message;
    }
    this.setState({ paymentErrors });
  };

  loadPlans = () => {
    this.setState({ loading: true }, async () => {
      try {
        const res = await paymentActions.getAvailablePlans();
        console.log('loadPlans', res.data);
        this.setState({ loading: false, availablePlans: res.data }, () => this.loadCurrentPlans());
      } catch (error) {
        console.log('loadPlans error', error);
        this.setState({ loading: false });
      }
    });
  };

  loadCurrentPlans = () => {
    const { availablePlans } = this.state;
    if (!availablePlans || availablePlans.length === 0) return;

    this.setState({ loading: true }, async () => {
      try {
        const res = await paymentActions.getCurrentPlans();
        console.log('loadCurrentPlans', res.data);
        const availablePlans = this.syncAvailablePlans(res.data);
        const selectedPlans = this.syncSelectedPlans(availablePlans, res.data);
        this.setState({ loading: false, availablePlans, selectedPlans });
      } catch (error) {
        console.log('loadCurrentPlans error', error);
        this.setState({ loading: false });
      }
    });
  };

  loadPaymentDetails = () => {
    this.setState({ loading: true }, async () => {
      try {
        const res = await paymentActions.getPaymentDetails();
        console.log('loadPaymentDetails', res.data);
        if (res && res.data && res.data.card) {
          this.setState({ loading: false, paymentMethod: res.data.card });
        } else {
          this.setState({ loading: false, paymentMethod: null });
          this.configureStripeScript();
        }
      } catch (error) {
        console.log('loadPaymentDetails error', error);
        this.setState({ loading: false });
      }
    });
  };

  loadInvoices = () => {
    this.setState({ loading: true }, async () => {
      try {
        const res = await paymentActions.getInvoices();
        console.log('loadInvoices', res.data);
        this.setState({ loading: false, invoices: res.data });
      } catch (error) {
        console.log('loadInvoices error', error);
        this.setState({ loading: false });
      }
    });
  };

  loadConnectedAccounts = () => {
    this.setState({ loading: true }, async () => {
      try {
        const { data } = await paymentActions.getConnectedAccounts(this.props.auth.site);
        console.log('loadConnectedAccounts', data);
        this.setState({ loading: false, connectedAccounts: data });
      } catch (error) {
        console.log('loadConnectedAccounts error', error);
        this.setState({ loading: false });
      }
    });
  };

  syncAvailablePlans = (currentPlans) => {
    currentPlans.map((current) => {
      const available = this.state.availablePlans.find((item) => item.priceId === current.priceId);
      if (available) {
        available.subscriptionId = current.subscriptionId;
        available.quantity = current.quantity || 1;
      }
      return current;
    });
    return this.state.availablePlans;
  };

  syncSelectedPlans = (availablePlans, currentPlans) => {
    if (!currentPlans || currentPlans.length === 0) {
      return availablePlans.filter((plan) => plan.priceId === 0);
    }
    return availablePlans.filter((plan) => currentPlans.some((current) => current.priceId === plan.priceId));
  };

  formatDate = (timeStamp) => {
    return moment.unix(timeStamp).format('DD/MM/YYYY');
  };

  formatAmount = (amount) => {
    return formatCurrency(amount / 100);
  };

  onChangePlanQuantity = (event, plan) => {
    let quantity = '';
    if (event.target.value) {
      quantity = parseInt(event.target.value, 10);
      if (_.isNaN(quantity) || quantity === 0) quantity = 1;
    }

    const updatedAvailable = this.state.availablePlans.find((item) => item.priceId === plan.priceId);
    if (updatedAvailable) updatedAvailable.quantity = quantity;
    const updatedSelected = this.state.selectedPlans.find((item) => item.priceId === plan.priceId);
    if (updatedSelected) updatedSelected.quantity = quantity;

    this.setState({ availablePlans: this.state.availablePlans, selectedPlans: this.state.selectedPlans });
  };

  onSelectPlan = (selected) => {
    let selectedPlans = [...this.state.selectedPlans];
    // Remove same tiered plans
    _.remove(selectedPlans, (plan) => plan.tier === selected.tier && plan.priceId !== selected.priceId);
    // Toggle plan
    var removed = _.remove(selectedPlans, (plan) => plan.priceId === selected.priceId);
    if (!removed || removed.length === 0) selectedPlans.push(selected);

    if (selectedPlans.length === 0) {
      // None selected, default plan
      selectedPlans = this.state.availablePlans.filter((plan) => plan.priceId === 0);
    } else {
      if (_.last(selectedPlans).priceId === 0) {
        // Default plan selected, remove others
        selectedPlans = selectedPlans.slice(-1);
      } else if (selectedPlans.some((plan) => plan.priceId === 0) && selectedPlans.length > 1) {
        // Non-default plan selected, remove default
        selectedPlans = selectedPlans.filter((plan) => plan.priceId !== 0);
      }
    }

    this.setState({ selectedPlans });
  };

  onSavePlans = () => {
    this.setState({ loading: true }, async () => {
      try {
        // console.log('onSavePlans - before', this.state.selectedPlans);
        const res = await paymentActions.savePlans(this.state.selectedPlans);
        // console.log('onSavePlans - after', res.data);
        const availablePlans = this.syncAvailablePlans(res.data);
        const selectedPlans = this.syncSelectedPlans(availablePlans, res.data);
        this.setState({ loading: false, availablePlans, selectedPlans, planErrors: '' });

        this.loadInvoices();
      } catch (error) {
        console.log('onSavePlans error', error);
        this.setState({ loading: false, planErrors: 'Cannot save plans - Please check your payment method.' });
      }
    });
  };

  onChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

  onAddCard = () => {
    this.createPaymentSession();
  };

  onOnboardConnectedAccount = (accountId) => {
    this.setState({ loading: true }, async () => {
      try {
        const { data } = await paymentActions.getOnboardingLink(this.props.auth.site, accountId);
        window.location.href = data.url;
      } catch (error) {
        console.log('getOnboardingLink error', error);
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  onDeleteConnectedAccount = (accountId) => {
    if (!window.confirm(`Are you sure you want to delete the selected account?`)) return;

    this.setState({ loading: true }, async () => {
      try {
        await paymentActions.deleteConnectedAccount(this.props.auth.site, accountId);
        this.loadConnectedAccounts();
      } catch (error) {
        console.log('onDeleteConnectedAccount error', error);
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  onCreateConnectedAccount = () => {
    this.setState({ loading: true }, async () => {
      try {
        const { data } = await paymentActions.createConnectedAccount(this.props.auth.site);
        // console.log('Connected Account', data);
        this.loadConnectedAccounts();
        window.location.href = data.url;
      } catch (error) {
        console.log('onCreateConnectedAccount error', error);
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  setDefaultPaymentMethod = async (paymentMethodId) => {
    this.setState({ loading: true }, async () => {
      try {
        const res = await paymentActions.setDefaultPaymentMethod(paymentMethodId, this.state.customerId);
        console.log('setDefaultPaymentMethod', res.data);
        this.setState({ loading: false });

        // Reload payment method
        this.loadPaymentDetails();
      } catch (error) {
        console.log('setDefaultPaymentMethod error', error);
        this.setState({ loading: false });
      }
    });
  };

  renderConnectedAccounts() {
    const { connectedAccounts, loading } = this.state;

    return (
      <div className={'paddingBottom-20'}>
        <p className="fontRegular fontSize-20 text-dark">Connected Accounts</p>
        <div>
          <div style={styles.accountLine}>
            <p style={styles.accountId}>Account Id</p>
            <p style={styles.accountEmail}>Email</p>
            <p style={styles.accountSubmitted}>Details</p>
            <p style={styles.accountPayout}>Payouts</p>
            <div style={styles.accountOnboard} />
            <div style={styles.accountDelete} />
          </div>
          {connectedAccounts &&
            connectedAccounts.map((acc) => {
              return (
                <div key={acc.id} style={styles.accountLine}>
                  <p style={styles.accountId}>{acc.id}</p>
                  <p style={styles.accountEmail}>{acc.email}</p>
                  <p style={styles.accountSubmitted}>{acc.details_submitted ? 'Yes' : 'No'}</p>
                  <p style={styles.accountPayout}>{acc.payouts_enabled ? 'Yes' : 'No'}</p>
                  <div style={styles.accountOnboard}>
                    <Button
                      buttonType="primary"
                      isActive={!acc.details_submitted && !loading}
                      onClick={() => this.onOnboardConnectedAccount(acc.id)}
                    >
                      Onboard
                    </Button>
                  </div>
                  <div style={styles.accountDelete}>
                    <Button buttonType="primary" isActive={!loading} onClick={() => this.onDeleteConnectedAccount(acc.id)}>
                      Delete
                    </Button>
                  </div>
                </div>
              );
            })}
        </div>
        <Button style={styles.plansSaveButton} buttonType="primary" isActive={!loading} onClick={this.onCreateConnectedAccount}>
          Create a Connected Account
        </Button>
      </div>
    );
  }

  renderCurrentPlan() {
    return (
      <div>
        <p className="fontRegular fontSize-20 text-dark">Current Plans</p>
        <div className="paddingBottom-20">
          {this.state.selectedPlans &&
            this.state.selectedPlans.map((current) => {
              return (
                <p className="fontHeavy fontSize-16" key={current.priceId}>
                  {`${current.name} x ${current.quantity}`}
                </p>
              );
            })}
        </div>
      </div>
    );
  }

  renderAvailablePlans() {
    return (
      <div>
        <p className="fontRegular fontSize-20 text-dark">Available Plans</p>
        <div className="paddingBottom-20">
          {this.state.availablePlans &&
            this.state.availablePlans.map((plan) => {
              const isActive = this.state.selectedPlans.some((selected) => selected.priceId === plan.priceId);
              return (
                <div key={plan.priceId} style={styles.availablePlanContainer}>
                  <p className="fontMedium fontSize-16" style={styles.planName}>
                    {plan.name}
                  </p>
                  <p className="fontMedium fontSize-16" style={styles.planPrice}>
                    {this.formatAmount(plan.unitAmount)}
                  </p>
                  <GenericInput
                    id={plan.priceId}
                    style={{ marginLeft: '40px', marginRight: '40px', marginBottom: '0px' }}
                    inputStyle={{ width: '40px', textAlign: 'center', paddingBottom: '0px' }}
                    type="text"
                    placeholder="1"
                    value={plan.multiple ? plan.quantity : 1}
                    onChange={(e) => this.onChangePlanQuantity(e, plan)}
                    disabled={!plan.multiple}
                  />
                  <Button
                    style={{ width: '150px' }}
                    buttonType="secondary"
                    isActive={!this.state.loading && isActive}
                    onClick={() => this.onSelectPlan(plan)}
                  >
                    {isActive ? 'Selected' : 'Select'}
                  </Button>
                </div>
              );
            })}
        </div>
        <div className="payment-error">{this.state.planErrors}</div>
        <div style={styles.plansSaveButtonContainer}>
          <Button style={styles.plansSaveButton} buttonType="primary" isActive={!this.state.loading} onClick={this.onSavePlans}>
            Save
          </Button>
          {this.state.loading && <FontAwesome style={{ fontSize: 20, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />}
        </div>
      </div>
    );
  }

  renderErrors() {
    const { paymentErrors } = this.state;
    if (typeof paymentErrors === 'string') {
      return <p>{paymentErrors}</p>;
    }
    return paymentErrors.map((error, index) => {
      return <p key={index}>{error}</p>;
    });
  }

  renderPaymentMethods() {
    const renderPaymentMethod = () => {
      return (
        <div style={styles.paymentMethodContainer}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>Card Number:</div>
            <div>{this.state.paymentMethod.formattedNumber}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>Expiry:</div>
            <div>{this.state.paymentMethod.formattedExpiry}</div>
          </div>
        </div>
      );
    };

    const renderPaymentEntry = () => {
      return (
        <div className="payment-container">
          <div className="payment-heading">Card Details</div>
          <div className="payment-error">{this.renderErrors()}</div>
          <div className="payment-field">
            <div className="label">Card Number</div>
            <div id="cardNumber-element"></div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="payment-field" style={{ width: '50%' }}>
              <div className="label">Expiry Date</div>
              <div id="cardExpiry-element"></div>
            </div>
            <div className="payment-field" style={{ paddingLeft: '50px' }}>
              <div className="label">CVV Number</div>
              <div id="cardCvc-element"></div>
            </div>
          </div>
          <div className="payment-field">
            <div className="label">Cardholder Name</div>
            <input
              className="genericInput"
              type="text"
              id="cardholder-name"
              title="cardholder name"
              aria-label="enter name on card"
              tabIndex="5"
              onChange={this.onChangeName}
            />
          </div>
          <div style={styles.addCardButtonContainer}>
            <Button buttonType="primary" buttonClassName="payment-button" onClick={this.onAddCard} isActive={!this.state.loading}>
              Add Card
            </Button>
            {this.state.loading && (
              <FontAwesome style={{ marginLeft: '10px', fontSize: 20, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />
            )}
          </div>
        </div>
      );
    };

    return (
      <div>
        <p className="fontMedium fontSize-20 text-dark paddingTop-40">Payment Methods</p>
        {this.state.paymentMethod ? renderPaymentMethod() : renderPaymentEntry()}
      </div>
    );
  }

  renderInvoices() {
    return (
      <div>
        <p className="fontMedium fontSize-20 text-dark paddingTop-40">Invoices</p>
        <div>
          <div style={styles.invoiceLine}>
            <p style={styles.invoiceNumber}>Number</p>
            <p style={styles.invoiceStatus}>Status</p>
            <p style={styles.invoiceStart}>Start</p>
            <p style={styles.invoiceEnd}>End</p>
            <p style={styles.invoiceSubTotal}>Sub Total</p>
            <p style={styles.invoiceTax}>Tax</p>
            <p style={styles.invoiceTotal}>Total</p>
            <p style={styles.invoiceCreated}>Created</p>
          </div>
          {this.state.invoices &&
            this.state.invoices.map((invoice) => {
              return (
                <div key={invoice.id} style={styles.invoiceLine}>
                  <p style={styles.invoiceNumber}>{invoice.number}</p>
                  <p style={styles.invoiceStatus}>{capitalize(invoice.status)}</p>
                  <p style={styles.invoiceStart}>{this.formatDate(invoice.periodStart)}</p>
                  <p style={styles.invoiceEnd}>{this.formatDate(invoice.periodEnd)}</p>
                  <p style={styles.invoiceSubTotal}>{this.formatAmount(invoice.subTotal)}</p>
                  <p style={styles.invoiceTax}>{this.formatAmount(invoice.tax)}</p>
                  <p style={styles.invoiceTotal}>{this.formatAmount(invoice.total)}</p>
                  <p style={styles.invoiceCreated}>{this.formatDate(invoice.created)}</p>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Header />
        <div style={{ width: '100%', maxWidth: 900, padding: 20 }}>
          <p className="fontMedium fontSize-36 text-dark">Billing</p>
          {this.renderConnectedAccounts()}
          {this.renderCurrentPlan()}
          {this.renderAvailablePlans()}
          {this.renderPaymentMethods()}
          {this.renderInvoices()}
        </div>
      </div>
    );
  }
}

const styles = {
  availablePlanContainer: {
    display: 'flex',
    width: '500px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  planName: {
    width: '120px',
  },
  planPrice: {
    width: '120px',
    textAlign: 'right',
  },
  plansSaveButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  plansSaveButton: {
    marginRight: '10px',
  },
  paymentMethodContainer: {
    width: '300px',
  },
  addCardButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  addCardButton: {
    marginRight: '10px',
  },
  invoiceLine: {
    display: 'flex',
    paddingBottom: '10px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  invoiceNumber: {
    width: '160px',
  },
  invoiceStatus: {
    width: '120px',
    textAlign: 'center',
  },
  invoiceStart: {
    width: '120px',
  },
  invoiceEnd: {
    width: '120px',
  },
  invoiceSubTotal: {
    width: '120px',
    textAlign: 'right',
  },
  invoiceTax: {
    width: '120px',
    textAlign: 'right',
  },
  invoiceTotal: {
    width: '120px',
    textAlign: 'right',
  },
  invoiceCreated: {
    marginLeft: '20px',
    width: '120px',
  },
  accountLine: {
    display: 'flex',
    paddingBottom: '10px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  accountId: {
    width: '170px',
  },
  accountEmail: {
    width: '250px',
  },
  accountSubmitted: {
    width: '70px',
    textAlign: 'center',
  },
  accountPayout: {
    width: '70px',
    textAlign: 'center',
  },
  accountOnboard: {
    width: '120px',
  },
  accountDelete: {
    width: '120px',
  },
};

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    auth,
    publicKey: auth.user && auth.user.paymentInfo && auth.user.paymentInfo.publicKey,
  };
};

export default connect(mapStateToProps, {})(Billing);
