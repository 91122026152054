import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Button,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  UserListing,
  Text,
} from '../../components';
import { feedbackActions } from '../../webapi';
import { checkLoggedIn, validateAccess } from '../../session';
import { safeReadParams, toParagraphed, getPluralS, getPercentage } from '../../helper';
import FontAwesome from 'react-fontawesome';
import { pollsLoaded } from '../../actions';
import { ProfilePic } from '../../components/ProfilePic';

class PollResults extends Component {
  initialState = {
    pollId: safeReadParams(this.props, 'pollId'),
    poll: {},
    showResponses: false,
    loadingAnswers: false,
    userResponses: null,
    selectedUserId: null,
    exportHeaders: [],
    exportData: [],
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    this.setStatePoll(this.props.polls, safeReadParams(this.props, 'pollId'));
  }

  componentDidMount() {
    setTimeout(() => {
      if (!validateAccess(this.props.auth.site, 'polls', this.props.auth) && !this.state.poll.ShowResults) {
        window.history.back();
      }
    }, 500);

    this.resultInterval = setInterval(() => {
      this.loadResults(this.state.pollId);
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.resultInterval);
  }

  getAnswerText = (answer, question) => {
    if (_.isNil(answer)) return null;

    const getOptionText = (value, id = 'Id') => {
      const option = question.Options && question.Options.find((o) => o[id] === value);
      return option ? option.Text : `Unknown - ${value}`;
    };

    switch (question.Type) {
      case 'multichoice':
        return getOptionText(answer);
      case 'scale':
        return getOptionText(answer, 'Value');
      case 'checkbox':
        return Array.isArray(answer) ? answer.map((a) => getOptionText(a)).join(',') : `Unknown - ${answer}`;
      default:
        return answer;
    }
  };

  getExportHeaders = (poll) => {
    const userInfo = poll.Anonymous
      ? []
      : [
          {
            label: 'Id',
            key: 'id',
          },
          {
            label: 'User',
            key: 'user',
          },
        ];
    const questions = poll
      ? poll.Questions.map((q) => {
          return {
            label: q.Text,
            key: q.Id.toString(),
          };
        })
      : [];

    return [...userInfo, ...questions];
  };

  getExportData = (responses) => {
    const { poll } = this.state;
    if (!responses || !poll) return [];

    return responses.map((r) => {
      const formatted = poll.Anonymous
        ? {}
        : {
            id: r.UserId,
            user: r.User.displayName,
          };
      for (let key in r.Answers) {
        const question = poll.Questions.find((q) => q.Id == key);
        formatted[key.toString()] = this.getAnswerText(r.Answers[key], question);
      }
      return formatted;
    });
  };

  setStatePoll(polls, pollId) {
    const poll = _.find(polls, (p) => {
      return p != null && p.Id === pollId;
    });
    if (poll) {
      const exportHeaders = this.getExportHeaders(poll);
      this.setState({
        poll,
        pollLoaded: true,
        exportHeaders,
      });
      this.loadAudience(pollId);
      this.loadAnswers(pollId);
    } else if (!this.state.loadedPolls) {
      this.loadPolls();
    } else {
      //TODO show failure to find poll
    }
  }

  loadPolls() {
    this.setState({
      loadedPolls: true,
    });
    feedbackActions.getPolls(this.props.auth.site).then((res) => {
      // console.log('loadPolls', res.data);
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
        this.props.pollsLoaded(res.data);
      }
      this.setStatePoll(res.data, this.state.pollId);
    });
  }

  loadAudience(pollId) {
    feedbackActions.getPollAudience(pollId).then((res) => {
      // console.log('loadAudience', res.data);
      if (res.data.Anonymous) {
        this.setState({
          audienceAnonymous: true,
        });
      } else {
        this.setState({
          audience: res.data.Audience,
        });
      }
    });
  }

  loadResults(pollId) {
    feedbackActions.getPollResults(pollId).then((res) => {
      // console.log('loadResults', res.data);
      this.setState({
        results: res.data,
      });
    });
  }

  loadAnswers = (pollId, userId) => {
    this.setState({ loadingAnswers: true }, async () => {
      try {
        const { data } = await feedbackActions.getPollAnswers(pollId, userId);
        // console.log('loadAnswers', data);
        const exportData = this.getExportData(data);
        this.setState({ loadingAnswers: false, userResponses: data, exportData });
      } catch (error) {
        console.log('loadAnswers error', error);
        this.setState({ loadingAnswers: false });
      }
    });
  };

  getParagraphResults(qid) {
    if (!this.state.results || !this.state.results[qid]) {
      return [];
    }
    return this.state.results[qid];
  }

  getResponseCount(qid, oid) {
    if (!this.state.results || !this.state.results[qid] || !this.state.results[qid][oid]) {
      return 0;
    }
    return this.state.results[qid][oid];
  }

  getPercentage(count) {
    return getPercentage(count, this.state.poll.ResponseCount);
  }

  getEmbedUrl = (includeOrigin) => {
    return `${includeOrigin ? window.location.origin : ''}/e/poll/${this.state.pollId}`;
  };

  copyEmbedUrl = () => {
    if (!(navigator && navigator.clipboard && navigator.clipboard.writeText)) {
      return;
    }
    navigator.clipboard.writeText(this.getEmbedUrl(true));
    this.setState({
      copiedEmbed: true,
    });
    setTimeout(() => {
      this.setState({
        copiedEmbed: false,
      });
    }, 3000);
  };

  onViewResponses = (selectedUserId) => {
    this.setState({ showResponses: true, selectedUserId });
  };

  onHideResponses = () => {
    this.setState({ showResponses: false, selectedUserId: null });
  };

  updatePollStatus = (status) => {
    feedbackActions.updateLivePollStatus(this.state.pollId, status).then(() => {
      this.loadPolls();
    });
    this.setState({
      poll: { ...this.state.poll, LivePollActive: status },
    });
  };

  renderBack() {
    return (
      <div>
        <Button
          inline
          buttonType="primary"
          onClick={() => {
            window.history.back();
          }}
          isActive
        >
          Back to Surveys
        </Button>
      </div>
    );
  }

  renderMultiChoiceOption(q, o) {
    const count = this.getResponseCount(q.Id, o.Id);
    const percentage = this.getPercentage(count);
    return (
      <div className="multichoiceresult" key={o.Id}>
        <div className="multichoiceresult_responses">
          {count} response{getPluralS(count)}
        </div>
        <div className="multichoiceresult_percentage">{percentage}</div>
        <div className="multichoiceresult_bar">
          <div className="multichoiceresult_innerBar" style={{ width: percentage }}></div>
          <p className="multichoiceresult_innerText">{o.Text}</p>
        </div>
      </div>
    );
  }

  renderMultiChoiceResults(question) {
    return question.Options.map((o) => {
      return this.renderMultiChoiceOption(question, o);
    });
  }

  renderParagraphResult(response, index) {
    return (
      <div className="paragraphresult" key={index}>
        <p className="paragraphresult_text">{toParagraphed(response.Answer)}</p>
        <div className="paragraphresult_bottom">
          <ProfilePic className="paragraphresult_pic" size={24} image={response.User.profilePic}></ProfilePic>
          <div className="paragraphresult_name">{response.User.displayName}</div>
        </div>
      </div>
    );
  }

  renderParagraphResults(question) {
    return (
      <div className="paragraphresults">
        {this.getParagraphResults(question.Id).map((r, i) => {
          return this.renderParagraphResult(r, i);
        })}
      </div>
    );
  }

  renderScaleResult(q, o) {
    const count = this.getResponseCount(q.Id, o.Value);
    const percentage = this.getPercentage(count);
    return (
      <div key={o.Value} className="scaleresult">
        <p className="scaleresult_percentage">{percentage}</p>
        <p className="scaleresult_count">
          {count} response{getPluralS(count)}
        </p>
        <p className="scaleresult_number">{o.Value}</p>
        <p className="scaleresult_text">{o.Text}</p>
      </div>
    );
  }

  renderScaleResults(question) {
    return (
      <div className="scaleresults">
        {question.Options.map((o, i) => {
          return this.renderScaleResult(question, o, i);
        })}
      </div>
    );
  }

  renderCheckboxResults(question) {
    return question.Options.map((o) => {
      return this.renderMultiChoiceOption(question, o);
    });
  }

  renderQuestion(question, index) {
    let content = null;

    switch (question.Type) {
      case 'multichoice':
        content = this.renderMultiChoiceResults(question);
        break;
      case 'paragraph':
        content = this.renderParagraphResults(question);
        break;
      case 'scale':
        content = this.renderScaleResults(question);
        break;
      case 'checkbox':
        content = this.renderCheckboxResults(question);
        break;
      default:
        break;
    }
    return (
      <div className="questionContainer" key={index} style={{ marginTop: index === 0 ? 0 : 32 }}>
        <div className="questionNumber_container">{!this.state.poll.IsLivePoll && <p className="questionNumber">{index + 1}</p>}</div>
        <div className={`question question-results ${question.Type === 'paragraph' ? 'question-paragraph' : ''}`}>
          <p className="question_title">{question.Text}</p>
          {question.Description && <p className="question_description">{question.Description}</p>}
          {content}
        </div>
      </div>
    );
  }

  renderQuestions() {
    return _.map(this.state.poll.Questions, (q, i) => {
      return this.renderQuestion(q, i);
    });
  }

  renderLivePollStatus() {
    if (!this.state.poll.IsLivePoll) {
      return null;
    }
    return (
      <div className="padding-60 paddingBottom-40 ">
        <div className="newTopBar clearfix">
          <p className="newTopBarTitle text-sectionTitle">STATUS</p>
        </div>
        <Text type="h3" className="marginBottom-8">
          {this.state.poll.LivePollActive ? (
            <span className="text-green">Poll is live</span>
          ) : (
            <span>Poll is not yet open for responses</span>
          )}
        </Text>
        {this.state.poll.LivePollActive ? (
          <Button
            inline
            buttonType="outlined"
            onClick={() => {
              this.updatePollStatus(false);
            }}
            isActive
          >
            End Poll
          </Button>
        ) : (
          <Button
            inline
            buttonType="primary"
            onClick={() => {
              this.updatePollStatus(true);
            }}
            isActive
          >
            Start Poll
          </Button>
        )}
      </div>
    );
  }

  renderMain() {
    const { pollLoaded } = this.state;
    if (!pollLoaded) return null;
    return (
      <div>
        {!this.state.poll.IsLivePoll && (
          <div className="padding-60 paddingBottom-40 bottomDivideBorder">
            <div className="newTopBar clearfix">
              <p className="newTopBarTitle text-sectionTitle">SURVEY RESULTS</p>
            </div>
            <p className="pollTitle">{this.state.poll.Title}</p>
          </div>
        )}
        <div className={`padding-60 paddingLeft-20 ${this.state.poll.IsLivePoll ? 'bottomDivideBorder' : ''}`}>
          <div className="newTopBar clearfix paddingLeft-40">
            <p className="newTopBarTitle text-sectionTitle">{this.state.poll.IsLivePoll ? 'LIVE POLL RESULT' : 'SURVEY ANSWERS'}</p>
          </div>
          {this.renderQuestions()}
        </div>
        {this.renderLivePollStatus()}
      </div>
    );
  }

  renderCSVExport() {
    const { exportHeaders, exportData } = this.state;
    // console.log('exportHeaders', exportHeaders);
    // console.log('exportData', exportData);
    if (_.isEmpty(exportHeaders) || _.isEmpty(exportData)) return null;

    return (
      <Button inline buttonType="primary" isActive className="marginTop-16">
        <CSVLink headers={exportHeaders} data={exportData} style={{ textDecoration: 'none', color: '#fff' }} filename="survey-results.csv">
          Export CSV
        </CSVLink>
      </Button>
    );
  }

  renderOverview() {
    const { pollLoaded } = this.state;
    if (!pollLoaded) return null;

    return (
      <div className="padding-32 paddingVertical-40 bottomDivideBorder">
        <p className="newTopBarTitle text-sectionTitle">OVERVIEW</p>
        <div className="polloverview">
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="users" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.poll.AudienceCount}</p>
              <p className="polloverview_text">Participant{getPluralS(this.state.poll.AudienceCount)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="pencil" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.poll.ResponseCount}</p>
              <p className="polloverview_text">Response{getPluralS(this.state.poll.ResponseCount)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="line-chart" />
            <div className="polloverview_right">
              <p className="polloverview_count">{getPercentage(this.state.poll.ResponseCount, this.state.poll.AudienceCount)}</p>
              <p className="polloverview_text">Response Rate</p>
            </div>
          </div>
        </div>
        {this.renderCSVExport()}
      </div>
    );
  }

  renderViewResponses(userId) {
    return (
      <p className="viewResponse" onClick={() => this.onViewResponses(userId)}>
        View Responses
      </p>
    );
  }

  renderEmbed() {
    const { pollLoaded, poll } = this.state;
    if (!pollLoaded) return null;
    if (!poll.IsLivePoll) return null;
    return (
      <div className="padding-32 paddingVertical-40 bottomDivideBorder">
        <p className="newTopBarTitle text-sectionTitle">EMBED LIVE RESULTS</p>
        <div className="flex flex-center">
          <Text type="h6">
            <a href={this.getEmbedUrl()} target="_blank">
              {this.getEmbedUrl(true)}
            </a>
          </Text>
          <FontAwesome
            name={this.state.copiedEmbed ? 'check' : 'copy'}
            onClick={this.copyEmbedUrl}
            className={`fontIconButton ${this.state.copiedEmbed ? 'fontIconButton-green' : ''}`}
          />
        </div>
        <Text type="help">
          Embedding this URL will allow you to display live results for this poll within a browser window or a presentation.
        </Text>
      </div>
    );
  }

  renderAudience() {
    const { pollLoaded } = this.state;
    if (!pollLoaded) return null;

    if (!this.state.audience) {
      if (this.state.audienceAnonymous) {
        return (
          <div className="padding-32 paddingVertical-40 bottomDivideBorder">
            <p className="newTopBarTitle text-sectionTitle">PARTICIPANTS</p>
            <div className="pollaudience_anonymous">
              <FontAwesome className="pollaudience_anonymousIcon" name="eye-slash" />
              <p className="pollaudience_text">
                This survey is anynomous.
                <br />
                You are not able to see who has responded.
              </p>
            </div>
          </div>
        );
      }
      return null;
    }
    const responded = [];
    const notResponded = [];
    this.state.audience.forEach((u) => {
      if (_.includes(this.state.poll.ResponseIds, u.id)) {
        responded.push(u);
      } else {
        notResponded.push(u);
      }
    });
    return (
      <div className="padding-32 paddingVertical-40 bottomDivideBorder">
        <p className="newTopBarTitle text-sectionTitle">PARTICIPANTS</p>
        <div className="pollaudience">
          <div className="pollaudience_left">
            <p className="pollaudience_title">Responded</p>
            <div className="pollaudience_bottom">
              {_.map(_.sortBy(responded, 'displayName'), (user) => {
                return <UserListing user={user} key={user.id} size={35} subContent={this.renderViewResponses(user.id)} />;
              })}
            </div>
          </div>
          <div className="pollaudience_right">
            <p className="pollaudience_title">Not Responded</p>
            <div className="pollaudience_bottom">
              {_.map(_.sortBy(notResponded, 'displayName'), (user) => {
                return <UserListing user={user} key={user.id} size={30} />;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSideForm() {
    return (
      <OverlayPageSection className="pageSectionWrapper--newPopupSideLarge">
        {this.renderOverview()}
        {this.renderEmbed()}
        {this.renderAudience()}
      </OverlayPageSection>
    );
  }

  renderResponsePopupContent() {
    const { loadingAnswers, poll, userResponses, selectedUserId } = this.state;
    const responses = Array.isArray(userResponses) ? userResponses.find((r) => r.UserId === selectedUserId) : userResponses;
    if (!responses) return;

    const title = responses ? `${responses.User.displayName}'s Responses` : 'Responses';
    const questions = (poll && poll.Questions) || [];
    const answers = (responses && responses.Answers) || [];

    return (
      <div>
        <div style={styles.detailHeading}>{title}</div>
        <div style={styles.detailContainer}>
          {loadingAnswers ? (
            <div className="flex-1 flex flex-center-row flex-center">
              <FontAwesome style={{ fontSize: 30 }} name="spinner fa-pulse fa-fw" />
            </div>
          ) : null}
          {questions.map((q) => {
            return (
              <Row key={q.Id} style={styles.question}>
                <Col xs={8} style={styles.questionLabel}>
                  {q.Text}
                </Col>
                <Col xs={4} style={styles.questionAnswer}>
                  {this.getAnswerText(answers[q.Id], q)}
                </Col>
              </Row>
            );
          })}
        </div>
        <div style={styles.detailButtonContainer}>
          <Button inline buttonType="outlined" onClick={this.onHideResponses} isActive>
            Close
          </Button>
        </div>
      </div>
    );
  }

  renderResponsePopup() {
    if (!this.state.showResponses) return null;

    return (
      <div style={styles.popUpOuter}>
        <div style={styles.whiteBox}>
          <div style={styles.popupContainer}>{this.renderResponsePopupContent()}</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents id="PollContainer" noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">{this.renderMain()}</OverlayPageSection>
          {this.renderSideForm()}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderBack()}</OverlayPageBottomButtons>
        {this.renderResponsePopup()}
      </OverlayPage>
    );
  }
}

const styles = {
  popUpOuter: {
    zIndex: 100,
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.7)',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
    overflow: 'auto',
    flex: '0 1 auto',
    top: 0,
    left: 0,
  },
  whiteBox: {
    minWidth: '60%',
    maxWidth: '80%',
  },
  popupContainer: {
    borderRadius: 15,
    backgroundColor: 'white',
    padding: 20,
    marginBottom: 30,
  },
  detailHeading: {
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 36,
    fontWeight: 'bold',
  },
  detailContainer: {
    marginLeft: '5px',
    marginRight: '5px',
    overflow: 'hidden',
  },
  detailButtonContainer: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: 15,
    borderTop: '1px solid #ccc',
  },
  question: {
    marginTop: 6,
  },
  questionLabel: {
    fontWeight: 'bold',
  },
  questionAnswer: {
    textAlign: 'right',
    paddingRight: '30px',
  },
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { pollsLoaded })(PollResults);
