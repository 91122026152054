import { clientName } from '../config';

export const setChatUser = (auth) => {
  if (!window.tidioChatApi || !window.tidioChatApi.setVisitorData) {
    setTimeout(() => {
      setChatUser(auth);
    }, 1000);
    return;
  }
  if (!auth.user) {
    return (
      window.tidioChatApi &&
      window.tidioChatApi.setVisitorData({
        name: undefined,
        email: undefined,
        phone: undefined,
        site: undefined,
        company: clientName,
        distinct_id: undefined,
      })
    );
  }
  return (
    window.tidioChatApi &&
    window.tidioChatApi.setVisitorData({
      name: `${auth.user.displayName} - ${clientName} ${auth.site}`,
      email: auth.user.email,
      phone: auth.user.phoneNumber,
      site: auth.site,
      userType: auth.auth,
      company: clientName,
      distinct_id: auth.user.Id,
    })
  );
};

export const openChat = () => {
  window.tidioChatApi.display(true);
  window.tidioChatApi.open();
};

export const hideChat = () => {
  if (window.tidioChatApi && window.tidioChatApi.display) {
    setTimeout(() => {
      window.tidioChatApi.display(false);
    }, 1000);
  } else {
    setTimeout(() => {
      hideChat();
    }, 1000);
  }
};
