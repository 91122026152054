import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { eventSubmissionsLoaded } from '../../actions';
import { eventActions, stringActions } from '../../webapi';
import { checkLoggedIn } from '../../session';
import { COLOUR_BRANDING_ACTION } from '../../js';
import { isEmail, isPaidItem } from '../../helper';
import { Button, GenericInput } from '../../components';

class EventSubmissions extends Component {
  state = {
    eventEntries: [],
    sortColumn: 'Changed', // column to sort by
    sortDesc: false, // if true, sort descending rather than ascending
    now: moment.utc(),
    onlyFuture: true,
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    this.updateProps(this.props);
  }

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    this.setState({
      eventEntries: props.submissions,
    });
  }

  getData() {
    eventActions.getEventSubmissions(this.props.auth.site).then((res) => {
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
        this.props.eventSubmissionsLoaded(res.data);
      }
    });
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  renderEvents() {
    let source = [];
    if (this.state.sortColumn === 'SubmittedBy') {
      source = _.sortBy(this.state.eventEntries, (user) => {
        return user.SubmittedBy ? user.SubmittedBy.displayName : '';
      });
    } else {
      source = _.sortBy(this.state.eventEntries, this.state.sortColumn);
    }
    if (this.state.sortDesc) {
      source.reverse();
    }
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });

    return source.map((ev) => {
      if (ev != null) {
        return (
          <tr key={ev.RowId}>
            <td>{moment.utc(ev.Timestamp).local().format('DD-MM-YYYY h:mma')}</td>
            <td className="table-TitleColumn">
              <Link to={`/events/eventsubmission/${ev.RowId}`}>
                {isPaidItem(this.props.paymentEnabled, ev.Tickets) && (
                  <FontAwesome style={{ color: COLOUR_BRANDING_ACTION }} name="usd fa-fw" />
                )}
                {ev.Title}
              </Link>
            </td>
            <td>{ev.SubmittedBy ? ev.SubmittedBy.displayName : ''}</td>
          </tr>
        );
      }
      return null;
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  getEmail() {
    stringActions
      .getString(this.props.auth.site, 'eventsubmissionemail')
      .then((res) => {
        this.setState({ fetching: false, email: res.data });
      })
      .catch((error) => {
        this.setState({ fetching: false, email: '' });
      });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  validateEmail() {
    return !_.isEmpty(this.state.email) && isEmail(this.state.email);
  }

  handleSubmit() {
    if (!this.validateEmail()) {
      this.setState({ showWarnings: true });
      return;
    }

    this.setState({ showWarnings: false, submitting: true, showSuccess: false });

    stringActions
      .setString(this.props.auth.site, 'eventsubmissionemail', this.state.email)
      .then((res) => {
        this.setState({ submitting: false, showSuccess: true });
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <Button buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateEmail()}>
        Save
      </Button>
    );
  }

  renderSuccess() {
    if (!this.state.showSuccess) {
      return null;
    }
    return <span className="fontHeavy marginLeft-16 text-teal">Saved</span>;
  }

  renderEmail() {
    return (
      <div className="marginBottom-16 bottomDivideBorder" style={{ padding: '24px 32px' }}>
        <p className="text-sectionTitle">CONFIGURE SUBMISSION EMAIL</p>
        <div className="marginTop-16">
          <GenericInput
            id="email"
            type="text"
            label="Email"
            placeholder="example@domain.com"
            value={this.state.email}
            onChange={(e) => this.handleChange(e)}
            isRequired
            isValid={() => {
              return this.validateEmail();
            }}
            showError={() => {
              return this.state.showWarnings && !this.validateEmail();
            }}
            help="When an event submission is added, the entered address will recieve an email notification."
          />
        </div>
        <div className="flex flex-center">
          {this.renderSubmit()}
          {this.state.fetching && <div className="fontMedium marginLeft-16 text-tang">loading...</div>}
          {this.renderSuccess()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ minWidth: '100%', paddingTop: 75 }}>
        {this.renderEmail()}
        <Table className="plussTable" striped bordered condensed hover>
          <thead>
            <tr>
              <th
                className={`${this.sortIsActive('Changed')}`}
                style={{ cursor: 'pointer', width: 100 }}
                onClick={() => {
                  this.sortByCol('Changed');
                }}
              >
                Time{this.renderSort('Changed')}
              </th>
              <th
                className={`${this.sortIsActive('Title')}`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.sortByCol('Title');
                }}
              >
                Title{this.renderSort('Title')}
              </th>
              <th
                className={`${this.sortIsActive('SubmittedBy')}`}
                style={{ cursor: 'pointer', width: 150 }}
                onClick={() => {
                  this.sortByCol('SubmittedBy');
                }}
              >
                Submitted By{this.renderSort('SubmittedBy')}
              </th>
            </tr>
          </thead>
          <tbody>{this.renderEvents()}</tbody>
        </Table>
        <div className="hub_tidioPadding"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { submissions } = state.events;
  const { auth } = state;
  return {
    submissions,
    auth,
    paymentEnabled: auth.user && auth.user.paymentInfo && auth.user.paymentInfo.enabled,
  };
};

export default connect(mapStateToProps, { eventSubmissionsLoaded })(EventSubmissions);
