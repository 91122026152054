import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { isTheBest, getApiError } from '../../session';
import { renderTitle, renderDescription, renderTextStep } from './helper';

const REPO_WEB = 'minuss-admin';
const REPO_AWS = 'minuss-aws';
const INTERVAL_BUILD_CHECK = 10000;
const RESULT_SUCCESS = 'SUCCESSFUL';
const STATUS_COMPLETE = 'COMPLETED';
const STEP_HOSTING = '1';
const STEP_DEPLOY = '2';
const STEP_DEPLOY_AWS = '2.1';
const STEP_DEPLOY_Web = '2.2';
const STEP_INIT = '3';
const STEP_TC = '4';
const STEP_SUBDOMAIN = '5';

class WebSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: {},
    };
    this.steps = [
      {
        label: 'Set up Hosting',
        name: STEP_HOSTING,
        render: this.renderStep1,
        validator: () => this.isDone() || this.canDeploy(),
      },
      {
        label: 'Deploy',
        name: STEP_DEPLOY,
        render: this.renderStep2,
        validator: () => this.isDone() || this.canInitialiseSite(),
      },
      {
        label: 'Initialise Site',
        name: STEP_INIT,
        render: this.renderStep3,
        validator: () => this.isDone() || this.canSetupTC(),
      },
      {
        label: 'Set up Terms & Conditions',
        name: STEP_TC,
        render: this.renderStep4,
        validator: () => this.isDone() || this.canSetupSubdomain(),
      },
      { label: 'Set up Sub Domain', name: STEP_SUBDOMAIN, render: this.renderStep5 },
    ];
  }

  componentDidMount() {
    this.checkDeployment(STEP_DEPLOY_AWS);
    this.checkDeployment(STEP_DEPLOY_Web);
  }

  componentDidUpdate(prevProps) {
    const { activeWhiteLabel } = this.props;
    if (activeWhiteLabel.ClientCode !== prevProps.activeWhiteLabel.ClientCode) {
      this.checkDeployment(STEP_DEPLOY_AWS);
      this.checkDeployment(STEP_DEPLOY_Web);
    }
  }

  isDone = () => {
    const { activeWhiteLabel } = this.props;
    return activeWhiteLabel.HostingDomainUrl;
  };

  canPerformSetup = () => {
    const { activeWhiteLabel } = this.props;
    const { loading } = this.state;
    return activeWhiteLabel.RowId && activeWhiteLabel.AWSAccessKey && activeWhiteLabel.AWSSecretKey && !loading;
  };

  canSetupHosting = () => this.canPerformSetup();

  canDeploy = () => {
    const { activeWhiteLabel } = this.props;
    return this.canPerformSetup() && activeWhiteLabel.HostingDomainName;
  };

  canInitialiseSite = () => {
    const { activeWhiteLabel } = this.props;
    return (
      this.canDeploy() &&
      activeWhiteLabel.ApiGatewayName &&
      activeWhiteLabel.BuildAWS &&
      activeWhiteLabel.BuildAWS.Result === RESULT_SUCCESS &&
      activeWhiteLabel.BuildWeb &&
      activeWhiteLabel.BuildWeb.Result === RESULT_SUCCESS
    );
  };

  canSetupTC = () => {
    const { activeWhiteLabel } = this.props;
    return this.canInitialiseSite() && activeWhiteLabel.HostingUrl && activeWhiteLabel.AdminIntialised;
  };

  canSetupSubdomain = () => {
    const { activeWhiteLabel } = this.props;
    return this.canDeploy() && activeWhiteLabel.TermsSet;
  };

  getBuildByStep = (step) => {
    const { activeWhiteLabel } = this.props;
    const options = {};
    options[STEP_DEPLOY_AWS] = activeWhiteLabel.BuildAWS;
    options[STEP_DEPLOY_Web] = activeWhiteLabel.BuildWeb;
    // console.log('getBuildByStep', options[step]);
    return options[step];
  };

  getBuildByRepo = (repo) => {
    const { activeWhiteLabel } = this.props;
    const options = {};
    options[REPO_AWS] = activeWhiteLabel.BuildAWS;
    options[REPO_WEB] = activeWhiteLabel.BuildWeb;
    // console.log('getBuildByRepo', options[repo]);
    return options[repo];
  };

  checkDeployment = (step) => {
    const { message } = this.state;
    const build = this.getBuildByStep(step);
    if (!build) return;

    const newMessage = _.cloneDeep(message);
    newMessage[step] = 'Checking build...';
    this.setState({ loading: true, message: newMessage }, async () => {
      try {
        const { data } = await automationActions.getBuild(build.RowId);
        // console.log('checkDeployment', data);
        newMessage[step] = '';
        this.setState({ loading: false, message: newMessage }, () => {
          if (!data) return;
          this.changeBuildStatus(step, data);
          if (data.Status !== STATUS_COMPLETE) setTimeout(() => this.checkDeployment(step), INTERVAL_BUILD_CHECK);
        });
      } catch (error) {
        newMessage[step] = getApiError(error).message;
        this.setState({ loading: false, message: newMessage });
      }
    });
  };

  changeBuildStatus = (step, build) => {
    if (!build) return;

    const { activeWhiteLabel } = this.props;
    let changed = false;
    const update = (() => {
      const { RowId, PipelineId, PipelineUrl, Status, Result } = build;
      const buildInfo = _.pickBy({ RowId, PipelineId, PipelineUrl, Status, Result }, _.identity); // NOTE: Exclude undefined properties
      switch (step) {
        case STEP_DEPLOY_AWS:
          changed = !_.isEqual(activeWhiteLabel.BuildAWS, buildInfo);
          // console.log('changeBuildStatus - BuildAWS', { changed, from: activeWhiteLabel.BuildAWS, to: buildInfo });
          return { BuildAWS: buildInfo };
        case STEP_DEPLOY_Web:
          changed = !_.isEqual(activeWhiteLabel.BuildWeb, buildInfo);
          // console.log('changeBuildStatus - BuildWeb', { changed, from: activeWhiteLabel.BuildWeb, to: buildInfo });
          return { BuildWeb: buildInfo };
        default:
          return {};
      }
    })();
    if (!changed) return;

    const { message } = this.state;
    const newMessage = _.cloneDeep(message);
    newMessage[step] = '';
    this.setState({ loading: true, message: newMessage }, async () => {
      try {
        const newInfo = { ...activeWhiteLabel, ...update };
        // console.log('changeBuildStatus', update);
        const { data } = await automationActions.updateWhiteLabel(newInfo);
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false });
      } catch (error) {
        newMessage[step] = getApiError(error).message;
        this.setState({ loading: false, message: newMessage });
      }
    });
  };

  getStep = (repo) => {
    const options = {};
    options[REPO_AWS] = STEP_DEPLOY_AWS;
    options[REPO_WEB] = STEP_DEPLOY_Web;
    return options[repo];
  };

  onMoveStep = (stepIndex) => {
    if (this.props.onMoveStep) this.props.onMoveStep(stepIndex);
  };

  onSetup = (canSetup, step, inProgressMessage, action) => {
    if (canSetup && !canSetup()) return;

    const { message } = this.state;
    const { activeWhiteLabel } = this.props;

    const newMessage = _.cloneDeep(message);
    newMessage[step] = inProgressMessage;
    this.setState({ loading: true, message: newMessage }, async () => {
      try {
        const { data } = await automationActions.setupWeb(activeWhiteLabel.RowId, action);
        this.props.whiteLabelLoaded(data);
        newMessage[step] = '';
        this.setState({ loading: false });
      } catch (error) {
        newMessage[step] = getApiError(error).message;
        this.setState({ loading: false, message: newMessage });
      }
    });
  };

  onDeploy = (environment, repo) => {
    const step = this.getStep(repo);
    if (!step) return;

    const { message } = this.state;
    const newMessage = _.cloneDeep(message);
    newMessage[step] = 'Start deploying...';
    this.setState({ loading: true, message: newMessage }, async () => {
      try {
        const { data } = await automationActions.startDeployment({ environment, repo });
        // console.log('onDeploy', data);
        newMessage[step] = '';
        this.setState({ loading: false, message: newMessage }, () => {
          if (!data) return;
          this.changeBuildStatus(step, data);
          setTimeout(() => this.checkDeployment(step), INTERVAL_BUILD_CHECK);
        });
      } catch (error) {
        newMessage[step] = getApiError(error).message;
        this.setState({ loading: false, message: newMessage });
      }
    });
  };

  onStop = (repo, buildId, pipelineId) => {
    if (!window.confirm(`Are you sure you want to stop deploying?`)) return;

    const step = this.getStep(repo);
    if (!step) return;

    const { message } = this.state;
    const newMessage = _.cloneDeep(message);
    newMessage[step] = 'Stop deploying...';
    this.setState({ loading: true, message: newMessage }, async () => {
      try {
        const { data } = await automationActions.stopDeployment(repo, buildId, pipelineId);
        // console.log('onStop', data);
        newMessage[step] = '';
        this.setState({ loading: false, message: newMessage }, () => this.changeBuildStatus(step, data));
      } catch (error) {
        newMessage[step] = getApiError(error).message;
        this.setState({ loading: false, message: newMessage });
      }
    });
  };

  renderHostingUrl = () => {
    const { activeWhiteLabel } = this.props;
    return (
      <div>
        Hosting Domain Url:{' '}
        <a href={`https://${activeWhiteLabel.HostingDomainName}`} target="_blank" rel="noopener noreferrer">
          {activeWhiteLabel.HostingDomainName}
        </a>
        <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
      </div>
    );
  };

  renderSetupHosting = () => {
    const { activeWhiteLabel } = this.props;
    const { message } = this.state;
    const exists =
      !_.isEmpty(activeWhiteLabel.HostingBucket) &&
      !_.isEmpty(activeWhiteLabel.HostingUrl) &&
      !_.isEmpty(activeWhiteLabel.HostingDomainId) &&
      !_.isEmpty(activeWhiteLabel.HostingDomainName);
    const isValid = this.canSetupHosting();
    return (
      <div className="flex flex-row flex-center marginTop-10">
        {exists ? (
          <div>
            <div>
              {`Hosting Bucket: ${activeWhiteLabel.HostingBucket}`}
              <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
            </div>
            <div>
              {`Hosting Domain Id: ${activeWhiteLabel.HostingDomainId}`}
              <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
            </div>
            {this.renderHostingUrl()}
          </div>
        ) : (
          <Button
            style={{ width: 90 }}
            inline
            buttonType="primary"
            onClick={() => this.onSetup(this.canSetupHosting, STEP_HOSTING, 'Setting up hosting for admin console...', 'hosting')}
            isActive={isValid}
          >
            Setup
          </Button>
        )}
        <div className="marginLeft-16">{message[STEP_HOSTING]}</div>
      </div>
    );
  };

  renderSetupTC = () => {
    const { activeWhiteLabel } = this.props;
    const { message } = this.state;
    const exists = activeWhiteLabel.TermsSet;
    const isValid = this.canSetupTC();
    return (
      <div className="marginTop-10">
        <div>
          Terms & Conditions Set:
          {exists ? (
            <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
          ) : (
            <FontAwesome className="marginLeft-10 text-plussRed" name={'times'} />
          )}
        </div>
        {!exists ? (
          <div className="flex flex-row flex-center marginTop-10">
            <Button
              style={{ width: 90 }}
              inline
              buttonType="primary"
              onClick={() => this.onSetup(this.canSetupTC, STEP_TC, 'Setting up default terms and conditions...', 'tc')}
              isActive={isValid}
            >
              Setup
            </Button>
            <div className="marginLeft-16">{message[STEP_TC]}</div>
          </div>
        ) : null}
      </div>
    );
  };

  renderInitialise = () => {
    const { activeWhiteLabel } = this.props;
    const { message } = this.state;
    const exists = !_.isEmpty(activeWhiteLabel.HostingUrl) && activeWhiteLabel.AdminIntialised;
    const isValid = this.canInitialiseSite();
    return (
      <div className="flex flex-row flex-center marginTop-10">
        {exists ? (
          <div>
            <div>
              {`Initialised: ${activeWhiteLabel.AdminIntialised}`}
              {activeWhiteLabel.AdminIntialised ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
            </div>
            {this.renderHostingUrl()}
          </div>
        ) : (
          <Button
            style={{ width: 90 }}
            inline
            buttonType="primary"
            onClick={() => this.onSetup(this.canInitialiseSite, STEP_INIT, 'Initialising site...', 'init')}
            isActive={isValid}
          >
            Initialise
          </Button>
        )}
        <div className="marginLeft-16">{message[STEP_INIT]}</div>
      </div>
    );
  };

  renderSetupSubdomain = () => {
    const { activeWhiteLabel } = this.props;
    const { message } = this.state;
    const exists = !_.isEmpty(activeWhiteLabel.HostingDomainUrl);
    const isValid = this.canSetupSubdomain();

    return (
      <div className="flex flex-row flex-center marginTop-10">
        {exists ? (
          <div>
            <div>
              Url:{' '}
              <a href={`https://${activeWhiteLabel.HostingDomainUrl}`} target="_blank" rel="noopener noreferrer">
                {activeWhiteLabel.HostingDomainUrl}
              </a>
              <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
            </div>
            <div className="marginTop-5">
              <b>Note:</b> It could take as long as 8 hours before the above URL can be reached.
            </div>
          </div>
        ) : (
          <Button
            style={{ width: 90 }}
            inline
            buttonType="primary"
            onClick={() => this.onSetup(this.canSetupSubdomain, STEP_SUBDOMAIN, 'Setting up the sub domain...', 'subdomain')}
            isActive={isValid}
          >
            Setup
          </Button>
        )}
        <div className="marginLeft-16">{message[STEP_SUBDOMAIN]}</div>
      </div>
    );
  };

  renderDeploy = (repo) => {
    const { activeWhiteLabel } = this.props;
    const { message, loading } = this.state;
    const step = this.getStep(repo);
    if (!step) return null;

    const buildInfo = this.getBuildByRepo(repo);
    const hasBuildInfo = !_.isNil(buildInfo);
    const isComplete = hasBuildInfo && buildInfo.Status === STATUS_COMPLETE;
    const isSuccess = hasBuildInfo && buildInfo.Result === RESULT_SUCCESS;
    const canDeploy = !loading && (!hasBuildInfo || (isComplete && !isSuccess));
    const canStop = !loading && hasBuildInfo && !isComplete;
    // if (repo === REPO_WEB) console.log('renderDeploy', { hasBuildInfo, isComplete, isSuccess, canDeploy });

    return (
      <div>
        {hasBuildInfo ? (
          <div className="marginTop-10">
            <div>
              Build Url:{' '}
              {buildInfo.PipelineUrl ? (
                <a href={buildInfo.PipelineUrl} target="_blank" rel="noopener noreferrer">
                  {buildInfo.PipelineUrl}
                </a>
              ) : null}
            </div>
            <div>
              {`Status: ${buildInfo.Status || ''}`}
              {isComplete && <FontAwesome className="marginLeft-10 text-teal" name={'check'} />}
            </div>
            <div>
              {`Result: ${buildInfo.Result || ''}`}
              {isSuccess && <FontAwesome className="marginLeft-10 text-teal" name={'check'} />}
            </div>
          </div>
        ) : null}
        {!isSuccess ? (
          <div className="flex flex-row flex-center marginTop-10">
            <Button
              className="marginRight-16"
              style={{ width: 90 }}
              inline
              buttonType="primary"
              onClick={() => canDeploy && this.onDeploy(activeWhiteLabel.ClientCode, repo)}
              isActive={canDeploy}
            >
              Deploy
            </Button>
            <Button
              className="marginRight-16"
              style={{ width: 90 }}
              inline
              buttonType="primary"
              onClick={() => canStop && this.onStop(repo, buildInfo.RowId, buildInfo.PipelineId)}
              isActive={canStop}
            >
              Stop
            </Button>
            <div>{message[step]}</div>
          </div>
        ) : null}
      </div>
    );
  };

  renderStep1 = () => {
    return (
      <div>
        {renderTitle('Set up Hosting')}
        {renderDescription('In this step, we will setup hosting for the admin console website.')}
        <ol>
          {renderTextStep(
            <div>
              Create hosting
              {this.renderSetupHosting()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep2 = () => {
    return (
      <div>
        {renderTitle('Deploy Community Manager')}
        {renderDescription('In this step, we will deploy Community Manager and deploy backend services.')}
        <ol>
          {renderTextStep(
            <div>
              Deploy backend services
              {this.renderDeploy(REPO_AWS)}
            </div>,
          )}
          {renderTextStep(
            <div>
              Deploy website
              {this.renderDeploy(REPO_WEB)}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep3 = () => {
    return (
      <div>
        {renderTitle('Initialise Site Data')}
        {renderDescription('In this step, we will create master users and setup default user types.')}
        <ol>
          {renderTextStep(
            <div>
              Initialise the site
              {this.renderInitialise()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep4 = () => {
    return (
      <div>
        {renderTitle('Set up Terms & Conditions')}
        {renderDescription('In this step, we will setup the terms and conditions for the app.')}
        <ol>
          {renderTextStep(
            <div>
              Setup default Terms & Conditions
              {this.renderSetupTC()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep5 = () => {
    const { activeWhiteLabel } = this.props;
    return (
      <div>
        {renderTitle('Set up Sub Domain')}
        {renderDescription(
          'In this step, we will setup sub domain for the admin console website. This is an optional step and is not required to access the Commnity Manager.',
        )}
        <ol>
          {renderTextStep(
            <div>
              Follow the steps below in order to setup a sub domain for the Community Manager. Otherwise, you can access the Community
              Manager using the folloiwng link.
              <br />
              <br />
              {this.renderHostingUrl()}
            </div>,
          )}
          {renderTextStep(
            <div>
              Create sub domain
              {this.renderSetupSubdomain()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    const { stepIndex } = this.props;
    const step = stepIndex && stepIndex < this.steps.length ? stepIndex : 0;
    return (
      <div className="flex-1 automation">
        <StepProgressBar
          startingStep={step}
          progressClass="progressBar"
          primaryBtnClass="primaryBtn"
          secondaryBtnClass="secondaryBtn"
          submitBtnName="Done"
          onPrevious={() => this.onMoveStep(step - 1)}
          onNext={() => this.onMoveStep(step + 1)}
          onSubmit={() => (this.props.onDone ? this.props.onDone() : null)}
          steps={this.steps}
        />
        {this.steps[step].render()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(WebSetup);
