import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, GenericInput } from '../../components';
import { COLOUR_GREEN } from '../../js';
import { stringActions } from '../../webapi';
import { Text } from '../../components/text';

class EditAppTerms extends Component {
  state = {
    terms: '',
    termsTitle: 'Terms & Conditions',
    loaded: false,
    showWarnings: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    stringActions.getString(this.props.auth.site, 'termsofusetitle').then((res) => {
      this.setState({
        termsTitle: res.data,
      });
    });
    stringActions.getString(this.props.auth.site, 'termsofuse', true).then((res) => {
      this.setState({
        terms: res.data,
        loaded: true,
      });
    });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true, showWarnings: false });
    stringActions.setString(this.props.auth.site, 'termsofusetitle', this.state.termsTitle);
    stringActions
      .setString(this.props.auth.site, 'termsofuse', this.state.terms)
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
        });
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  onSuccess() {
    this.setState({ submitting: false, success: true });
  }

  validateForm() {
    return !_.isEmpty(this.state.terms) && !_.isEmpty(this.state.termsTitle);
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
        Save
      </Button>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return <span style={{ color: COLOUR_GREEN, fontSize: 14, lineHeight: '33px', marginLeft: 30 }}>Saved</span>;
  }

  renderEmpty() {
    if (_.isEmpty(this.state.terms) && this.state.loaded) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
          <div className="emptyState" />
          <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
            <Text type="h3">Set your Terms of Use here</Text>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div style={{ width: '100%', maxWidth: 600 }}>
        <GenericInput
          id="termsTitle"
          label="Terms Title"
          type="textarea"
          placeholder="Terms & Conditions"
          value={this.state.termsTitle}
          onChange={(e) => this.handleChange(e)}
          isRequired
          isValid={() => {
            return !_.isEmpty(this.state.termsTitle);
          }}
          showError={() => {
            return this.state.showWarnings && _.isEmpty(this.state.termsTitle);
          }}
        />
        <GenericInput
          id="terms"
          label="Terms"
          type="textarea"
          placeholder="Enter terms..."
          value={this.state.terms}
          onChange={(e) => this.handleChange(e)}
          inputStyle={{
            height: 500,
          }}
          isRequired
          isValid={() => {
            return !_.isEmpty(this.state.terms);
          }}
          showError={() => {
            return this.state.showWarnings && _.isEmpty(this.state.terms);
          }}
        />
        {this.renderSubmit()}
        {this.renderEmpty()}
        {this.renderSuccess()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(EditAppTerms);
