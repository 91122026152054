import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { formActions } from '../../webapi';
import { formsLoaded, removeForm } from '../../actions';
import { AddButton, Popup, GenericInput, DropdownInput } from '../../components';
import { getRolesWithAccess, mapRolesToSites, validateAccess } from '../../session';

class SignInFormList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyFormTitle: '',
      savingOrder: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const res = await formActions.getForms(this.props.auth.site, true);
      // console.log('getForms', res.data);
      this.props.formsLoaded(res.data);
    } catch (error) {
      console.log('getForms error', error);
    }
  };

  onDeleteForm = async (form) => {
    if (window.confirm(`Are you sure you want to delete this form?`)) {
      this.props.removeForm(form.RowId);

      try {
        await formActions.deleteForm(this.props.auth.site, form.RowId);
        this.props.removeForm(form.RowId);
      } catch (error) {
        console.log('deleteForm error', error);
      }
    }
  };

  onSetCopy = (form) => {
    const sites = mapRolesToSites(getRolesWithAccess((this.props.auth && this.props.auth.user && this.props.auth.user.Roles) || []));
    const selectedSite = _.find(sites, (s) => {
      return s.Id === this.props.auth.site;
    });
    this.setState({
      copyForm: form,
      copyFormTitle: !!form ? `Copy of ${form.Name}` : '',
      sites,
      selectedSite,
      copying: false,
      copySuccess: false,
    });
  };

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  isReadyToCopy = () => {
    if (_.isEmpty(this.state.copyFormTitle)) return false;
    if (this.state.copying) return false;
    return true;
  };

  handleCopy = () => {
    if (!this.isReadyToCopy()) return;
    const data = _.cloneDeep(this.state.copyForm);
    data.Name = this.state.copyFormTitle;
    data.Site = this.state.selectedSite.Id;
    delete data.Id;
    delete data.RowId;
    this.setState({
      copying: true,
    });
    formActions.saveForm(data, data.Site).then((res) => {
      this.setState({
        copySuccess: true,
        copying: false,
      });
      this.getData();
    });
  };

  selectSite = (key) => {
    this.setState({
      selectedSite: _.find(this.state.sites, (s) => {
        return s.Id === key;
      }),
    });
  };

  activateForm(form) {
    formActions.manageStatus(this.props.auth.site, form.Id, 'activate').then((res) => {
      form.Active = res.data.form.Active;
      this.props.formsLoaded([...this.props.forms]);
    });
  }

  deactivateForm(form) {
    if (window.confirm('Deactivating a form will hide it until you activate it again. Are you sure you want to continue?')) {
      formActions.manageStatus(this.props.auth.site, form.Id, 'deactivate').then((res) => {
        form.Active = res.data.form.Active;
        this.props.formsLoaded([...this.props.forms]);
      });
    }
  }

  getUpStyle(index) {
    if (this.state.savingOrder || index === 0) return { visibility: 'hidden' };
    return null;
  }

  getDownStyle(index) {
    if (this.state.savingOrder || index === this.props.forms.length - 1) return { visibility: 'hidden' };
    return null;
  }

  onMove = (ev, increment = 1) => {
    if (this.state.savingOrder) return;
    let source = _.sortBy(this.props.forms, 'Order');
    const index = source.indexOf(ev);
    const newIndex = index + increment;
    if (newIndex < 0 || newIndex > source.length - 1) return;

    source.splice(index, 1);
    source.splice(newIndex, 0, ev);
    this.saveOrder(source);
  };

  saveOrder = (source) => {
    const ordered = source.map((item, index) => {
      return { ...item, Order: index };
    });
    this.props.formsLoaded(ordered);

    const changes = ordered.map((item) => {
      return { RowId: item.RowId, Order: item.Order };
    });
    this.setState({ savingOrder: true }, async () => {
      try {
        await formActions.changeFormOrder(this.props.auth.site, changes);
      } catch (error) {
        console.log('changeFormOrder error', error);
      } finally {
        this.setState({ savingOrder: false });
      }
    });
  };

  renderOrder(form, index) {
    return (
      <div>
        <a onClick={() => this.onMove(form, -1)} style={this.getUpStyle(index)}>
          <FontAwesome style={styles.orderIcon} name="arrow-up" />
        </a>
        <a onClick={() => this.onMove(form, 1)} style={this.getDownStyle(index)}>
          <FontAwesome style={styles.orderIcon} name="arrow-down" />
        </a>
      </div>
    );
  }

  renderStatus(form) {
    if (form.Active) {
      return (
        <div
          className="tagWrapper tagWrapper--active"
          onClick={() => {
            return this.deactivateForm(form);
          }}
        >
          <p className="tagWrapper__content tagWrapper__text">Active</p>
          <p className="tagWrapper__hoverContent tagWrapper__text">Deactivate</p>
        </div>
      );
    }
    return (
      <div
        className="tagWrapper tagWrapper--inactive"
        onClick={() => {
          return this.activateForm(form);
        }}
      >
        <p className="tagWrapper__content tagWrapper__text">Inactive</p>
        <p className="tagWrapper__hoverContent tagWrapper__text">Activate</p>
      </div>
    );
  }

  renderList() {
    const { forms } = this.props;
    if (_.isEmpty(forms)) return null;

    return _.sortBy(forms, 'Order').map((ev, index) => {
      if (ev != null) {
        return (
          <tr key={index}>
            <td className="table-TitleColumn">
              <Link to={`/signinHub/form/${ev.RowId}`}>
                <span>{ev.Name}</span>
              </Link>
            </td>
            <td>{ev.Fields ? ev.Fields.length : 0}</td>
            <td>{this.renderOrder(ev, index)}</td>
            <td>{this.renderStatus(ev)}</td>
            <td className="table-options">
              <div className="formOptions">
                {validateAccess(this.props.auth.site, 'forms', this.props.auth) && (
                  <a onClick={() => this.onSetCopy(ev)}>
                    <FontAwesome className="icon" name="clone" />
                  </a>
                )}
                {validateAccess(this.props.auth.site, 'forms', this.props.auth) && (
                  <Link to={`/signinHub/form/${ev.RowId}`}>
                    <FontAwesome className="icon" name="pencil" />
                  </Link>
                )}
                {validateAccess(this.props.auth.site, 'forms', this.props.auth) && (
                  <a onClick={() => this.onDeleteForm(ev)}>
                    <FontAwesome className="icon" name="minus-circle" />
                  </a>
                )}
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderCopyForm() {
    if (!this.state.copyForm) return null;
    if (this.state.copying) {
      return <Popup title={`Copying ${this.state.copyForm.Name}`} maxWidth={600} hasPadding />;
    }
    if (this.state.copySuccess) {
      return (
        <Popup
          title={`Copied ${this.state.copyForm.Name}`}
          maxWidth={600}
          hasPadding
          buttons={[
            {
              type: 'primaryAction',
              onClick: () => {
                this.onSetCopy(false);
              },
              isActive: true,
              text: 'Done',
              className: 'button--signup',
            },
          ]}
        />
      );
    }
    return (
      <Popup
        title={`Create a copy of ${this.state.copyForm.Name}`}
        maxWidth={600}
        hasPadding
        buttons={[
          {
            type: 'outlinedAction',
            onClick: () => {
              this.onSetCopy(false);
            },
            isActive: true,
            text: 'Cancel',
            className: 'button--signup',
          },
          {
            type: 'primaryAction',
            onClick: this.handleCopy,
            isActive: this.isReadyToCopy(),
            text: 'Save Copy',
            className: 'button--signup',
          },
        ]}
        onClose={() => {
          this.onSetCopy(false);
        }}
      >
        <div className="marginTop-20 minHeight-400">
          <GenericInput
            id="copyFormTitle"
            type="text"
            label="Form Name"
            placeholder="Visitors"
            value={this.state.copyFormTitle}
            onChange={(e) => this.handleChange(e)}
          />

          <DropdownInput
            id="siteSelect"
            label="Site"
            placeholder="Site"
            value={this.state.selectedSite.Title}
            options={this.state.sites}
            onSelect={this.selectSite}
          />
        </div>
      </Popup>
    );
  }

  render() {
    return (
      <div className="signInForms">
        {this.renderCopyForm()}
        <AddButton onClick={this.props.onNew} text="NEW SIGN IN FORM" />
        <Table className="plussTable" striped bordered condensed hover>
          <thead>
            <tr>
              <th>Name</th>
              <th style={{ width: 100 }}>No. Fields</th>
              <th style={{ width: 120 }}></th>
              <th style={{ width: 120 }}>Status</th>
              <th style={{ width: 100 }}></th>
            </tr>
          </thead>
          <tbody>{this.renderList()}</tbody>
        </Table>
        <div className="hub_tidioPadding"></div>
      </div>
    );
  }
}

const styles = {
  orderIcon: {
    fontSize: 20,
    padding: 5,
    marginLeft: 8,
    cursor: 'pointer',
  },
};

const mapStateToProps = (state) => {
  const { auth, forms } = state;
  return {
    auth,
    forms: forms.list,
  };
};

export default connect(mapStateToProps, { formsLoaded, removeForm })(SignInFormList);
