import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Comment, Header, P60Icon, Text } from '../../components';
import { reactionActions } from '../../webapi';
import { getFeatureInfo, getPluralS } from '../../helper';
import { Link } from 'react-router-dom';

class CommentsDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rawComments: [],
      comments: [],
    };
  }

  componentDidMount() {
    this.connect();
  }

  componentWillUnmount() {
    this.disconnect();
  }

  connect = () => {
    this.getComments();

    this.commentsInterval = setInterval(this.getComments, 30000);
  };

  disconnect = () => {
    clearInterval(this.commentsInterval);
  };

  getComments = async () => {
    //TODO add
    const minTime = _.isEmpty(this.state.rawComments) ? 0 : this.state.rawComments[0].Timestamp + 1;
    reactionActions.getSiteComments(this.props.auth.site, 50, minTime).then((res) => {
      const rawComments = _.uniqBy([...this.state.rawComments, ...res.data], (c) => c.Id);
      const comments = _.cloneDeep(rawComments);
      const threads = _.groupBy(comments, (c) => c.EntityId);

      comments.forEach((c) => {
        c.ThreadCount = threads[c.EntityId].length;
      });

      const groupedComments = _.uniqBy(_.orderBy(comments, 'Timestamp', 'desc'), (c) => c.EntityId);

      this.setState({
        rawComments: rawComments,
        comments: groupedComments,
      });
    });
  };

  render() {
    return (
      <div className="pageContainer relative flex flex-column">
        <Header />
        <div className="hub-contentWrapper hub-contentWrapper-block">
          <Text type="h1" className="marginBottom-40">
            Comments
          </Text>

          {this.state.comments.map((comment) => {
            const feature = getFeatureInfo(comment.EntityType);
            return (
              <Link
                to={`/comments/thread/${comment.EntityId}${comment.ParentId ? `?parentId=${comment.ParentId}` : ''}`}
                className="dashboardComment dashboardComment-large"
              >
                <div className="dashboardComment_entity">
                  {feature.isFontAwesome ? (
                    <FontAwesome className="dashboardComment_entity_icon" name={feature.icon} />
                  ) : (
                    <P60Icon className="dashboardComment_entity_icon" icon={feature.icon} />
                  )}
                  <Text type="name" className="dashboardComment_entity_text">
                    {comment.EntityName}
                  </Text>
                </div>
                <Comment comment={comment} key={comment.Id} />
                {comment.ThreadCount > 1 && (
                  <Text type="body" className="dashboardComment_otherComments">
                    +{comment.ThreadCount - 1} other comment{getPluralS(comment.ThreadCount - 1)}
                  </Text>
                )}
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(withRouter(CommentsDashboard));
