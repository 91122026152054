import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getApiError } from '../../session';
import { GenericInput, Button } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { renderTitle, renderTextStep } from './helper';
import AppSelector from './common/AppSelector';

const MESSAGE_APP = 'app';

class AddAppLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabelInfo: _.cloneDeep(props.activeWhiteLabel),
      appInfo: null,
      loading: false,
      selectedApp: null,
      showWarnings: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { activeWhiteLabel } = this.props;
    if (
      activeWhiteLabel.ClientCode !== prevProps.activeWhiteLabel.ClientCode ||
      !_.isEqual(activeWhiteLabel.Apps, prevProps.activeWhiteLabel.Apps)
    ) {
      //   console.log('componentDidUpdate', activeWhiteLabel.Apps);
      this.setState({ whiteLabelInfo: activeWhiteLabel }, this.setAppInfo);
    }
  }

  setMessage = (key, message, extraStates = {}) => {
    return new Promise((resolve) => {
      const newState = { ...extraStates };
      newState[`message_${key}`] = message;
      // console.log('setMessage', newState);
      this.setState(newState, resolve());
    });
  };

  getMessage = (key) => this.state[`message_${key}`];

  getAppInfo = () => {
    const { selectedApp, whiteLabelInfo } = this.state;
    if (!selectedApp || !whiteLabelInfo || !whiteLabelInfo.Apps) return {};

    return whiteLabelInfo.Apps.find((a) => a.ClientCode === selectedApp.Key) || {};
  };

  setAppInfo = () => {
    this.setState({ appInfo: this.getAppInfo() });
  };

  isUrlValid = (url) => {
    return !_.isEmpty(url) && url.startsWith('https://');
  };

  isValid = () => {
    const { appInfo, loading } = this.state;
    return !loading && this.isUrlValid(appInfo.IOSUrl) && this.isUrlValid(appInfo.AndroidUrl);
  };

  onAppSelected = (selectedApp) => {
    this.setState({ selectedApp }, this.setAppInfo);
  };

  onUrlChanged = (field, event) => {
    const { appInfo } = this.state;
    const newAppInfo = _.cloneDeep(appInfo);
    newAppInfo[field] = event.target.value;
    this.setState({ appInfo: newAppInfo });
  };

  onSaveWhiteLabel = async () => {
    if (this.state.loading) return;
    if (!this.isValid()) {
      this.setState({ showWarnings: true });
      return;
    }

    try {
      await this.setMessage(MESSAGE_APP, 'Saving store links...', { loading: true, showWarnings: false });
      const { whiteLabelInfo, appInfo } = this.state;
      const newWhiteLabel = _.cloneDeep(whiteLabelInfo);
      const appIndex = newWhiteLabel.Apps.findIndex((a) => a.ClientCode === appInfo.ClientCode);
      newWhiteLabel.Apps[appIndex] = appInfo;

      // console.log('onSaveWhiteLabel - before', newWhiteLabel);
      const { data } = await automationActions.updateWhiteLabel(newWhiteLabel);
      // console.log('onSaveWhiteLabel - after', data);
      this.props.whiteLabelLoaded(data);
      await this.setMessage(MESSAGE_APP, 'Saved successfully', { loading: false });
      setTimeout(() => this.setMessage(MESSAGE_APP, ''), 5000);
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_APP, `Error: ${message}`, { loading: false });
    }
  };

  renderAppLinks() {
    const { appInfo, loading, showWarnings } = this.state;
    if (!appInfo) return null;

    const message = this.getMessage(MESSAGE_APP);
    return (
      <div className="marginTop-16">
        <div className="flex flex-row flex-center">
          <div style={{ width: 200 }}>Enter App Store Url</div>
          <GenericInput
            id="iosLink"
            style={{ width: 600, marginTop: 10, marginBottom: 10 }}
            type="text"
            placeholder="Enter App Store url for the app"
            value={appInfo.IOSUrl}
            onChange={(e) => this.onUrlChanged('IOSUrl', e)}
            disabled={loading}
            showError={() => showWarnings && !this.isUrlValid(appInfo.IOSUrl)}
            errorMessage={'Store url is not valid'}
          />
        </div>
        <div className="flex flex-row flex-center">
          <div style={{ width: 200 }}>Enter Google Play Store Url</div>
          <GenericInput
            id="iosLink"
            style={{ width: 600, marginTop: 10, marginBottom: 10 }}
            type="text"
            placeholder="Enter Google Play Store url for the app"
            value={appInfo.AndroidUrl}
            onChange={(e) => this.onUrlChanged('AndroidUrl', e)}
            disabled={loading}
            showError={() => showWarnings && !this.isUrlValid(appInfo.AndroidUrl)}
            errorMessage={'Store url is not valid'}
          />
        </div>
        <div className="flex flex-row flex-center marginTop-10">
          <Button inline buttonType="primary" onClick={() => this.onSaveWhiteLabel()} isActive={!loading}>
            Save
          </Button>
          <div className="marginLeft-16">{message}</div>
        </div>
      </div>
    );
  }

  render() {
    const { selectedApp, loading } = this.state;
    return (
      <div>
        {renderTitle('Add store links')}
        <ol>
          {renderTextStep(
            <div>
              <AppSelector onSelected={this.onAppSelected} selected={selectedApp} includeSubApps disabled={loading} />
            </div>,
          )}
          {selectedApp
            ? renderTextStep(
                <div>
                  Enter app store links
                  {this.renderAppLinks()}
                </div>,
              )
            : null}
        </ol>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { automation } = state;
  return { activeWhiteLabel: automation.active };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(AddAppLinks);
