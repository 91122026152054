import { authedFunction } from '../session';
import { getUrl } from './helper'

export const keyActions = {
    getKeys: (site) => {
        return authedFunction({
            method: 'GET',
            url: getUrl('keys', 'get', { site })
        });
    },
    getKey: (id) => {
        return authedFunction({
            method: 'GET',
            url: getUrl('keys', `get/${id}`)
        });
    },
    getKeyHistory: (id) => {
        return authedFunction({
            method: 'GET',
            url: getUrl('keys', `history/${id}`)
        });
    },
    addKey: (site, name) => {
        return authedFunction({
            method: 'POST',
            url: getUrl('keys', 'add'),
            data: {
                site,
                name
            }
        });
    },
    editKey: (site, id, name) => {
        return authedFunction({
            method: 'POST',
            url: getUrl('keys', 'edit'),
            data: {
                site,
                id,
                name
            }
        });
    },
    deleteKey: (site, id) => {
        return authedFunction({
            method: 'POST',
            url: getUrl('keys', 'remove'),
            data: {
                site,
                id
            }
        });
    },
    registerKey: (site, id, name, phone, email) => {
        return authedFunction({
            method: 'POST',
            url: getUrl('keys', 'register'),
            data: {
                site,
                id,
                heldBy: {
                    name,
                    phone,
                    email
                }
            }
        });
    },
    deregisterKey: (site, id) => {
        return authedFunction({
            method: 'POST',
            url: getUrl('keys', 'deregister'),
            data: {
                site,
                id
            }
        });
    }
}