import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import _ from 'lodash';
import { Header, HubSidebar } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { getApiError } from '../../session';
import AWSAccount from './AWSAccount';
import AWSSetup from './AWSSetup';
import Repository from './Repository';
import WebSetup from './WebSetup';
import AppSetupiOS from './AppSetupiOS';
import AppSetupAndroid from './AppSetupAndroid';
import Deployment from './Deployment';
import Extensions from './Extensions';
import AddSubApp from './AddSubApp';
import AddAppLinks from './AddAppLinks';
import ReplaceAppColours from './ReplaceAppColours';
import ReplaceAppAssets from './ReplaceAppAssets';
import BuildApp from './BuildApp';

const SHORTCUT_WHITELABEL = 'whitelabel';

class AutomationHub extends Component {
  constructor(props) {
    super(props);
    const { activeWhiteLabel } = props;
    this.state = {
      selectedShortcut: null,
      sectionIndex: activeWhiteLabel.Section,
      // sectionIndex: 2,
      stepIndex: activeWhiteLabel.Step,
    };
    this.shortcuts = [
      {
        key: SHORTCUT_WHITELABEL,
        label: 'New Whitelabel App',
        icon: 'plus',
        sections: [
          {
            key: 'AWSAccount',
            label: '1 - Create Account',
            render: (stepIndex) => <AWSAccount onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
          },
          {
            key: 'AWSSetup',
            label: '2 - Set up AWS',
            render: (stepIndex) => <AWSSetup onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
          },
          {
            key: 'Configure',
            label: '3 - Configure Source Code',
            render: (stepIndex) => <Repository onDone={this.onDone} />,
          },
          {
            key: 'WebSetup',
            label: '4 - Set up Web',
            render: (stepIndex) => <WebSetup onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
          },
          {
            key: 'AppSetupiOS',
            label: '5 - Set up iOS',
            render: (stepIndex) => <AppSetupiOS onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
          },
          {
            key: 'AppSetupAndroid',
            label: '6 - Set up Android',
            render: (stepIndex) => <AppSetupAndroid onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
          },
        ],
      },
      {
        key: 'addSubApp',
        label: 'Add Sub App',
        icon: 'mobile',
        sections: [
          {
            key: 'AddSubApp',
            label: '',
            render: () => <AddSubApp onChangeShortcut={this.onSelectShortcut} />,
          },
        ],
      },
      {
        key: 'appColours',
        label: 'Replace App Colours',
        icon: 'paint-brush',
        sections: [
          {
            key: 'ReplaceAppColours',
            label: '',
            render: () => <ReplaceAppColours />,
          },
        ],
      },
      {
        key: 'appAssets',
        label: 'Replace App Images',
        icon: 'file-image-o',
        sections: [
          {
            key: 'ReplaceAppAssets',
            label: '',
            render: () => <ReplaceAppAssets />,
          },
        ],
      },
      {
        key: 'appLinks',
        label: 'Add Store Links',
        icon: 'link',
        sections: [
          {
            key: 'AddAppLinks',
            label: '',
            render: () => <AddAppLinks />,
          },
        ],
      },
      {
        key: 'buildApp',
        label: 'Build App',
        icon: 'wrench',
        sections: [
          {
            key: 'BuildApp',
            label: '',
            render: () => <BuildApp />,
          },
        ],
      },
      {
        key: 'deploy',
        label: 'Deploy',
        icon: 'cloud-upload',
        sections: [
          {
            key: 'Deployment',
            label: '',
            render: () => <Deployment />,
          },
        ],
      },
      {
        key: 'extend',
        label: 'Extend',
        icon: 'puzzle-piece',
        sections: [
          {
            key: 'Extensions',
            label: '',
            render: () => <Extensions />,
          },
        ],
      },
    ];
  }

  componentDidMount() {
    // this.setState({
    //   selectedShortcut: {
    //     key: 'appColours',
    //     label: 'Replace App Colours',
    //     icon: 'paint-brush',
    //     sections: [
    //       {
    //         key: 'ReplaceAppColours',
    //         label: '',
    //         render: () => <ReplaceAppColours />,
    //       },
    //     ],
    //   },
    // });
  }

  onSaveWhiteLabel = async ({ sectionIndex, stepIndex } = { sectionIndex: null, stepIndex: null }) => {
    const { activeWhiteLabel } = this.props;
    if (!activeWhiteLabel || !activeWhiteLabel.RowId) return;

    if (sectionIndex !== null) activeWhiteLabel.Section = sectionIndex;
    if (stepIndex !== null) activeWhiteLabel.Step = stepIndex;
    try {
      const { data } = await automationActions.updateWhiteLabel(activeWhiteLabel);
      this.props.whiteLabelLoaded(data);
    } catch (error) {
      console.log('onSaveWhiteLabel error', getApiError(error).message);
    }
  };

  onSelectShortcut = (shortcut) => {
    let selectedShortcut = shortcut;
    if (typeof selectedShortcut === 'string') {
      selectedShortcut = this.shortcuts.find((s) => s.key === selectedShortcut);
    }
    this.setState({ selectedShortcut });
  };

  onSelectSection = (newIndex) => {
    const { sectionIndex, stepIndex } = this.state;
    const newStepIndex = sectionIndex !== newIndex ? 0 : stepIndex;
    this.setState({ sectionIndex: newIndex, stepIndex: newStepIndex }, () =>
      this.onSaveWhiteLabel({ sectionIndex: this.state.sectionIndex, stepIndex: this.state.stepIndex }),
    );
  };

  onDone = () => {
    const { sectionIndex } = this.state;
    const whiteLabelShortcut = this.shortcuts.find((s) => s.key === SHORTCUT_WHITELABEL);
    if (whiteLabelShortcut) {
      if (sectionIndex < this.whiteLabelShortcut.sections.length - 1) this.onSelectSection(sectionIndex + 1);
    }
  };

  onMoveStep = (stepIndex) => {
    this.setState({ stepIndex }, () => this.onSaveWhiteLabel({ stepIndex: this.state.stepIndex }));
  };

  renderShortcut(shortcut) {
    return (
      <div key={shortcut.key} className="dashboardButton" onClick={() => this.onSelectShortcut(shortcut)}>
        <div className="dashboardButton_inner">
          <div className="dashboardButton_iconContainer">
            <FontAwesome className="dashboardButton_icon" name={shortcut.icon} />
          </div>
          <p className="dashboardButton_text" style={{ fontSize: 16, height: 50 }}>
            {shortcut.label}
          </p>
        </div>
      </div>
    );
  }

  renderShortcuts() {
    return <div>{this.shortcuts.map((s) => this.renderShortcut(s))}</div>;
  }

  renderLeftBar() {
    const { selectedShortcut } = this.state;
    const sectionItems = selectedShortcut
      ? selectedShortcut.sections
          .filter((section) => !_.isEmpty(section.label))
          .map((section, index) => {
            return {
              type: 'navItem',
              text: section.label,
              selected: this.state.selectedSection === index,
              onClick: () => this.onSelectSection(index),
            };
          })
      : [];
    if (selectedShortcut) {
      sectionItems.splice(0, 0, {
        type: 'button',
        text: 'Home',
        onClick: () => this.setState({ selectedShortcut: null, sectionIndex: 0, stepIndex: 0 }),
      });
    }

    return (
      <HubSidebar
        sections={[
          {
            title: 'Automation',
            items: sectionItems,
          },
        ]}
      />
    );
  }

  renderRight() {
    const { selectedShortcut, sectionIndex, stepIndex } = this.state;
    if (selectedShortcut && sectionIndex < selectedShortcut.sections.length) {
      return selectedShortcut.sections[sectionIndex].render(stepIndex);
    }
    return this.renderShortcuts();
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        <style>{`#tidio-chat { display: none}`}</style>
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
    paddingRight: 32,
    whiteSpace: 'nowrap',
  },
};

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  // console.log('automation.active', automation.active);
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(AutomationHub);
