import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { Comment, Header, P60Icon, Tabs } from '../../components';
import { LINEGREY, COLOUR_BRANDING_OFF } from '../../js';
import { checkLoggedIn, validateAccess } from '../../session';
import { hasFeaturePicker } from '../../config';
import {
  setAuth,
  eventsUpdate,
  eventSubmissionsUpdate,
  newsUpdate,
  newsSubmissionsUpdate,
  getUserCount,
  jobsUpdate,
  facilitiesUpdate,
  servicesUpdate,
  eventsManagedUpdate,
  imageLibraryUpdate,
  devicesLoaded,
} from '../../actions';
import { activityActions, analyticsActions, deviceActions, reactionActions } from '../../webapi';
import ActivityEntry from './ActivityEntry';
import { get1400, getFeatureInfo, getPluralS } from '../../helper';
import { SignUpSuccess } from '../../components/onboarding/SignUpSuccess';
import { IntroComplete, OnboardingStep } from '../../components/onboarding';
import { Text } from '../../components/text';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: '',
      height: 0,
      width: 0,
      events: [],
      upcomingEvents: [],
      activity: [],
      comments: [],
      loadingUserStats: false,
      appUseWeek: 0,
      appUseMonth: 0,
      adminUseWeek: 0,
      adminUseMonth: 0,
      selectedTab: 'comments',
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    const newState = {};
    if (!_.isUndefined(this.props.auth.site)) {
      newState.location = this.props.auth.site;
      if (this.props.auth.user) {
        analyticsActions.log(this.props.auth.site, 'AdminUse', 'user', this.props.auth.user.Id, { sourceId: this.props.auth.user.Id });
      }
    }
    this.setState(newState);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.connect();
    this.updateDashboard();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isUndefined(nextProps.auth.site) && this.state.location !== nextProps.auth.site) {
      this.setState({ location: nextProps.auth.site });
      setTimeout(() => {
        this.updateDashboard();
      }, 200);
    }
    this.updateProps(nextProps);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    this.disconnect();
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  updateProps(props) {
    const newStates = {};
    newStates.events = [];

    //Events setup
    props.events.forEach((ev) => {
      if (ev != null && !ev.Deleted) {
        newStates.events.push(ev);
      }
    });

    newStates.upcomingEvents = _.filter(newStates.events, (ev) => {
      if (ev.IsEveryday) {
        return true;
      }
      if (ev.RepeatedTimes) {
        if (
          _.some(ev.RepeatedTimes, (rep) => {
            return moment.utc(rep.Time).isSameOrAfter(this.state.now);
          })
        ) {
          return true;
        }
        return false;
      }
      return (
        moment.utc(ev.StartTime).isSameOrAfter(this.state.now) ||
        (ev.EndTime != null && ev.EndTime !== '' && moment.utc(ev.EndTime).isSameOrAfter(this.state.now))
      );
    });

    this.setState(newStates);
  }

  connect = () => {
    this.getComments();

    this.commentsInterval = setInterval(this.getComments, 30000);
  };

  disconnect = () => {
    clearInterval(this.commentsInterval);
  };

  getUserStats() {
    this.setState({ loadingUserStats: true }, async () => {
      try {
        const newStates = { loadingUserStats: false };
        const today = moment().valueOf();

        // Get month usage
        const monthAgo = moment().subtract(1, 'months').valueOf();
        const monthRes = await analyticsActions.getAggregateEntityStats(this.props.auth.site, 'user', monthAgo, today);
        const monthStats = Object.values(monthRes.data);
        newStates.appUseMonth = monthStats.filter((stat) => stat.AppUse).length;
        newStates.adminUseMonth = monthStats.filter((stat) => stat.AdminUse).length;

        // Get week usage
        const weekAgo = moment().subtract(1, 'weeks').valueOf();
        const weekRes = await analyticsActions.getAggregateEntityStats(this.props.auth.site, 'user', weekAgo, today);
        const weekStats = Object.values(weekRes.data);
        newStates.appUseWeek = weekStats.filter((stat) => stat.AppUse).length;
        newStates.adminUseWeek = weekStats.filter((stat) => stat.AdminUse).length;

        this.setState(newStates);
      } catch (error) {
        console.log('getUserStats', error);
        this.setState({ loadingUserStats: false });
      }
    });
  }

  updateDashboard() {
    this.getUserStats();

    // update events
    if (Object.keys(this.props.events).length > 0) {
      var maxElem = _.maxBy(this.props.events, (event) => {
        if (!event) {
          return 0;
        }
        return event.Changed;
      });
      this.props.eventsUpdate(this.props.auth.site, maxElem.Changed + 1, true);
    } else {
      this.props.eventsUpdate(this.props.auth.site, null, true);
    }
    this.props.eventSubmissionsUpdate(this.props.auth.site, true);
    this.props.eventsManagedUpdate(this.props.auth.site, true);

    // News
    this.props.newsUpdate(this.props.auth.site, true);
    this.props.newsSubmissionsUpdate(this.props.auth.site, true);
    this.props.getUserCount(this.props.auth.site, true);
    this.props.jobsUpdate(this.props.auth.site, true);
    this.props.facilitiesUpdate(this.props.auth.site, true);
    this.props.servicesUpdate(this.props.auth.site, true);
    this.props.imageLibraryUpdate(this.props.auth.site, true);

    this.getActivity();
    this.getDevices();
  }

  getDevices = async () => {
    try {
      const res = await deviceActions.getDevices(this.props.auth.site);
      // console.table('getDevices', res.data);
      this.props.devicesLoaded(res.data);
    } catch (error) {
      console.log('getDevices error', error);
    } finally {
      this.setState({ loading: false });
    }
  };

  selectTab = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  getActivity() {
    activityActions.getActivity(this.props.auth.site, 50).then((res) => {
      this.setState({
        activity: res.data,
      });
    });
  }

  getComments = async () => {
    const minTime = _.isEmpty(this.state.comments) ? 0 : this.state.comments[0].Timestamp + 1;
    reactionActions.getSiteComments(this.props.auth.site, 50, minTime).then((res) => {
      const comments = _.uniqBy([...this.state.comments, ...res.data], (c) => c.Id);

      this.setState({
        comments: _.orderBy(comments, 'Timestamp', 'desc'),
      });
    });
  };

  renderStats(content, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return content;
  }

  getFeaturePickerButtonClass() {
    return 'dashboardFeaturePickerButton';
  }

  getImageStyle(displayValue) {
    if (_.isEmpty(displayValue)) return null;

    const image = get1400(displayValue);
    return { backgroundImage: `url(${image})` };
  }

  renderGalleryImage(image, index) {
    const isImage = !_.isNil(image.uri);

    let dateText, timeText;
    if (isImage) {
      const imageDate = moment(image.date);
      dateText = imageDate.format('MMMM D, YYYY');
      timeText = imageDate.format('hh:mm A');
    }
    return (
      <div
        key={index}
        className="galleryImageContainer"
        style={isImage ? null : { cursor: 'pointer' }}
        onClick={() => {
          if (!isImage) this.props.history.push('/imageLibrary');
        }}
      >
        <div className="topSection" style={isImage ? { ...this.getImageStyle(image.uri) } : null}>
          {!isImage && <div className="imageLibraryIcon" />}
        </div>
        <div className="bottomSection">
          {isImage ? (
            <div className="imageDate">{`Uploaded ${dateText} • ${timeText}`}</div>
          ) : (
            <div className="imageLibraryText">Explore Gallery</div>
          )}
        </div>
      </div>
    );
  }

  renderCommunityGallery() {
    const publishingFolder = this.props.imageLibrary.find((folder) => folder.Public);
    if (!publishingFolder) return null;

    const publishingImages = _.take(_.orderBy(publishingFolder.Images, 'date', 'desc'), 4);
    publishingImages.push({});

    return (
      <div>
        <div style={styles.contentSection}>
          <div className="fontSize-16 text-light lineHeight-22 fontRegular" style={styles.contentHeader}>
            Community Gallery
          </div>
          <div style={styles.content}>
            <div style={{ ...styles.innerSideContent, display: 'flex', gap: 14 }}>
              {publishingImages.map((image, index) => {
                return this.renderGalleryImage(image, index);
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderActivateTV() {
    if (!validateAccess(this.props.auth.site, 'activateDevices', this.props.auth)) {
      return null;
    }
    return (
      <div className="dashboardModules">
        <Text type="h4" className="dashboardModules_title">
          Modules
        </Text>
        <Link className="dashboard_activateTV" to={`/activatetv`}>
          <div className="dashboard_activateTV_top">
            <div className="dashboard_activateTV_top_icon"></div>
            <div className="dashboard_activateTV_top_activate">
              <p className="dashboard_activateTV_top_activate_text">Activate a TV</p>
              <FontAwesome className="dashboard_activateTV_top_activate_icon" name="angle-right" />
            </div>
          </div>
          <div className="dashboard_activateTV_bottom">
            <p className="dashboard_activateTV_bottom_text">{`${this.props.devices.length} Active TV${getPluralS(
              this.props.devices.length,
            )}`}</p>
          </div>
        </Link>
      </div>
    );
  }

  renderOverviewStats() {
    return (
      <div style={styles.contentSection}>
        <div className="fontRegular fontSize-16 text-light lineHeight-22" style={styles.contentHeader}>
          Overview
        </div>
        <div style={{ ...styles.content, display: 'flex', justifyContent: 'space-around' }}>
          <div style={styles.innerContent}>
            <div className="fontRegular fontSize-26 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.appUseWeek, this.state.loadingUserStats)}
            </div>
            <div className="fontLight fontSize-13 text-light lineHeight-22">Users logged in to app this week</div>
          </div>
          <div style={styles.innerContent}>
            <div className="fontRegular fontSize-26 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.appUseMonth, this.state.loadingUserStats)}
            </div>
            <div className="fontLight fontSize-13 text-light lineHeight-22">Users logged in to app this month</div>
          </div>
          <div style={styles.innerContent}>
            <div className="fontRegular fontSize-26 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.adminUseWeek, this.state.loadingUserStats)}
            </div>
            <div className="fontLight fontSize-13 text-light lineHeight-22">Users logged in to Community Manager this week</div>
          </div>
          <div style={styles.innerContent}>
            <div className="fontRegular fontSize-26 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.adminUseMonth, this.state.loadingUserStats)}
            </div>
            <div className="fontLight fontSize-13 text-light lineHeight-22">Users logged in to Community Manager this month</div>
          </div>
        </div>
      </div>
    );
  }

  renderAddButton(feature) {
    return (
      <Link to={feature.addUrl} key={feature.key} className="dashboardButton">
        <div className="dashboardButton_inner">
          <div className="dashboardButton_iconContainer">
            {feature.isFontAwesome ? (
              <FontAwesome className="dashboardButton_icon" name={feature.icon} />
            ) : (
              <P60Icon className="dashboardButton_icon" icon={feature.icon} />
            )}
          </div>
          <p className="dashboardButton_text">
            New
            <br />
            {feature.singularName.toLowerCase()}
          </p>
        </div>
      </Link>
    );
  }

  renderAddButtons() {
    // filter to valid features
    const validFeatures = _.filter(
      (this.props.auth.features || []).map((f) => {
        return getFeatureInfo(f);
      }),
      (f) => {
        if (!f.addPermission || !f.addUrl) {
          return false;
        }
        return validateAccess(this.props.auth.site, f.addPermission, this.props.auth);
      },
    );
    // sort by recently used and only show 4
    const featuresToShow = _.take(
      _.sortBy(validFeatures, (f) => {
        const index = _.findIndex(this.props.recentlyCreated, (r) => {
          return r === f.key;
        });
        return index < 0 ? 1000 : index;
      }),
      4,
    );
    if (_.isEmpty(featuresToShow)) {
      return null;
    }
    return (
      <div className="dashboardButtons">
        <Text type="h4" className="dashboardButtons_title">
          Start creating your content
        </Text>
        <div>
          {featuresToShow.map((f) => {
            return this.renderAddButton(f);
          })}
        </div>
      </div>
    );
  }

  renderMiddle() {
    if (this.props.auth.site === 'hq') {
      return this.renderHQ();
    }
    return (
      <div style={{ flex: 1, paddingTop: 16 }}>
        <div style={{ maxWidth: 1200, margin: '0 auto' }}>
          {this.props.auth && this.props.auth.user && (
            <Text type="h1" className="marginBottom-40">
              Hi, {this.props.auth.user.displayName}
            </Text>
          )}
          {this.renderAddButtons()}
          {this.renderActivateTV()}
        </div>
      </div>
    );
  }

  renderComments() {
    return (
      <div>
        <Link className="dashboard_seeAllButton" to={`/comments`}>
          <div className="dashboard_seeAllButton_top">
            <FontAwesome className="dashboard_seeAllButton_top_icon" name="comment" />
            <div className="dashboard_seeAllButton_top_seeAll">
              <p className="dashboard_seeAllButton_top_seeAll_text">See all Comments</p>
              <FontAwesome className="dashboard_seeAllButton_top_seeAll_icon" name="angle-right" />
            </div>
          </div>
        </Link>
        {this.state.comments.map((comment) => {
          const feature = getFeatureInfo(comment.EntityType);
          return (
            <Link
              to={`/comments/thread/${comment.EntityId}${comment.ParentId ? `?parentId=${comment.ParentId}` : ''}`}
              className="dashboardComment"
            >
              <div className="dashboardComment_entity">
                {feature.isFontAwesome ? (
                  <FontAwesome className="dashboardComment_entity_icon" name={feature.icon} />
                ) : (
                  <P60Icon className="dashboardComment_entity_icon" icon={feature.icon} />
                )}
                <Text type="name" className="dashboardComment_entity_text">
                  {comment.EntityName}
                </Text>
              </div>
              <Comment comment={comment} key={comment.Id} />
            </Link>
          );
        })}
      </div>
    );
  }

  renderActivity() {
    return this.state.activity.map((activity, index) => {
      return <ActivityEntry data={activity} index={index} key={index} />;
    });
  }

  renderUsage() {
    return (
      <div className="paddingVertical-8 paddingHorizontal-16 marginTop-16 genericBox">
        <div>
          <Text type="statsNumber" className="inline marginRight-10">
            {this.renderStats(this.state.appUseWeek, this.state.loadingUserStats)}
          </Text>
          <Text type="statsText" className="inline">
            Users logged in to app this week
          </Text>
        </div>
        <div className="marginTop-8">
          <Text type="statsNumber" className="inline marginRight-10">
            {this.renderStats(this.state.appUseMonth, this.state.loadingUserStats)}
          </Text>
          <Text type="statsText" className="inline">
            Users logged in to app this month
          </Text>
        </div>
        <div className="marginTop-8">
          <Text type="statsNumber" className="inline marginRight-10">
            {this.renderStats(this.state.adminUseWeek, this.state.loadingUserStats)}
          </Text>
          <Text type="statsText" className="inline">
            Users logged in to Community Manager this week
          </Text>
        </div>
        <div className="marginTop-8">
          <Text type="statsNumber" className="inline marginRight-10">
            {this.renderStats(this.state.adminUseMonth, this.state.loadingUserStats)}
          </Text>
          <Text type="statsText" className="inline">
            Users logged in to Community Manager this month
          </Text>
        </div>
      </div>
    );
  }

  renderRight() {
    return (
      <div className="hub-rightContentWrapper">
        <Tabs
          onSelectTab={this.selectTab}
          selectedTab={this.state.selectedTab}
          className="tabContainer-dashboard"
          tabs={[
            {
              value: 'comments',
              text: 'Comments',
            },
            {
              value: 'activity',
              text: 'Recent Activity',
            },
            {
              value: 'usage',
              text: 'Usage',
            },
          ]}
        />
        {this.state.selectedTab === 'comments' && this.renderComments()}
        {this.state.selectedTab === 'activity' && this.renderActivity()}
        {this.state.selectedTab === 'usage' && this.renderUsage()}
      </div>
    );
  }

  renderDefault() {
    if (this.props.auth.site !== 'default') {
      return null;
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <span className="fontRegular fontSize-13">
            <span className="fontHeavy text-brandingColour">Default Values </span>
            is a space for you to create content that can be used across all new sites.
          </span>
        </div>
        <div className="marginTop-8 fontRegular fontSize-13" style={{ maxWidth: 500, textAlign: 'center' }}>
          Examples might be; User Types to be copied to new sites.
        </div>
      </div>
    );
  }

  renderHQ() {
    if (this.props.auth.site !== 'hq') {
      return null;
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <span className="fontRegular fontSize-13">
            <span className="fontHeavy text-brandingColour">HQ </span>
            is a space for you to manage content across all your sites.
          </span>
        </div>
        <div className="marginTop-8 fontRegular fontSize-13" style={{ maxWidth: 500, textAlign: 'center' }}>
          Examples might be; viewing a comprehensive user list, or publishing news or surveys to all your sites.
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="hub-wrapperContainer" style={{ minHeight: this.state.height, backgroundColor: '#f4f7f9' }}>
        <SignUpSuccess />
        <IntroComplete />
        <OnboardingStep step="intro_dash_1" />
        <div className="hub-headerContentWrapper">
          <Header>
            {hasFeaturePicker && validateAccess(this.props.auth.site, 'featurePicker', this.props.auth) && (
              <Link className={this.getFeaturePickerButtonClass()} to="/featurepicker">
                <p className="dashboardFeaturePickerButton_text">FEATURE PICKER</p>
                <FontAwesome className="dashboardFeaturePickerButton_icon" name="mobile" />
              </Link>
            )}
          </Header>
          {this.renderDefault()}
          {this.props.auth.site !== 'default' && (
            <div className="hub-contentWrapper" style={{ flexDirection: 'row-reverse' }}>
              {this.renderRight()}
              {this.renderMiddle()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  contentSection: {
    backgroundColor: '#fff',
    marginBottom: 32,
    width: '100%',
    borderRadius: 5,
    boxShadow: '1px 1px 32px rgba(106, 163, 216, 0.2)',
    overflow: 'hidden',
  },
  contentHeader: {
    padding: 16,
    borderBottom: `1px solid ${LINEGREY}`,
  },
  content: {
    padding: 16,
  },
  spinner: {
    color: COLOUR_BRANDING_OFF,
  },
  innerContent: {
    marginRight: 10,
    maxWidth: 200,
  },
};

const mapStateToProps = (state) => {
  const { events, auth, media, devices, local } = state;

  return {
    events: events.events,
    managedEvents: events.managed,
    imageLibrary: media.imageLibrary.filter((folder) => folder.RowId),
    auth,
    devices: devices.list,
    recentlyCreated: local.recentlyCreated || [],
  };
};

export default connect(mapStateToProps, {
  setAuth,
  eventsUpdate,
  eventSubmissionsUpdate,
  newsUpdate,
  newsSubmissionsUpdate,
  getUserCount,
  jobsUpdate,
  facilitiesUpdate,
  servicesUpdate,
  eventsManagedUpdate,
  imageLibraryUpdate,
  devicesLoaded,
})(withRouter(Dashboard));
