import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Button,
  GenericInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
  ImageInput,
  RadioButton,
  DatePicker,
  CheckBox,
} from '../../components';
import { checkLoggedIn, validateAccess } from '../../session';
import { updateDish, addRecentlyCreated } from '../../actions';
import { currencyToFloat, formatCurrency, safeReadParams } from '../../helper';
import { foodActions } from '../../webapi';
import { Text } from '../../components/text';

class AddDish extends Component {
  initialState = {
    dishId: safeReadParams(this.props, 'dishId'),
    titleInput: '',
    descriptionInput: '',
    priceInput: '$',
    imageInput: '',
    showWarnings: false,
    success: false,
    submitting: false,
    isLimitedTime: false,
    isDineInOnly: false,
    selectedDates: [],
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const dishId = safeReadParams(nextProps, 'dishId');
    if (dishId) {
      this.loadDish(dishId);
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (!validateAccess(this.props.auth.site, 'food', this.props.auth, true)) {
        window.history.back();
      } else {
        if (!this.isNew()) {
          this.loadDish(this.state.dishId);
        }
      }
    }, 500);
    this.props.addRecentlyCreated('food');
  }

  isNew() {
    return !this.state.dishId;
  }

  loadDish(dishId) {
    const dish = _.find(this.props.dishes, (p) => {
      return p != null && p.Id === dishId;
    });

    if (dish != null) {
      const newState = {
        dishId,
        titleInput: dish.Title,
        descriptionInput: dish.Description,
        priceInput: _.isEmpty(`${dish.Price[0].Price}`) ? '$' : formatCurrency(`${dish.Price[0].Price}`),
        isLimitedTime: dish.Availability.Limited,
        isDineInOnly: dish.IsDineInOnly,
        allDay: dish.Availability.Meals && _.includes(dish.Availability.Meals, 'AllDay'),
        breakfast: dish.Availability.Meals && _.includes(dish.Availability.Meals, 'Breakfast'),
        lunch: dish.Availability.Meals && _.includes(dish.Availability.Meals, 'Lunch'),
        dinner: dish.Availability.Meals && _.includes(dish.Availability.Meals, 'Dinner'),
      };

      if (newState.isLimitedTime) {
        newState.selectedDates = dish.Availability.Dates.map((d) => {
          return d.Key;
        });
      }

      this.setState(newState);

      this.checkSetImage(dish.Image);
    } else {
      foodActions.getDish(dishId).then((res) => {
        this.props.updateDish(res.data);
        setTimeout(() => {
          this.loadDish(dishId);
        }, 100);
      });
    }
  }

  checkSetImage(url) {
    if (this.refs.imageInput) {
      this.setState({
        imageInput: url,
      });
      this.refs.imageInput.getWrappedInstance().setValue(url);
    } else {
      setTimeout(() => {
        this.checkSetImage(url);
      }, 100);
    }
  }

  onImageUpdated = (url) => {
    this.setState({
      imageInput: url,
    });
  };

  getAvailability() {
    const meals = [];
    if (this.state.allDay) {
      meals.push('AllDay');
    } else {
      if (this.state.breakfast) {
        meals.push('Breakfast');
      }
      if (this.state.lunch) {
        meals.push('Lunch');
      }
      if (this.state.dinner) {
        meals.push('Dinner');
      }
    }
    const result = {
      Limited: this.state.isLimitedTime,
      Meals: meals,
    };
    if (result.Limited) {
      const selectedDates = this.state.selectedDates.map((d) => {
        const date = moment(d, 'DD-MM-YYYY');
        return {
          Key: d,
          Time: date.valueOf(),
          Meals: meals,
        };
      });
      result.Dates = _.sortBy(selectedDates, 'time');
    }
    return result;
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleFormatPrice() {
    this.setState({
      priceInput: _.isEmpty(this.state.priceInput) ? '$' : formatCurrency(this.state.priceInput, undefined, undefined, true),
    });
  }

  handleDateChange(selection) {
    this.setState({
      selectedDates: selection,
    });
  }

  validateLoading() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  validateCompulsoryText() {
    if (_.isEmpty(this.state.titleInput)) {
      return false;
    }
    return true;
  }

  validateImage() {
    if (_.isEmpty(this.state.imageInput)) {
      return false;
    }
    return true;
  }

  validateAvailability() {
    if (!this.state.isLimitedTime) {
      return true;
    }
    if (_.isEmpty(this.state.selectedDates)) {
      return false;
    }
    return true;
  }

  validateForm() {
    if (!this.validateLoading()) {
      return false;
    }
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.validateImage()) {
      return false;
    }
    if (!this.validateAvailability()) {
      return false;
    }
    return true;
  }

  compileJson() {
    const result = {
      site: this.props.auth.site,
      title: this.state.titleInput,
      description: this.state.descriptionInput,
      price: [
        {
          Category: null,
          Price: currencyToFloat(this.state.priceInput),
        },
      ],
      image: this.state.imageInput,
      availability: this.getAvailability(),
      isDineInOnly: this.state.isDineInOnly,
    };

    if (!this.isNew()) {
      result.id = this.state.dishId;
    }

    return result;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });
    const data = this.compileJson();

    foodActions
      .addOrEditDish(data)
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
        });
        this.props.updateDish(res.data.dish);
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  clearForm() {
    this.setState(this.initialState);
  }

  clearSuccess() {
    this.setState({
      success: false,
      submitting: false,
      showWarnings: false,
    });
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Dish has been ${this.isNew() ? 'added' : 'edited'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
          Save
        </Button>
      </div>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleLarge" className="marginBottom-24">
            {this.isNew() ? 'New' : 'Edit'} Dish
          </Text>
          {/* title */}
          <GenericInput
            id="titleInput"
            type="text"
            label="Dish name"
            placeholder="Dish name"
            showError={() => {
              return this.state.showWarnings && _.isEmpty(this.state.titleInput);
            }}
            isValid={() => {
              return !_.isEmpty(this.state.titleInput);
            }}
            value={this.state.titleInput}
            onChange={(e) => this.handleChange(e)}
            alwaysShowLabel
            isRequired
          />
          {/* description */}
          <GenericInput
            id="descriptionInput"
            label="Description"
            type="textarea"
            componentClass="textarea"
            placeholder="Description"
            value={this.state.descriptionInput}
            onChange={(e) => this.handleChange(e)}
            inputStyle={{
              height: 120,
            }}
            alwaysShowLabel
          />

          {/* TODO CATEGORIES */}
          {/* TODO TYPE */}

          {/* price */}
          <GenericInput
            id="priceInput"
            label="Price"
            type="text"
            placeholder="$0.00"
            value={this.state.priceInput}
            onChange={(e) => this.handleChange(e)}
            onBlur={() => {
              this.handleFormatPrice();
            }}
            alwaysShowLabel
          />
        </div>
        <div className="padding-60 paddingBottom-40 bottomDivideBorder">
          {/* image */}
          <ImageInput ref="imageInput" label="IMAGE" limit={1} isRequired refreshCallback={this.onImageUpdated.bind(this)} />
        </div>
      </div>
    );
  }

  renderLimitedTimeSelector() {
    if (!this.state.isLimitedTime) {
      return null;
    }
    return (
      <div className="dishDatePicker">
        <p className="dishDatePicker_title">Select Date Availability</p>
        <DatePicker selectedDates={this.state.selectedDates} selectDate={this.handleDateChange.bind(this)} multiple />
      </div>
    );
  }

  renderSideForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <OverlayPageSection className="pageSectionWrapper--newPopupSide">
        <div className="padding-32 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleSmall" className="marginBottom-16">
            Menu Availability
          </Text>
          <RadioButton
            label="Is this dish only available on certain days?"
            isActive={this.state.isLimitedTime}
            options={[
              { Label: 'Yes', Value: true, onChange: () => this.setState({ isLimitedTime: true }) },
              { Label: 'No', Value: false, onChange: () => this.setState({ isLimitedTime: false }) },
            ]}
          />
          {this.renderLimitedTimeSelector()}
        </div>
        <div className="padding-32 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleSmall" className="marginBottom-16">
            Meal Availability
          </Text>
          <CheckBox
            label="All Day"
            isActive={this.state.allDay}
            onChange={() => {
              this.setState({
                allDay: !this.state.allDay,
              });
            }}
          />
          <CheckBox
            label="Breakfast"
            isActive={this.state.allDay || this.state.breakfast}
            onChange={() => {
              this.setState({ breakfast: !this.state.breakfast });
            }}
            disabled={this.state.allDay}
          />
          <CheckBox
            label="Lunch"
            isActive={this.state.allDay || this.state.lunch}
            onChange={() => {
              this.setState({ lunch: !this.state.lunch });
            }}
            disabled={this.state.allDay}
          />
          <CheckBox
            label="Dinner"
            isActive={this.state.allDay || this.state.dinner}
            onChange={() => {
              this.setState({ dinner: !this.state.dinner });
            }}
            disabled={this.state.allDay}
          />
        </div>
        <div className="padding-32 paddingVertical-40">
          <Text type="formTitleSmall" className="marginBottom-16">
            Options
          </Text>
          <RadioButton
            label="Is this dish available for dine-in only?"
            isActive={this.state.isDineInOnly}
            options={[
              { Label: 'Yes', Value: true, onChange: () => this.setState({ isDineInOnly: true }) },
              { Label: 'No', Value: false, onChange: () => this.setState({ isDineInOnly: false }) },
            ]}
          />
        </div>
      </OverlayPageSection>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents id="DishContainer" noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
          {this.renderSideForm()}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { dishes } = state.food;
  const { auth } = state;
  return {
    dishes,
    auth,
  };
};

export default connect(mapStateToProps, { updateDish, addRecentlyCreated })(AddDish);
