import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import { connect } from 'react-redux';
import _ from 'lodash';
import { isTheBest, getApiError } from '../../session';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { FileInput, GenericInput, Button, DropdownInput } from '../../components';
import {
  renderTitle,
  renderDescription,
  renderOpenLinkStep,
  renderTextStep,
  renderImageStep,
  renderEmbed,
  renderCopyToClipboardText,
  getWhiteLabelAppUser,
} from './helper';
import { automationConfig } from '../../config';

const REVIEW_USER_PREFIX = 'google';
const STEP_SELECT = 0;
const STEP_FCM = 1;
const STEP_FCM_GOOGLE = 1.1;
const STEP_FCM_TOKEN = 1.2;
const STEP_BUNDLE = 2;
const STEP_USER = 3;
const STEP_APP = 4;

class AppSetupAndroid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabelInfo: _.cloneDeep(props.activeWhiteLabel),
      selectedApp: '',
      cloudMessageToken: '',
      loading: false,
      loadingMessage: '',
      resetMessage: '',
      message: {},
    };
    this.steps = [
      { label: 'Select App', name: STEP_SELECT, render: this.renderStep1 },
      { label: 'Set up FCM', name: STEP_FCM, render: this.renderStep2 },
      { label: 'Build App Bundle', name: STEP_BUNDLE, render: this.renderStep3 },
      { label: 'Set up Review User', name: STEP_USER, render: this.renderStep4 },
      { label: 'Set up Android App', name: STEP_APP, render: this.renderStep5 },
    ];
  }

  componentDidMount() {
    // TEST: Preset environment
    // this.setState({ whiteLabelInfo: { ClientCode: 'unittesting' } }, this.onLoadWhiteLabel);
  }

  componentDidUpdate(prevProps) {
    const { activeWhiteLabel } = this.props;
    if (!_.isEqual(prevProps.activeWhiteLabel, activeWhiteLabel)) {
      this.setState({ whiteLabelInfo: activeWhiteLabel }, () => {
        this.setState({ cloudMessageToken: this.getAppProperty('CloudMessageToken') });
      });
    }
  }

  getAppBranchName = (defaultName = '{MISSING_NAME}') => {
    const { selectedApp, whiteLabelInfo } = this.state;
    return selectedApp || whiteLabelInfo.ClientCode || defaultName;
  };

  getAppBundleId = () => this.getAppBranchName().replace('-', '');

  getAppProperty = (key) => {
    const { selectedApp, whiteLabelInfo } = this.state;
    const clientCode = selectedApp || whiteLabelInfo.ClientCode;
    const app = whiteLabelInfo.Apps && whiteLabelInfo.Apps.find((a) => a.ClientCode === clientCode);
    // console.log('getAppProperty', selectedApp, key, app);
    return app ? app[key] : null;
  };

  onMoveStep = (stepIndex) => {
    if (this.props.onMoveStep) this.props.onMoveStep(stepIndex);
  };

  onAddGoogleServicesFile = (file) => {
    return new Promise((resolve, reject) => {
      const { message, whiteLabelInfo } = this.state;

      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        message[STEP_FCM_GOOGLE] = 'Processing...';
        this.setState({ loading: true, message }, async () => {
          try {
            const googleService = e.target.result;
            const { data } = await automationActions.setupGoogleService(whiteLabelInfo.RowId, this.getAppBranchName(''), googleService);
            message[STEP_FCM_GOOGLE] = 'Saved successfully';
            this.props.whiteLabelLoaded(data);
            this.setState({ loading: false, message }, resolve());
          } catch (error) {
            message[STEP_FCM_GOOGLE] = getApiError(error).message;
            this.setState({ loading: false, message }, reject(error));
          }
        });
      };
      reader.onerror = (error) => {
        message[STEP_FCM_GOOGLE] = error.message;
        reject(error);
      };
    });
  };

  onChangeText = (event, fieldName) => {
    const { whiteLabelInfo } = this.state;
    whiteLabelInfo[fieldName] = event.target.value;
    this.setState({ whiteLabelInfo });
  };

  onChangeCloudMessageToken = (event) => {
    this.setState({ cloudMessageToken: event.target.value });
  };

  onSaveCloudMessageToken = () => {
    const { whiteLabelInfo, cloudMessageToken, message } = this.state;
    message[STEP_FCM_TOKEN] = 'Saving...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.setupCloudMessage(whiteLabelInfo.RowId, this.getAppBranchName(''), cloudMessageToken);
        message[STEP_FCM_TOKEN] = 'Saved successfully';
        this.props.whiteLabelLoaded(data);
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_FCM_TOKEN] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onBranchNameChanged = (event) => {
    const { whiteLabelInfo } = this.state;
    const newInfo = _.cloneDeep(whiteLabelInfo);
    newInfo.ClientCode = event.target.value.replace(' ', '-');
    this.setState({ whiteLabelInfo: newInfo });
  };

  onBranchChanged = (key) => {
    this.setState({ selectedApp: key, resetMessage: '' });
  };

  onClearClientCode = () => {
    this.props.whiteLabelReset();
    this.setState({ loading: false, loadingMessage: '', resetMessage: '', selectedApp: '' });
  };

  onLoadWhiteLabel = () => {
    const { whiteLabelInfo } = this.state;
    const { activeWhiteLabel } = this.props;
    const clientCode = whiteLabelInfo.ClientCode;
    if (clientCode === activeWhiteLabel.ClientCode) return;
    if (!clientCode) {
      this.onClearClientCode();
      return;
    }

    let loadingMessage = `Loading ${clientCode} details...`;
    this.setState({ loading: true, loadingMessage, cloudMessageToken: '' }, async () => {
      try {
        const { data } = await automationActions.getWhiteLabel(clientCode);
        const selectedApp = data ? data.ClientCode : '';
        // console.log('onLoadWhiteLabel', data, 'selectedApp', selectedApp);
        loadingMessage = data ? `Details for ${data.ClientName} has been loaded` : `${clientCode} does not exist`;
        this.setState({ loading: false, loadingMessage, selectedApp }, () => {
          if (data) {
            this.props.whiteLabelLoaded(data);
          } else {
            this.props.whiteLabelReset();
          }
        });
      } catch (error) {
        this.setState({ loading: false, loadingMessage: getApiError(error).message });
      }
    });
  };

  onResetUser = () => {
    const { whiteLabelInfo, selectedApp } = this.state;
    this.setState({ loading: true, resetMessage: 'Setting up review user...' }, async () => {
      try {
        const { data } = await automationActions.resetTestUser(
          whiteLabelInfo.RowId,
          selectedApp || whiteLabelInfo.ClientCode,
          REVIEW_USER_PREFIX,
        );
        // console.log('onResetUser', data);
        this.setState({ loading: false, resetMessage: 'Review user has been set up successfully' }, () => {
          if (data) this.props.whiteLabelLoaded(data);
        });
      } catch (error) {
        this.setState({ loading: false, resetMessage: getApiError(error).message });
      }
    });
  };

  renderBranchInput = () => {
    const { whiteLabelInfo, loading, loadingMessage } = this.state;
    const { activeWhiteLabel } = this.props;
    const branchName = whiteLabelInfo ? whiteLabelInfo.ClientCode : '';
    const isBranchValid = !_.isEmpty(branchName);
    const canClear = isBranchValid && activeWhiteLabel && activeWhiteLabel.ClientCode === branchName;

    return (
      <div className="flex flex-row flex-center">
        <GenericInput
          className="marginTop-10"
          id="branch"
          style={{ width: 300 }}
          type="text"
          placeholder="Client code"
          isValid={() => isBranchValid}
          value={branchName}
          onChange={this.onBranchNameChanged}
          onBlur={this.onLoadWhiteLabel}
          isRequired
        />
        <Button
          style={{ width: 90 }}
          className="marginLeft-24"
          inline
          buttonType="primary"
          onClick={canClear ? this.onClearClientCode : null}
          isActive={branchName && !loading}
        >
          {canClear ? 'Clear' : 'Load'}
        </Button>
        <div className="marginLeft-16">{loadingMessage}</div>
      </div>
    );
  };

  renderSelectApp = () => {
    const { whiteLabelInfo, loading } = this.state;
    const branches = whiteLabelInfo.Apps
      ? whiteLabelInfo.Apps.map((b) => {
          return { Title: b.ClientCode, Key: b.ClientCode };
        })
      : [];

    return (
      <DropdownInput
        id={`dropdown_app`}
        style={{ width: 250, marginBottom: 'unset', marginTop: 10 }}
        placeholder="Select Sub App"
        value={this.getAppBranchName('')}
        options={branches}
        onSelect={this.onBranchChanged}
        disabled={loading}
      />
    );
  };

  renderStep1 = () => {
    return (
      <div>
        {renderTitle('Select App')}
        {renderDescription('Select Android app to build.')}
        <ol>
          {renderTextStep(
            <div>
              Enter client code (e.g. aveo)
              {this.renderBranchInput()}
            </div>,
          )}
          {renderTextStep(
            <div>
              Select app
              {this.renderSelectApp()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep2 = () => {
    const { message, loading, cloudMessageToken } = this.state;
    const hasGoogleServices = this.getAppProperty('HasGoogleServices');
    const cloudMessageTokenValid = !_.isEmpty(cloudMessageToken);

    return (
      <div>
        {renderTitle('Set up Firebase Cloud Messaging')}
        {renderDescription('In this step, we will set up the Firebase Cloud Messaging to enable push notification for the Android app.')}
        <ol>
          {renderOpenLinkStep('https://console.firebase.google.com')}
          {renderImageStep(
            <div>
              Sign in to Google
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Email</b> <b>{renderCopyToClipboardText(automationConfig.devAccountEmail)}</b>
                </li>
                <li>
                  Enter <b>Password</b>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderImageStep(
            <div>
              Click <b>Add project</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Add+Project.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Enter the project name <b>{renderCopyToClipboardText(`pluss-${this.getAppBranchName()}`)}</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Project+Name.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Click <b>Select parent resource</b>
              <ul>
                <li>
                  Select <b>joinpluss.com</b> and then click <b>Done</b>
                </li>
                <li>
                  Click <b>Continue</b>
                </li>
              </ul>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Parent+Resource.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Uncheck <b>Enable Google Analytis for this project</b> then click <b>Create project</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Disable+Google+Analytics.png',
            '50%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Once new project is ready, click <b>Continue</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Project+Is+Ready.png',
            '50%',
            '30%',
          )}
          {renderImageStep(
            <div>
              Next to the <b>Project Overview</b>, click the cog symbol and click <b>Project settings</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Open+Project+Settings.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Scroll down to <b>Your apps</b> section and click the <b>Android</b> button
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Add+Android+App.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Under <b>Register app</b>,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Android package name</b> <b>{renderCopyToClipboardText(`com.pluss.${this.getAppBundleId()}`)}</b> (OR the actual
                  package name saved during the configuration steps)
                </li>
                <li>
                  Enter <b>App nickname</b> <b>{renderCopyToClipboardText(this.getAppProperty('ClientName'))}</b>
                </li>
              </ol>
              Then click <b>Register app</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/4+-+Register+App.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Click <b>Download google-services.json</b> button to download the configuration file
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/5+-+Download+Config.png',
            '50%',
            '40%',
          )}
          {renderTextStep(
            <div>
              Drop and upload <b>google-services.json</b> file downloaded
              <div className="flex flex-row flex-center marginTop-10">
                <FileInput
                  ref={(ref) => {
                    this.jsonFileInput = ref;
                  }}
                  style={{ height: 120, width: 240 }}
                  handleFile={this.onAddGoogleServicesFile}
                  accept="application/json"
                  simpleStyle
                  multiple={false}
                />
                <div className="marginLeft-16">{message[STEP_FCM_GOOGLE]}</div>
                {hasGoogleServices ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
              </div>
            </div>,
          )}
          {renderImageStep(
            <div>
              Click <b>Cloud Messaging</b> tab at the top
              <ul>
                <li>
                  To enable <b>Cloud Messaging API (Legacy)</b>, expand the section and click <b>Manage API in Google Cloud Console</b>
                </li>
                <li>
                  Enable <b>Cloud Messaging</b> service
                </li>
              </ul>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/enablecloudmessaging.png',
            '50%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Return to the <b>Cloud Messaging</b> tab in <b>Project settings</b>
              <ul>
                <li>
                  Under <b>Cloud Messaging API (Legacy)</b>, copy the <b>Token</b> next to the <b>Server key</b>
                </li>
                <li>
                  Paste the <b>Token</b> below
                  <GenericInput
                    className="marginTop-10"
                    id="cloudMessageToken"
                    style={{ width: 400 }}
                    type="text"
                    isValid={() => cloudMessageTokenValid}
                    value={cloudMessageToken}
                    onChange={this.onChangeCloudMessageToken}
                    isRequired
                  />
                  <div className="flex flex-row flex-center">
                    <Button
                      inline
                      buttonType="primary"
                      onClick={this.onSaveCloudMessageToken}
                      isActive={cloudMessageTokenValid && !loading}
                    >
                      Save
                    </Button>
                    <div className="marginLeft-16">{message[STEP_FCM_TOKEN]}</div>
                  </div>
                </li>
              </ul>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/getmessagingserverkey.png',
            '50%',
            '50%',
          )}
          {renderTextStep('Close the Incognito window')}
        </ol>
      </div>
    );
  };

  renderStep3 = () => {
    return (
      <div>
        {renderTitle('Build Android App Bundle')}
        {renderDescription(
          'In this step, we will build an App Bundle file to upload. The following steps assume that you have Docker Desktop installed and have the plussdev/default image.',
        )}
        <ol>
          {renderTextStep(
            <div>
              For detailed instructions on how to set up the Docker Desktop for building the app, refer to{' '}
              <a href="https://docs.google.com/document/d/1bxv7Xvy_K4Xk16L0bolRzIsLj6EtXC8O6sHgXt5Bxr4/edit?usp=sharing" target="_blank">
                Building and Uploading Apps
              </a>
            </div>,
          )}
          {renderImageStep(
            <div>
              Ensure you are running a docker container from the <b>plussdev/default</b> image then click <b>Run</b> button without changing
              any settings.
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Run+Docker+Image.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on the <b>Containers/Apps</b> menu on the left and then click the CLI button to open the command prompt
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Open+Command+Prompt.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              Ensure you are in <b>minuss-expo</b> folder. If not, switch to the folder by typing,
              {renderEmbed('cd minuss-expo')}
            </div>,
          )}
          {renderTextStep(
            <div>
              Start building the android app bundle by typing,
              {renderEmbed(`npm run build ${this.getAppBranchName()} android`)}
            </div>,
          )}
          {renderTextStep(
            <div>
              When prompted to generate a new <b>keystore</b>, type <b>Y</b> to allow Expo to generate
            </div>,
          )}
          {renderTextStep(<div>Wait for the build to complete</div>)}
          {renderImageStep(
            <div>
              Download App Bundle file
              <ol className="marginBottom-16">
                <li>
                  Right click on the url to the standalone app built (e.g.{' '}
                  <b>https://expo.io/artifacts/94e075f9-852d-48a6-bd17-e8228a9ca618</b>)
                </li>
                <li>Open the url to start downloading the App Bundle file</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Build+Complete.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              Upload cloud messaging server key written down from previous steps to the Expo server by typing,
              {renderEmbed(`expo push:android:upload --api-key ${this.getAppProperty('CloudMessageToken') || '{MISSING_TOKEN}'}`)}
            </div>,
          )}
          {renderTextStep('Close the terminal window')}
        </ol>
      </div>
    );
  };

  renderResetUser = () => {
    const { loading, resetMessage } = this.state;
    const canReset = !loading;

    return (
      <div className="flex flex-row flex-center marginTop-10">
        <Button style={{ width: 90 }} inline buttonType="primary" onClick={() => canReset && this.onResetUser()} isActive={canReset}>
          Start
        </Button>
        <div className="marginLeft-16">{resetMessage}</div>
      </div>
    );
  };

  renderStep4 = () => {
    return (
      <div>
        {renderTitle('Set up Review User')}
        {renderDescription(
          'In this step, we will ensure an app review user is set up and/or have the password reset for a fresh review process.',
        )}
        <ol>
          {renderTextStep(
            <div>
              Create or reset app review user
              {this.renderResetUser()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep5 = () => {
    const { whiteLabelInfo, selectedApp } = this.state;
    const appUser = getWhiteLabelAppUser(whiteLabelInfo, selectedApp, REVIEW_USER_PREFIX);
    const userName = appUser ? appUser.email : automationConfig.googleTester;
    const password = appUser ? appUser.password : `${whiteLabelInfo.ClientCode}Google`;

    return (
      <div>
        {renderTitle('Set up new app')}
        {renderDescription('In this step, we will set up a new app in Google Play Console')}
        <ol>
          {renderOpenLinkStep('https://play.google.com/apps/publish/?account=8994129238190593164#AppListPlace')}
          {renderImageStep(
            <div>
              Sign in to Google
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Email</b> <b>{renderCopyToClipboardText(automationConfig.devAccountEmail)}</b>
                </li>
                <li>
                  Enter <b>Password</b>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderImageStep(
            <div>
              Click <b>Create app</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/2+-+All+Apps.png',
            '30%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>App details</b>,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>App name</b> <b>{renderCopyToClipboardText(this.getAppProperty('ClientName'))}</b>
                </li>
                <li>
                  Select <b>Default language</b> - <b>English (Australia) - en-AU</b>
                </li>
                <li>
                  Select <b>App or game</b> - <b>App</b>
                </li>
                <li>
                  Select <b>Free or paid</b> - <b>Free</b>
                </li>
              </ol>
              Under <b>Declarations</b>,
              <ol className="marginBottom-16">
                <li>
                  For <b>Developer Program Policies</b>, check <b>Confirm app meets the Developer Program Policies</b>
                </li>
                <li>
                  For <b>US export laws</b>, check <b>Accept US export laws</b>
                </li>
              </ol>
              Then click <b>Create app</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/3+-+Create+App.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Under <b>Grow/Store presence</b> on the left menu, click <b>Main store listing</b>
              <ol className="marginBottom-16">
                <li>
                  Enter <b>App name</b> <b>{renderCopyToClipboardText(this.getAppProperty('ClientName'))}</b>
                </li>
                <li>
                  Enter <b>Short description</b>
                </li>
                <li>
                  Enter <b>Full description</b>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/4+-+App+Details.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Under <b>Graphics</b>,
              <ol className="marginBottom-16">
                <li>
                  Upload <b>App Icon</b>
                </li>
                <li>
                  Upload <b>Feature graphic</b>
                </li>
                <li>
                  Upload <b>Phone screenshots</b>
                </li>
                <li>
                  Upload <b>7-inch tablet screenshots</b>
                </li>
                <li>
                  Upload <b>10-inch tablet screenshots</b>
                </li>
              </ol>
              Then click <b>Save</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/5+-+Graphics.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Under <b>Grow/Store presence</b> on the left menu, click <b>Store settings</b>
              <ol className="marginBottom-16">
                <li>
                  Select <b>Category</b> - <b>Lifestyle</b>
                </li>
                <li>
                  Enter <b>Email address</b> <b>{renderCopyToClipboardText(automationConfig.helpDeskEmail)}</b>
                </li>
                <li>
                  Enter <b>Website</b> <b>{renderCopyToClipboardText(automationConfig.websiteUrl)}</b>
                </li>
              </ol>
              Then click <b>Save</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/6+-+Store+Settings.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Under <b>Policy</b> on the left menu, click <b>App content</b>
              <ol className="marginBottom-16">
                <li>
                  For <b>Privacy policy</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Enter <b>{renderCopyToClipboardText(automationConfig.privacyUrl)}</b>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Ads</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>No, my app does not contain ads</b>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>App access</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>All or some functionality is restricted</b>
                    </li>
                    <li>
                      Click <b>+ Add new instructions</b>
                      <ol>
                        <li>
                          Enter <b>Name</b> (<b>Login Credentials</b>)
                        </li>
                        <li>
                          Enter <b>Username / phone number</b> <b>{renderCopyToClipboardText(userName)}</b>
                        </li>
                        <li>
                          Enter <b>Password</b> <b>{renderCopyToClipboardText(password)}</b>
                        </li>
                        <li>
                          Enter <b>Any other instructions</b> (
                          <b>
                            Use the app as if you might be a member of a community - explore What's On and invite friends to an event you
                            might be attending, check the News for announcements or stories about your neighbours.
                          </b>
                          )
                        </li>
                        <li>
                          Click <b>Apply</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Content ratings</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Click <b>Start questionnaire</b> button
                    </li>
                    <li>
                      For <b>Category</b>,
                      <ol>
                        <li>
                          Enter <b>Email address</b> <b>{renderCopyToClipboardText(automationConfig.devAccountEmail)}</b>
                        </li>
                        <li>
                          Select <b>Category</b> (<b>Utility, Productivity, Communication, or Other</b>)
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                      For <b>Utility, Productivity, Communication, or Other</b>,
                      <ol>
                        <li>
                          Select <b>Violence</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Sexuality</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Language</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Controlled Substance</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Promotion Of Age-Restricted Products Or Activities</b> (<b>No</b>)
                        </li>
                        <li>
                          Answer <b>Yes</b> to{' '}
                          <b>
                            Does the app natively allow users to interact or exchange content with other users through voice communication,
                            text, or sharing images or audio?
                          </b>
                        </li>
                        <li>
                          Answer <b>No</b> to <b>Does the app share the user's current physical location with other users?</b>
                        </li>
                        <li>
                          Answer <b>No</b> to <b>Does the app allow users to purchase digital goods?</b>
                        </li>
                        <li>
                          Answer <b>No</b> to{' '}
                          <b>Does the app contain any swastikas, other Nazi symbols or propaganda deemed unconstitutional in Germany?</b>
                        </li>
                        <li>
                          Answer <b>No</b> to <b>Is the app a web browser or search engine?</b>
                        </li>
                        <li>
                          Click <b>Save</b>
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      Click <b>Submit</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Target audience and content</b>, click <b>Start</b>
                  <ol>
                    <li>
                      For <b>Target age</b>,
                      <ol>
                        <li>
                          Check <b>18 and over</b>
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      For <b>Store presence</b>,
                      <ol>
                        <li>
                          Answer <b>No</b> to <b>Appeal to children</b>
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>News apps</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>News apps</b> (<b>No</b>)
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/7+-+App+Content.png',
            '50%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Under <b>Release</b> on the left menu, click <b>Production</b>
              <ol className="marginBottom-16">
                <li>
                  Click <b>Countries / regions</b> tab
                </li>
                <li>
                  Click <b>Add countires / regions</b>
                </li>
                <li>
                  Select <b>Australia</b> from the list
                </li>
                <li>
                  Click <b>Add countries / regions</b> button
                </li>
              </ol>
              Then click <b>Create new release</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/8+-+Production.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Play App Signing</b>,
              <ol className="marginBottom-16">
                <li>
                  Click <b>Continue</b> to enable app signing by Google
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/9+-+Create+Release.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>App bundles and APKs</b>
              <ol className="marginBottom-16">
                <li>
                  Click <b>Upload</b> and select the App Bundle file downloaded from previous steps
                </li>
                <li>Wait until the upload completes</li>
                <li>You should see the App Bundle file uploaded in the list</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/10+-+App+Bundles.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Release details</b>,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Release notes</b>
                  {renderEmbed('<en-AU>\nInitial release\n</en-')}
                </li>
                <li>
                  Click <b>Save</b>
                </li>
                <li>
                  Click <b>Review release</b> button
                </li>
              </ol>
              Then click <b>Start rollout to Production</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/11+-+Release+Details.png',
            '40%',
            '60%',
          )}
          {renderTextStep('Close the Incognito window once completed')}
        </ol>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    const { stepIndex } = this.props;
    const step = stepIndex && stepIndex < this.steps.length ? stepIndex : 0;
    return (
      <div className="flex-1 automation">
        <StepProgressBar
          startingStep={step}
          progressClass="progressBar"
          primaryBtnClass="primaryBtn"
          secondaryBtnClass="secondaryBtn"
          submitBtnName="Done"
          onPrevious={() => this.onMoveStep(step - 1)}
          onNext={() => this.onMoveStep(step + 1)}
          onSubmit={() => (this.props.onDone ? this.props.onDone() : null)}
          steps={this.steps}
        />
        {this.steps[step].render()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(AppSetupAndroid);
