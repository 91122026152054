import React, { Component } from 'react';
import { FormGroup } from 'react-bootstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { validateAccess } from '../../session';
import { facilitiesLoaded, facilitiesUpdate, addRecentlyCreated } from '../../actions';
import {
  Button,
  DropdownInput,
  RadioButton,
  GenericInput,
  ImageInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
  TextFormatPopup,
  TimePicker,
  OptionsSection,
  SVGIcon,
  FileInput,
  P60Icon,
  Tag,
  AudienceSelector,
} from '../../components';
import { facilityActions, fileActions } from '../../webapi';
import { safeReadParams, getThumb300, get1400, isEmail, getFileName } from '../../helper';
import categories from '../../json/eventCategories.json';
import bookingTypes from '../../json/eventBookingTypes.json';
import { CONST_STRINGS, eventsHaveTags } from '../../config';
import { Text } from '../../components/text';
import { COLOUR_DUSK } from '../../js';

class AddFacility extends Component {
  initialState = {
    facilityId: safeReadParams(this.props, 'facilityId'),
    title: '',
    description: '',
    location: '',
    tag: '',
    eventLink: '',
    area: 'Global',
    showWarnings: false,
    success: false,
    submitting: false,
    facilityAttachments: [],
    isEveryday: true,
    isFeatured: false,
    isTvMode: true,
    phone: '',
    email: '',
    organiser: '',

    allowComments: false,
    buttonText: '',
    buttonLink: '',

    emailRequestBooking: '',

    hasBookings: false,
    bookingData: {
      type: 'External',
    },

    selectedTimeSlot: null,
    shouldNotify: false,

    Days: {
      Mon: { Key: 'Mon', Title: 'Mon', Value: 1 },
      Tue: { Key: 'Tue', Title: 'Tue', Value: 2 },
      Wed: { Key: 'Wed', Title: 'Wed', Value: 3 },
      Thu: { Key: 'Thu', Title: 'Thu', Value: 4 },
      Fri: { Key: 'Fri', Title: 'Fri', Value: 5 },
      Sat: { Key: 'Sat', Title: 'Sat', Value: 6 },
      Sun: { Key: 'Sun', Title: 'Sun', Value: 7 },
    },
    Times: [],
    links: [],

    isAudienceValid: true,
    audienceType: '',
    audienceTypeSelection: [],
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    this.onNewProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.onNewProps(nextProps);
  }

  componentDidMount() {
    if (this.props.facilities.length === 0) this.loadFacilities();
    this.props.addRecentlyCreated('facilities');
  }

  onNewProps(props) {
    const facilityId = safeReadParams(props, 'facilityId');
    const facility = _.find(props.facilities, (facility) => {
      return facility != null && facility.RowId === facilityId;
    });

    if (facility != null) this.parseFacility(facility);
  }

  selectOption = (o) => {
    this.setState({
      selectedOption: o,
    });
  };

  canManageFacility() {
    return validateAccess(this.props.auth.site, 'facilities', this.props.auth);
  }

  inputsDisabled() {
    if (this.state.isSourceGlobal) return true;
    if (this.canManageFacility()) return false;
    return true;
  }

  toggleTextFormat(isOpen) {
    this.setState({
      textFormatOpen: isOpen,
    });
  }

  parseFacility = (facility) => {
    let newState = {
      title: facility.Title,
      description: facility.Description,
      location: facility.Location,
      eventLink: facility.Link,
      eventShortId: facility.Id,
      facilityAttachments: _.isEmpty(facility.Attachments) ? [] : facility.Attachments,
      area: _.isEmpty(facility.Area) ? 'Global' : facility.Area,
      organiser: facility.Organiser ? facility.Organiser : '',
      phone: facility.Phone ? facility.Phone : '',
      email: facility.Email || '',
      isEveryday: Boolean(facility.IsEveryday),
      isFeatured: !Boolean(facility.IsEveryday) && Boolean(facility.IsFeatured),
      isTvMode: Boolean(facility.TVMode),
      allowComments: Boolean(facility.AllowComments),
      audienceType: facility.AudienceType || '',
      audienceTypeSelection: facility.AudienceTypeSelection || [],
    };

    if (!_.isEmpty(facility.Links)) {
      newState.links = facility.Links;
    }

    if (!_.isUndefined(facility.HasBookingOptions) && facility.HasBookingOptions) {
      newState.hasBookings = true;
      newState.bookingData = facility.BookingData;

      // if external booking type, set booking button/link fields
      if (facility.BookingData.type === 'External' && !_.isUndefined(facility.BookingData.ButtonLink)) {
        newState.buttonLink = facility.BookingData.ButtonLink;
      }
      if (facility.BookingData.type === 'EmailRequest') {
        newState.emailRequestBooking = facility.BookingData.RequestEmail;
      }

      newState.requestButtonText = facility.BookingData.ButtonText || '';
      newState.confirmationTitle = facility.BookingData.ConfirmationTitle || '';
      newState.confirmationText = facility.BookingData.ConfirmationText || '';
    }

    if (!_.isEmpty(facility.Category)) {
      const tags = this.selectCategory(facility.Category);
      if (tags && !_.isEmpty(facility.Tags)) {
        _.values(tags).forEach((tag) => {
          tag.Active = facility.Tags.indexOf(tag.Key) > -1;
        });
      }
    }

    if (!_.isUndefined(facility.Times) && !_.isEmpty(facility.Times)) {
      newState.Times = facility.Times;
    }

    newState.isSourceGlobal = facility.IsGlobal;
    newState.isSourceTemplate = facility.IsTemplate;
    newState.globalAllowDescriptionEdit = facility.GlobalAllowDescriptionEdit;
    newState.globalAllowTimeEdit = facility.GlobalAllowTimeEdit;

    newState = { ...newState };

    this.setState(newState);

    this.checkSetImage(facility.Image);
  };

  checkSetImage(url) {
    if (this.refs.imageInput) {
      this.setState({
        image: url,
      });
      this.refs.imageInput.getWrappedInstance().setValue(url);
    } else {
      setTimeout(() => {
        this.checkSetImage(url);
      }, 100);
    }
  }

  loadFacilities = () => {
    this.setState({ loading: true }, async () => {
      try {
        const res = await facilityActions.getFacilities(this.props.auth.site);
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.facilitiesLoaded(res.data);
        }
      } catch (error) {
        alert('Something went wrong with the request. Please try again.');
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  getSelectedCategory() {
    if (categories[this.state.tag]) {
      return categories[this.state.tag];
    }
    return {
      Title: 'Category',
    };
  }

  getSelectedBookingType() {
    if (bookingTypes[this.state.bookingData.type]) {
      return bookingTypes[this.state.bookingData.type];
    }
    return {
      Title: 'Select',
    };
  }

  isEmpty(text) {
    return text === '';
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handlePDFFileChange(event, attachment) {
    const file = event.target.files[0];
    if (!file || attachment.Uploading) {
      return;
    }
    attachment.Uploading = true;
    this.setState({
      facilityAttachments: this.state.facilityAttachments,
    });
    fileActions
      .uploadMediaAsync(file, file.name)
      .then((fileRes) => {
        const newAttachments = [...this.state.facilityAttachments];
        const attachmentIndex = newAttachments.indexOf(attachment);
        if (attachmentIndex !== -1) {
          newAttachments[attachmentIndex].Uploading = false;
          newAttachments[attachmentIndex].Source = fileRes;
          const fileSplit = fileRes.split('/');
          newAttachments[attachmentIndex].Title = _.last(fileSplit).split('.')[0];
          this.setState({
            facilityAttachments: newAttachments,
          });
        }
      })
      .catch((uploadErrorRes) => {
        attachment.Uploading = false;
        this.setState({
          facilityAttachments: this.state.facilityAttachments,
        });
      });
  }

  handleAttachmentChange(index, column, event) {
    var stateChange = {
      facilityAttachments: this.state.facilityAttachments,
    };
    stateChange.facilityAttachments[index][column] = event.target.value;
    this.setState(stateChange);
  }

  onImageUpdated = (url) => {
    this.setState({
      image: url,
    });
  };

  addLink = () => {
    const newLinks = [...this.state.links];
    newLinks.push({
      Text: '',
      Url: '',
    });
    this.setState({ links: newLinks });
  };

  removeLink = (index) => {
    const newLinks = [...this.state.links];
    newLinks.splice(index, 1);
    this.setState({ links: newLinks });
  };

  handleLinkChange = (index, key, value) => {
    const newLinks = [...this.state.links];
    const newLink = { ...newLinks[index] };
    newLink[key] = value;
    newLinks[index] = newLink;
    this.setState({
      links: newLinks,
    });
  };

  validateLoading() {
    return !this.state.submitting;
  }

  validateImage() {
    return this.state.image && this.state.image.match(/\.(jpeg|jpg|gif|png|ashx)/) != null;
  }

  isValidLink() {
    const regexp = /(http:\/\/|https:\/\/)/;
    if (regexp.test(this.state.buttonLink)) {
      return true;
    }
    return false;
  }

  validateEmailRequestField() {
    if (
      this.state.hasBookings &&
      this.state.bookingData.type === 'EmailRequest' &&
      (this.isEmpty(this.state.emailRequestBooking) || !isEmail(this.state.emailRequestBooking))
    ) {
      return false;
    }
    return true;
  }

  validateButtonLink() {
    if (
      this.state.hasBookings &&
      this.state.bookingData.type === 'External' &&
      (this.isEmpty(this.state.requestButtonText) || this.isEmpty(this.state.buttonLink))
    ) {
      return false;
    }
    return true;
  }

  validateCompulsoryText() {
    if (this.isEmpty(this.state.title)) {
      return false;
    }
    return true;
  }

  validateCategory() {
    if (categories[this.state.tag]) {
      return true;
    }
    return false;
  }

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.validateImage()) {
      return false;
    }
    if (!this.validateLoading()) {
      return false;
    }
    if (!this.validateButtonLink()) {
      return false;
    }
    if (!this.validateEmailRequestField()) {
      return false;
    }
    if (!this.state.isAudienceValid) {
      return false;
    }
    return true;
  }

  setTags(input) {
    input.Tags = _.filter(_.values(this.state.selectableTags), (tag) => {
      return tag.Active;
    }).map((tag) => {
      return tag.Key;
    });
  }

  setAttachments(input) {
    input.Attachments = [];
    this.state.facilityAttachments.forEach((attachment) => {
      if (!attachment.Removed && !_.isEmpty(attachment.Source)) {
        input.Attachments.push({
          Id: attachment.Id,
          Title: attachment.Title,
          Source: attachment.Source,
        });
      }
    });
  }

  setDays(obj) {
    let source = this.state.Times;
    source = _.filter(source, (ev) => {
      return !ev.Removed;
    });
    source = _.filter(source, (ev) => {
      return ev.Day != null;
    });
    obj.Times = source;
  }

  compileJson = async () => {
    const result = {
      Title: this.state.title,
      Image: this.refs.imageInput.getWrappedInstance().getValue(),
      Description: this.state.description,
      Location: this.state.location,
      Category: eventsHaveTags ? this.state.tag : '',
      Date: null,
      Time: '',
      AllDay: this.state.eventAllDay,
      Link: this.state.eventLink,
      Thumbnail: this.refs.imageInput.getWrappedInstance().getValue(),
      Area: this.state.area,
      Site: this.props.auth.site,
      IsEveryday: this.state.isEveryday,
      IsFeatured: this.state.isFeatured,
      Phone: this.state.phone,
      Email: this.state.email,
      Organiser: this.state.organiser,
      HasBookingOptions: this.state.hasBookings,
      BookingData: null,
      TVMode: this.state.isTvMode,
      AllowComments: this.state.allowComments,
      Links: [...this.state.links],
      AudienceType: this.state.audienceType,
      AudienceTypeSelection: this.state.audienceTypeSelection,
    };

    if (this.state.facilityId != null) {
      result.RowId = this.state.facilityId;
    }
    if (this.state.eventShortId != null) {
      result.Id = this.state.eventShortId;
    }

    if (result.HasBookingOptions) {
      result.BookingData = { type: this.state.bookingData.type };
      if (result.BookingData.type === 'External') {
        result.BookingData.ButtonLink = this.state.buttonLink;

        if (!this.isValidLink(this.state.buttonLink)) {
          result.BookingData.ButtonLink = 'http://' + this.state.buttonLink;
        }
      }
      if (result.BookingData.type === 'EmailRequest') {
        result.BookingData.RequestEmail = this.state.emailRequestBooking;
        if (!_.isEmpty(this.state.confirmationTitle) || !_.isEmpty(this.state.confirmationText)) {
          result.BookingData.ConfirmationTitle = this.state.confirmationTitle;
          result.BookingData.ConfirmationText = this.state.confirmationText;
        }
      }
      if (result.BookingData.type !== 'NoBooking') {
        if (!_.isEmpty(this.state.requestButtonText)) {
          result.BookingData.ButtonText = this.state.requestButtonText;
        }
      }
    }

    this.setAttachments(result);
    if (eventsHaveTags) {
      this.setTags(result);
    }
    this.setDays(result);

    result.Thumbnail = getThumb300(result.Image);
    result.Image = get1400(result.Image);
    if (result.Category && result.Category.indexOf(',') !== -1) {
      result.Category = result.Category.split(',')[0];
    }
    if (this.state.isTemplate) {
      result.IsTemplate = 'X';
    }

    result.Notification = _.includes(this.props.auth.hidden, 'facilityNotifications') ? false : this.state.shouldNotify;

    return result;
  };

  handleSubmit = async () => {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });
    const data = await this.compileJson();
    facilityActions
      .addOrEditFacility(data)
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
        });
        this.props.facilitiesUpdate(this.props.auth.site);
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      });
  };

  clearForm() {
    this.setState(this.initialState);
    setTimeout(() => {
      this.addRepetition();
    }, 50);
  }

  clearSuccess() {
    this.setState({
      success: false,
      submitting: false,
      showWarnings: false,
    });
  }

  selectBookingType(tag) {
    if (!bookingTypes[tag]) {
      return null;
    }
    const newBooking = this.state.bookingData;
    newBooking.type = tag;
    this.setState({
      bookingData: newBooking,
    });
  }

  selectCategory(tag) {
    if (!categories[tag]) {
      return null;
    }
    const tags = categories[tag].Sub;
    this.setState({
      tag: tag,
      selectableTags: tags,
    });
    return tags;
  }

  toggleTag(tag) {
    if (this.inputsDisabled()) {
      return;
    }
    const newTags = { ...this.state.selectableTags };
    newTags[tag].Active = !newTags[tag].Active;
    this.setState({
      selectableTags: newTags,
    });
  }

  addAttachment = (url) => {
    if (_.isEmpty(url)) {
      return;
    }
    const newReps = [...this.state.facilityAttachments];
    const id = _.isEmpty(this.state.facilityAttachments) ? 0 : _.maxBy(this.state.facilityAttachments, 'Id').Id + 1;
    newReps.push({
      Id: id,
      Title: getFileName(url, true),
      Source: url,
      Uploading: false,
      Type: 'pdf',
    });
    this.setState({
      facilityAttachments: newReps,
    });

    this.pdFileInput && this.pdFileInput.getWrappedInstance().setValue('');
  };

  removeAttachment(index) {
    const newReps = [...this.state.facilityAttachments];
    newReps[index].Removed = true;
    this.setState({
      facilityAttachments: newReps,
    });
  }

  addOpeningTime = () => {
    const newReps = [...this.state.Times];
    const id = _.isEmpty(this.state.Times) ? 0 : _.maxBy(this.state.Times, 'Id').Id + 1;

    newReps.push({
      Id: id,
      Day: null,
      StartTime: '9:00am',
      EndTime: '5:00pm',
    });
    this.setState({
      Times: newReps,
    });
  };

  removeOpeningTime(rep, e) {
    e.stopPropagation();
    rep.Removed = true;
    const newReps = [...this.state.Times];
    const newState = { Times: newReps };

    this.setState(newState);
  }

  getSelectedRepDay(repetition) {
    if (this.state.Days[repetition]) {
      return this.state.Days[repetition];
    }
    return {
      Title: 'Select day',
    };
  }

  validateRepDay(rep) {
    if (this.state.Days[rep.Day] != null) {
      return true;
    }
    return false;
  }

  selectRepDay(id, tag) {
    var stateChange = {
      Times: this.state.Times,
    };
    const index = _.findIndex(stateChange.Times, (rep) => {
      return rep.Id === id;
    });
    stateChange.Times[index].Day = tag;
    stateChange.Times[index].Value = this.state.Days[tag].Value;
    this.setState(stateChange);
  }

  handleRepetitionTimeChange(id, time) {
    var stateChange = {
      Times: this.state.Times,
    };
    const index = _.findIndex(stateChange.Times, (rep) => {
      return rep.Id === id;
    });
    stateChange.Times[index].StartTime = time;
    this.setState(stateChange);
  }

  handleRepetitionEndTimeChange(id, time) {
    var stateChange = {
      Times: this.state.Times,
    };
    const index = _.findIndex(stateChange.Times, (rep) => {
      return rep.Id === id;
    });
    stateChange.Times[index].EndTime = time;
    this.setState(stateChange);
  }

  validateRepetitionTime(repetition) {
    if (!_.isUndefined(repetition.StartTime)) {
      return repetition.StartTime.trim() !== '';
    }
    return false;
  }

  validateRepetitionEndTime(repetition) {
    if (!_.isUndefined(repetition.EndTime)) {
      return repetition.EndTime.trim() !== '';
    }
    return false;
  }

  onChangeAudience = (audienceType, audienceTypeSelection, isAudienceValid) => {
    this.setState({ audienceType, audienceTypeSelection, isAudienceValid });
  };

  renderSubCategories() {
    if (_.isEmpty(this.state.tag) || !categories[this.state.tag] || _.isEmpty(this.state.selectableTags)) {
      return null;
    }
    return (
      <div className="flex-1">
        <div className="fieldLabel marginBottom-8">Tag/s</div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {_.values(this.state.selectableTags).map((tag) => {
            return (
              <Tag
                key={tag.Key}
                className="marginTop-8 marginRight-8"
                text={tag.Title}
                onClick={this.toggleTag.bind(this, tag.Key)}
                leftIcon={tag.Active ? 'check' : null}
              />
            );
          })}
        </div>
      </div>
    );
  }

  renderAddAttachment() {
    if (this.inputsDisabled()) {
      return null;
    }
    return (
      <FileInput
        ref={(ref) => {
          this.pdFileInput = ref;
        }}
        style={{ height: 120, width: 240 }}
        refreshCallback={this.addAttachment}
        accept="application/pdf"
        simpleStyle
      />
    );
  }

  renderAttachments() {
    return (
      <div className="optionsContent_bottom">
        <Text type="formTitleSmall" className="marginBottom-16">
          Links
        </Text>
        <div>
          {this.state.links.map((link, index) => {
            return (
              <div key={index} className="pdfAttachmentInput">
                <FontAwesome className="pdfAttachmentInput_icon paddingTop-8 marginRight-16" name="link" />
                <GenericInput
                  id={`newsLink${index}`}
                  label="Title"
                  type="text"
                  placeholder="Enter Title"
                  value={link.Text}
                  onChange={(e) => this.handleLinkChange(index, 'Text', e.target.value)}
                  disabled={this.inputsDisabled()}
                  alwaysShowLabel
                  style={{ margin: 0, marginRight: 16, flex: 1 }}
                />
                <GenericInput
                  id={`newsLink${index}`}
                  label="URL"
                  type="text"
                  placeholder="https://"
                  value={link.Url}
                  onChange={(e) => this.handleLinkChange(index, 'Url', e.target.value)}
                  disabled={this.inputsDisabled()}
                  alwaysShowLabel
                  style={{ margin: 0, flex: 1 }}
                />
                <P60Icon
                  className="removeoption pdfAttachmentInput_remove"
                  icon="remove-circle"
                  onClick={() => {
                    this.removeLink(index);
                  }}
                />
              </div>
            );
          })}
          <div className="iconTextButton marginTop-16" onClick={this.addLink}>
            <FontAwesome className="iconTextButton_icon" name="plus" />
            <p className="iconTextButton_text">Add Link</p>
          </div>
        </div>
        <Text type="formTitleSmall" className="marginTop-32 marginBottom-16">
          PDF Attachments
        </Text>
        <FormGroup controlId="eventAttachment">
          {this.state.facilityAttachments.map((attachment, index) => {
            if (attachment.Removed) {
              return null;
            }
            return (
              <div key={index} className="pdfAttachmentInput">
                <img
                  src="https://s3-ap-southeast-2.amazonaws.com/pluss60-dev-media/pluss/document.svg"
                  className="pdfAttachmentInput_icon"
                  alt="file"
                />
                <Text type="formTitleSmall" className="pdfAttachmentInput_title">
                  {getFileName(attachment.Source)}
                </Text>
                <GenericInput
                  id={`eventAttachmentTitle${index}`}
                  label="Title"
                  type="text"
                  placeholder="Title"
                  value={attachment.Title}
                  onChange={(e) => this.handleAttachmentChange(index, 'Title', e)}
                  disabled={this.inputsDisabled()}
                  alwaysShowLabel
                  style={{ margin: 0, flex: 1 }}
                />
                <P60Icon
                  className="removeoption pdfAttachmentInput_remove"
                  icon="remove-circle"
                  onClick={() => {
                    this.removeAttachment(index);
                  }}
                />
              </div>
            );
          })}
          {this.renderAddAttachment()}
        </FormGroup>
      </div>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`${CONST_STRINGS.FACILITY} has been ${this.state.facilityId != null ? 'edited' : 'added'}`}
        buttons={[
          {
            type: 'secondary',
            onClick: this.clearForm.bind(this),
            text: `Create new ${CONST_STRINGS.FACILITY.toLowerCase()}`,
          },
          {
            type: 'outlined',
            onClick: this.clearSuccess.bind(this),
            text: `Add another ${CONST_STRINGS.FACILITY.toLowerCase()} with same info`,
          },
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: `Go to home`,
          },
        ]}
      />
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {this.canManageFacility() ? 'Cancel' : 'Back'}
        </Button>
        {this.canManageFacility() && (
          <Button inline buttonType="primary" onClick={this.handleSubmit} isActive={this.validateForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderGlobalInfo() {
    if (!this.state.isSourceGlobal) {
      return null;
    }
    let editText = null;
    if (this.state.globalAllowTimeEdit && this.state.globalAllowDescriptionEdit) {
      editText = 'description and time slots';
    } else if (this.state.globalAllowTimeEdit) {
      editText = 'time slots';
    } else if (this.state.globalAllowDescriptionEdit) {
      editText = 'description';
    }

    const text = editText ? `This event only allows for the ${editText} to be edited` : 'This event does not allow you to edit';
    return <p className="highlightedHelp">{text}</p>;
  }

  renderAudience() {
    const { auth } = this.props;
    const { audienceType, audienceTypeSelection } = this.state;

    return (
      <div className="optionsContent_bottom">
        <AudienceSelector
          onChange={this.onChangeAudience}
          auth={auth}
          custom
          audienceType={audienceType}
          audienceTypeSelection={audienceTypeSelection}
          disallowSingleUsers
          disallowUserType
          maxWidth={700}
        />
      </div>
    );
  }

  renderPublishOptions() {
    return <div className="optionsContent_bottom optionsContent_bottom-noTopPadding">{this.renderNotify()}</div>;
  }

  renderOpeningHours() {
    return (
      <div className="optionsContent_bottom">
        {this.renderTimes()}
        <Button buttonType="outlined" isActive onClick={this.addOpeningTime}>
          Add day
        </Button>
      </div>
    );
  }

  renderAdditionalInfo() {
    const inputsDisabled = this.inputsDisabled();
    return (
      <div className="optionsContent_bottom">
        <GenericInput
          label={'Location'}
          id="location"
          placeholder="Location"
          value={this.state.location}
          onChange={(e) => this.handleChange(e)}
          disabled={inputsDisabled}
          alwaysShowLabel
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '100%', maxWidth: 300, marginRight: 16 }}>
            <DropdownInput
              id="tag"
              label="Category"
              placeholder="Category"
              ignoreValue="Category"
              value={this.getSelectedCategory().Title}
              options={categories}
              onSelect={this.selectCategory.bind(this)}
              disabled={inputsDisabled}
            />
          </div>
          {/* secondary tags */}
          {this.renderSubCategories()}
        </div>
      </div>
    );
  }

  renderContactOptions() {
    const inputsDisabled = this.inputsDisabled();
    return (
      <div className="optionsContent_bottom">
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
        {/* organiser */}
        <GenericInput
          id="organiser"
          type="text"
          label="Contact"
          placeholder="Reception"
          value={this.state.organiser}
          onChange={(e) => this.handleChange(e)}
          style={{ width: '100%' }}
          disabled={inputsDisabled}
          alwaysShowLabel
        />
        {/* phone */}
        <GenericInput
          id="phone"
          type="text"
          label="Phone number"
          placeholder="0400 000 000"
          value={this.state.phone}
          onChange={(e) => this.handleChange(e)}
          style={{ width: '100%' }}
          disabled={inputsDisabled}
          alwaysShowLabel
        />
        {/* email */}
        <GenericInput
          id="email"
          type="text"
          label="Email"
          placeholder="example@domain.com"
          value={this.state.email}
          onChange={(e) => this.handleChange(e)}
          style={{ width: '100%' }}
          disabled={inputsDisabled}
          alwaysShowLabel
        />
        {/* </div> */}
      </div>
    );
  }

  renderBookingOptions() {
    const inputsDisabled = this.inputsDisabled();
    return (
      <div className="optionsContent_bottom">
        <RadioButton
          label={`Does this ${CONST_STRINGS.FACILITY.toLowerCase()} have booking options?`}
          isActive={this.state.hasBookings}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ hasBookings: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ hasBookings: false }) },
          ]}
          disabled={inputsDisabled}
        />
        {this.state.hasBookings && (
          <>
            <div style={{ marginTop: 8 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '100%', maxWidth: '50%', marginRight: 24 }}>
                  <DropdownInput
                    id="bookingType"
                    label="Booking Type"
                    placeholder="Booking Type"
                    value={this.getSelectedBookingType().Title}
                    options={bookingTypes}
                    onSelect={this.selectBookingType.bind(this)}
                    disabled={inputsDisabled}
                  />
                </div>
                {this.state.bookingData.type === 'EmailRequest' && (
                  <GenericInput
                    id="emailRequestBooking"
                    type="text"
                    label="Email address"
                    placeholder="example@domain.com"
                    help="Enter an email address that receives these requests"
                    showError={() => {
                      return this.state.showWarnings && this.isEmpty(this.state.emailRequestBooking);
                    }}
                    value={this.state.emailRequestBooking}
                    onChange={(e) => this.handleChange(e)}
                    style={{ width: '100%', marginLeft: 24 }}
                    disabled={inputsDisabled}
                    alwaysShowLabel
                  />
                )}
              </div>
              {this.state.bookingData.type === 'External' && (
                <GenericInput
                  id="buttonLink"
                  type="text"
                  label="Button Link"
                  placeholder="External link (https://...)"
                  showError={() => {
                    return this.state.showWarnings && this.isEmpty(this.state.buttonLink);
                  }}
                  value={this.state.buttonLink}
                  onChange={(e) => this.handleChange(e)}
                  disabled={inputsDisabled}
                  alwaysShowLabel
                />
              )}
            </div>
            {this.state.bookingData.type !== 'NoRequest' && (
              <GenericInput
                id="requestButtonText"
                type="text"
                label="Button text"
                placeholder="Request booking"
                value={this.state.requestButtonText}
                onChange={(e) => this.handleChange(e)}
                alwaysShowLabel
              />
            )}
            {this.state.bookingData.type === 'EmailRequest' && (
              <div className="marginTop-40">
                <Text type="formTitleSmall">Customise Confirmation Popup</Text>
                <Text type="help" className="marginBottom-10">
                  Use the fields to customise the message displayed in the popup when users submit their request.
                </Text>
                <GenericInput
                  id="confirmationTitle"
                  type="text"
                  label="Confirmation title"
                  placeholder="Request received"
                  value={this.state.confirmationTitle}
                  onChange={(e) => this.handleChange(e)}
                  alwaysShowLabel
                />
                <GenericInput
                  id="confirmationText"
                  type="textarea"
                  label="Confirmation text"
                  placeholder="Thanks for submitting your request. A member from our team will be in touch with you."
                  value={this.state.confirmationText}
                  onChange={(e) => this.handleChange(e)}
                  alwaysShowLabel
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  renderSelectedOption(selectedOption) {
    switch (selectedOption) {
      case 'details':
        return this.renderAdditionalInfo();
      case 'bookingOption':
        return this.renderBookingOptions();
      case 'audience':
        return this.renderAudience();
      case 'publishOptions':
        return this.renderPublishOptions();
      case 'openingHours':
        return this.renderOpeningHours();
      case 'contacts':
        return this.renderContactOptions();
      case 'attachments':
        return this.renderAttachments();
      default:
        return null;
    }
  }

  renderOptionsSection() {
    let options = [
      {
        key: 'details',
        icon: 'info',
        text: 'Details',
      },
      {
        key: 'audience',
        icon: 'audience',
        text: 'Audience',
      },
      {
        key: 'publishOptions',
        icon: 'bell',
        text: 'Publish Options',
      },
      {
        key: 'bookingOption',
        icon: 'pencil-o',
        text: 'Booking Option',
      },
      {
        key: 'openingHours',
        icon: 'clock',
        text: 'Opening Hours',
      },
      {
        key: 'contacts',
        icon: 'user-card',
        text: 'Contact Details',
      },
      {
        key: 'attachments',
        icon: 'attachments',
        text: 'Attachments',
      },
    ];
    if (this.state.facilityId || _.includes(this.props.auth.hidden, 'facilityNotifications')) {
      options = _.filter(options, (o) => {
        return o.key !== 'publishOptions';
      });
    }
    if (_.isEmpty(options)) {
      return null;
    }

    const selectedOption = this.state.selectedOption || options[0].key;

    return (
      <OptionsSection options={options} selected={selectedOption} selectOption={this.selectOption}>
        {this.renderSelectedOption(selectedOption)}
      </OptionsSection>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }

    const inputsDisabled = this.inputsDisabled();
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleLarge" className="marginBottom-24">
            {this.state.facilityId == null ? 'New' : 'Edit'} {CONST_STRINGS.FACILITY}
          </Text>
          {this.renderGlobalInfo()}
          <div className="flex flex-reverse">
            {/* image */}
            <ImageInput
              ref="imageInput"
              label="IMAGE"
              limit={1}
              isRequired
              refreshCallback={this.onImageUpdated}
              showError={() => {
                return this.state.showWarnings && !this.validateImage();
              }}
              disabled={inputsDisabled}
              containerStyle={{ marginLeft: 40 }}
            />
            <div className="flex-1">
              {/* title */}
              <GenericInput
                id="title"
                type="text"
                label="Title"
                placeholder={`Enter ${CONST_STRINGS.FACILITY.toLowerCase()} name`}
                isValid={() => {
                  return !_.isEmpty(this.state.title);
                }}
                showError={() => {
                  return this.state.showWarnings && this.isEmpty(this.state.title);
                }}
                value={this.state.title}
                onChange={(e) => this.handleChange(e)}
                isRequired
                disabled={inputsDisabled}
                alwaysShowLabel
              />
              {/* description */}
              <GenericInput
                id="description"
                label="Description"
                type="textarea"
                componentClass="textarea"
                placeholder="Enter a description"
                value={this.state.description}
                onChange={(e) => this.handleChange(e)}
                inputStyle={{
                  height: 120,
                }}
                disabled={inputsDisabled && !this.state.globalAllowDescriptionEdit}
                help={
                  <a
                    onClick={() => {
                      this.toggleTextFormat(true);
                    }}
                    className="pointer"
                  >
                    Text formatting
                  </a>
                }
                alwaysShowLabel
              />
            </div>
          </div>
        </div>

        {this.renderOptionsSection()}
      </div>
    );
  }

  renderTimes() {
    let source = this.state.Times;
    source = _.filter(source, (ev) => {
      return !ev.Removed;
    });
    return _.map(source, (repetition, index) => {
      if (repetition.Removed) {
        return null;
      }
      return (
        <div key={repetition.Id} className="flex flex-row flex-center marginBottom-16">
          <DropdownInput
            id={`repitionDay${repetition.Id}`}
            placeholder="Category"
            ignoreValue="Category"
            value={this.getSelectedRepDay(repetition.Day).Title}
            isRequired
            isValid={() => {
              return this.validateRepDay(repetition);
            }}
            showError={() => {
              return this.state.showWarnings && !this.validateRepDay(repetition);
            }}
            options={this.state.Days}
            onSelect={this.selectRepDay.bind(this, repetition.Id)}
            disabled={this.inputsDisabled()}
            style={{ marginBottom: 0, marginRight: 24 }}
          />
          <GenericInput
            id={`repetitionStartTime${repetition.Id}`}
            type="time"
            value={repetition.StartTime}
            isValid={() => {
              return this.validateRepetitionTime(repetition);
            }}
            showError={() => {
              return this.state.showWarnings && !this.validateRepetitionTime(repetition);
            }}
            style={{ marginBottom: 0 }}
            disabled={this.inputsDisabled()}
            inputComponent={
              <TimePicker selectedTime={repetition.StartTime} selectTime={this.handleRepetitionTimeChange.bind(this, repetition.Id)} />
            }
          />
          <div style={{ paddingLeft: 8, paddingRight: 8 }}>-</div>
          <GenericInput
            id={`repetitionEndTime${repetition.Id}`}
            type="time"
            value={repetition.EndTime}
            isValid={() => {
              return this.validateRepetitionEndTime(repetition);
            }}
            showError={() => {
              return this.state.showWarnings && !this.validateRepetitionEndTime(repetition);
            }}
            style={{ marginBottom: 0 }}
            disabled={this.inputsDisabled()}
            inputComponent={
              <TimePicker selectedTime={repetition.EndTime} selectTime={this.handleRepetitionEndTimeChange.bind(this, repetition.Id)} />
            }
          />

          <div className="removeIcon marginLeft-16" onClick={this.removeOpeningTime.bind(this, repetition)}>
            <SVGIcon icon="close" colour={COLOUR_DUSK} />
          </div>
        </div>
      );
    });
  }

  renderNotify() {
    return (
      <div className="marginTop-16">
        <Text type="formTitleSmall" className="marginBottom-16">
          Notification
        </Text>
        <div className="marginTop-16">
          <RadioButton
            label={`Do you want to send a notification with this ${CONST_STRINGS.FACILITY.toLowerCase()}?`}
            isActive={this.state.shouldNotify}
            options={[
              { Label: 'Yes', Value: true, onChange: () => this.setState({ shouldNotify: true }) },
              { Label: 'No', Value: false, onChange: () => this.setState({ shouldNotify: false }) },
            ]}
            help="This will send an alert to all users who have enabled push notifications."
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <TextFormatPopup onClose={this.toggleTextFormat.bind(this, false)} isOpen={this.state.textFormatOpen} />
        <OverlayPageContents id="FacilityContainer" noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { facilities } = state.facilities;
  return {
    facilities,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { facilitiesLoaded, facilitiesUpdate, addRecentlyCreated })(AddFacility);
