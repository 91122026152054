import { getUrl } from './helper';
import { authedFunction } from '../session';

export const eventActions = {
  addOrEditEvent: (data) => {
    let url = getUrl('events', 'add');
    if (data.RowId != null) {
      url = getUrl('events', 'edit');
    }
    const request = {
      method: 'POST',
      url,
      data: {
        event: data,
      },
    };
    return authedFunction(request);
  },
  removeEvent: (site, id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('events', 'remove'),
      data: {
        id,
        site,
      },
    });
  },
  getEvent: (site, id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('events', `get/${id}`, { site }),
    });
  },
  getEvents: (site, time, includePast) => {
    let query = { site };
    if (time) {
      query.time = time;
    }
    if (includePast) {
      query.includePast = 'true';
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('events', 'get', query),
    });
  },
  registerForEvent: async (site, userID, eventId, repId, count, notes) => {
    const request = {
      method: 'POST',
      url: getUrl('eventreps', 'register'),
      data: {
        site,
        userID,
        eventId,
        repId,
        fromAdmin: true,
        count,
        notes,
      },
    };
    return authedFunction(request);
  },
  removeEventAttendee: async (site, userID, eventId, repId) => {
    const request = {
      method: 'POST',
      url: getUrl('eventreps', 'remove'),
      data: {
        site,
        userID,
        eventId,
        repId,
        fromAdmin: true,
      },
    };
    return authedFunction(request);
  },
  confirmEventAttendee: async (site, userID, eventId, repId, confirmed) => {
    const request = {
      method: 'POST',
      url: getUrl('eventreps', 'confirm'),
      data: {
        site,
        userID,
        eventId,
        repId,
        confirmed,
      },
    };
    return authedFunction(request);
  },
  getEventReps: (site, eventId) => {
    let query = { site, eventId };
    return authedFunction({
      method: 'GET',
      url: getUrl('eventreps', 'get', query),
    });
  },
  getUpcomingEventReps: (site, minTime, limit) => {
    let query = { site, minTime, limit };
    return authedFunction({
      method: 'GET',
      url: getUrl('eventreps', 'upcoming', query),
    });
  },
  getEventSubmissions: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('events', 'submissions/get', { site }),
    });
  },
  getEventSubmission: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('events', `submissions/get/${id}`),
    });
  },
  handleEventSubmission: (site, id, action) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('events', 'submissions/handle'),
      data: {
        site,
        id,
        action,
      },
    });
  },
  editSubmission: (site, event) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('events', 'editSub'),
      data: {
        event,
      },
    });
  },
  getSubmissionCount: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('events', `submissions/count`, { site }),
    });
  },
  getManagedEvents: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('events', `getmanaged`, { site }),
    });
  },
  getAvailableEvents: (type, term, count, minTime, maxTime) => {
    const query = { type };
    if (count) {
      query.count = count;
    }
    if (term) {
      query.term = term;
    }
    if (minTime) {
      query.minTime = minTime;
    }
    if (maxTime) {
      query.maxTime = maxTime;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('events', `content/get`, query),
    });
  },
  getPublishedAvailableEvents: (site, sourceId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('events', `content/check`, { site, sourceId }),
    });
  },
  getLocationTags: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('events', `tags/location`, { site }),
    });
  },
  deleteLocationTag: (site, tag) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('events', `tags/delete`),
      data: { site, tag, type: 'location' },
    });
  },
};
