import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getApiError } from '../../session';
import { DropdownInput, Button, CheckBox } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { renderTitle, renderTextStep } from './helper';
import AppSelector from './common/AppSelector';

const MESSAGE_APP = 'app';
const INTERVAL_BUILD_CHECK = 15000;
const RESULT_SUCCESS = 'SUCCESSFUL';
const STATUS_COMPLETE = 'COMPLETED';
const EXPO_STATUS_FINISHED = 'finished';
const EXPO_STATUS_ERRORED = 'errored';
const EXPO_STATUS_CANCELED = 'canceled';

class BuildApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabelInfo: _.cloneDeep(props.activeWhiteLabel),
      appInfo: null,
      buildInfo: null,
      loading: false,
      selectedApp: null,
      selectedPlatform: null,
      shouldIncrementVersion: false,
      shouldSubmit: true,
      showWarnings: false,
    };
    this.platformOptions = [
      {
        Key: 'ios',
        Title: 'iOS',
      },
      {
        Key: 'android',
        Title: 'Android',
      },
    ];
    this.checkStatusHandle = null;
  }

  componentDidUpdate(prevProps) {
    const { activeWhiteLabel } = this.props;
    if (
      activeWhiteLabel.ClientCode !== prevProps.activeWhiteLabel.ClientCode ||
      !_.isEqual(activeWhiteLabel.Apps, prevProps.activeWhiteLabel.Apps)
    ) {
      //   console.log('componentDidUpdate', activeWhiteLabel.Apps);
      this.setState({ whiteLabelInfo: activeWhiteLabel }, this.setAppInfo);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.checkStatusHandle);
  }

  setMessage = (key, message, extraStates = {}) => {
    return new Promise((resolve) => {
      const newState = { ...extraStates };
      newState[`message_${key}`] = message;
      // console.log('setMessage', newState);
      this.setState(newState, resolve());
    });
  };

  getMessage = (key) => this.state[`message_${key}`];

  getAppInfo = () => {
    const { selectedApp, whiteLabelInfo } = this.state;
    if (!selectedApp || !whiteLabelInfo || !whiteLabelInfo.Apps) return {};

    return whiteLabelInfo.Apps.find((a) => a.ClientCode === selectedApp.Key) || {};
  };

  setAppInfo = () => {
    this.setState({ appInfo: this.getAppInfo() }, this.setBuildInfo);
  };

  hasBuildInfo = () => {
    const { buildInfo } = this.state;
    return !_.isNil(buildInfo) && !_.isNil(buildInfo.PipelineId);
  };

  isBuildInProgress = () => {
    const { buildInfo } = this.state;
    const hasBuildInfo = this.hasBuildInfo();
    if (!hasBuildInfo) return false;
    if (buildInfo.AutoSubmit) {
      if (!_.isNil(buildInfo.SubmitStatus)) return false;
    } else {
      if (!_.isNil(buildInfo.BuildStatus)) return false;
    }
    if (buildInfo.PipelineStatus === STATUS_COMPLETE) return false;
    return true;
  };

  queueStatusCheck = () => {
    if (this.isBuildInProgress()) {
      clearTimeout(this.checkStatusHandle);
      this.checkStatusHandle = setTimeout(this.checkBuildStatus, INTERVAL_BUILD_CHECK);
    }
  };

  setBuildInfo = async () => {
    await this.setMessage(MESSAGE_APP, '');

    const { appInfo, selectedPlatform } = this.state;
    if (!appInfo || !selectedPlatform) return;

    const buildKey = `Build${_.startCase(selectedPlatform.Key)}`;
    const buildInfo = appInfo[buildKey];
    // console.log('setBuildInfo', buildInfo);
    this.setState({ buildInfo }, this.queueStatusCheck);
  };

  checkBuildStatus = async () => {
    if (!this.isValid()) return;

    const { whiteLabelInfo, selectedApp, selectedPlatform } = this.state;
    const whitelabelId = whiteLabelInfo.RowId;
    const appClientCode = selectedApp.Key;
    const platform = selectedPlatform.Key;
    try {
      await this.setMessage(MESSAGE_APP, `Checking ${selectedPlatform.Title} app build...`, { loading: true });
      const { data } = await automationActions.checkAppBuild(whitelabelId, appClientCode, platform);
      // console.log('checkBuildStatus', data);
      if (data) this.props.whiteLabelLoaded(data);
      await this.setMessage(MESSAGE_APP, '', { loading: false });
      this.queueStatusCheck();
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_APP, `Error: ${message}`, { loading: false });
    }
  };

  isValid = () => {
    const { loading, whiteLabelInfo, selectedApp, selectedPlatform } = this.state;
    return !loading && whiteLabelInfo && selectedApp && selectedPlatform;
  };

  onAppSelected = (selectedApp) => {
    // console.log('onAppSelected', selectedApp);
    this.setState({ selectedApp }, this.setAppInfo);
  };

  onSelectPlatform = (key) => {
    // console.log('onSelectPlatform', key);
    const selected = this.platformOptions.find((o) => o.Key === key);
    if (selected) this.setState({ selectedPlatform: selected }, this.setBuildInfo);
  };

  onStartBuild = async () => {
    if (!this.isValid()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ showWarnings: false });

    if (this.isBuildInProgress()) {
      if (!window.confirm(`There is a build in progress. Are you sure you want to start another build?`)) return;
    }

    const { whiteLabelInfo, selectedApp, selectedPlatform, shouldSubmit, shouldIncrementVersion } = this.state;
    const whitelabelId = whiteLabelInfo.RowId;
    const appClientCode = selectedApp.Key;
    const platform = selectedPlatform.Key;
    try {
      await this.setMessage(MESSAGE_APP, `Starting ${selectedPlatform.Title} app build...`, { loading: true });
      const { data } = await automationActions.startAppBuild(whitelabelId, appClientCode, platform, shouldSubmit, shouldIncrementVersion);
      //   console.log('onStartBuild', data);
      if (data) this.props.whiteLabelLoaded(data);
      await this.setMessage(MESSAGE_APP, '', { loading: false });
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_APP, `Error: ${message}`, { loading: false });
    }
  };

  onStopBuild = async () => {
    if (!this.isValid()) return;

    const { whiteLabelInfo, selectedApp, selectedPlatform } = this.state;
    const whitelabelId = whiteLabelInfo.RowId;
    const appClientCode = selectedApp.Key;
    const platform = selectedPlatform.Key;

    try {
      await this.setMessage(MESSAGE_APP, `Stopping ${selectedPlatform.Title} app build...`, { loading: true });
      const { data } = await automationActions.stopAppBuild(whitelabelId, appClientCode, platform);
      //   console.log('onStopBuild', data);
      if (data) this.props.whiteLabelLoaded(data);
      await this.setMessage(MESSAGE_APP, '', { loading: false });
    } catch (error) {
      const message = getApiError(error).message;
      await this.setMessage(MESSAGE_APP, `Error: ${message}`, { loading: false });
    }
  };

  renderProgressStatus(inProgress, successful, failed) {
    if (failed) return <FontAwesome className="marginLeft-10 text-plussRed" name={'times'} />;
    if (successful) return <FontAwesome className="marginLeft-10 text-teal" name={'check'} />;
    if (inProgress) return <FontAwesome className="marginLeft-10 fontSize-13" name="spinner fa-pulse fa-fw" />;
  }

  renderProgress() {
    const { buildInfo } = this.state;
    if (!this.hasBuildInfo()) return null;

    const isPipelineSuccess = buildInfo.PipelineStatus === STATUS_COMPLETE && buildInfo.PipelineResult === RESULT_SUCCESS;
    const isBuildSuccess = buildInfo.BuildStatus === EXPO_STATUS_FINISHED;
    // console.log('renderProgress', buildInfo);

    return (
      <div className="marginTop-16">
        <div className="flex flex-row flex-center marginBottom-5">
          <div style={{ width: 242 }}>- Request package to be built</div>
          {this.renderProgressStatus(
            !_.isNil(buildInfo.PipelineId),
            isPipelineSuccess,
            buildInfo.PipelineStatus === STATUS_COMPLETE && buildInfo.PipelineResult !== RESULT_SUCCESS,
          )}
          {buildInfo.PipelineUrl ? (
            <a href={buildInfo.PipelineUrl} className="marginLeft-10" target="_blank" rel="noopener noreferrer">
              See More
            </a>
          ) : null}
        </div>
        <div className="flex flex-row flex-center marginBottom-5">
          <div style={{ width: 242 }}>- Build package for submission</div>
          {this.renderProgressStatus(
            isPipelineSuccess,
            isBuildSuccess,
            buildInfo.BuildStatus === EXPO_STATUS_ERRORED || buildInfo.BuildStatus === EXPO_STATUS_CANCELED,
          )}
          {buildInfo.BuildUrl ? (
            <a href={buildInfo.BuildUrl} className="marginLeft-10" target="_blank" rel="noopener noreferrer">
              Download Package
            </a>
          ) : null}
        </div>
        {buildInfo.AutoSubmit ? (
          <div className="flex flex-row flex-center marginBottom-5">
            <div style={{ width: 242 }}>- Submit package to the store</div>
            {this.renderProgressStatus(
              isBuildSuccess,
              buildInfo.SubmitStatus === EXPO_STATUS_FINISHED,
              buildInfo.SubmitStatus === EXPO_STATUS_ERRORED || buildInfo.SubmitStatus === EXPO_STATUS_CANCELED,
            )}
            {buildInfo.SubmitLogUrl ? (
              <a href={buildInfo.SubmitLogUrl} className="marginLeft-10" target="_blank" rel="noopener noreferrer">
                See More
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }

  renderStartButton() {
    const { buildInfo, loading } = this.state;
    let message = this.getMessage(MESSAGE_APP);
    if (buildInfo) {
      if (buildInfo.BuildError) message = buildInfo.BuildError.message;
      if (buildInfo.SubmitError) message = buildInfo.SubmitError.message;
    }

    return (
      <div className="marginTop-24">
        {this.renderIncrementVersion()}
        {this.renderAutoSubmit()}
        <div className="flex flex-row flex-center">
          <Button className="marginRight-16" inline buttonType="primary" onClick={this.onStartBuild} isActive={!loading}>
            Start
          </Button>
          <div>{message}</div>
        </div>
      </div>
    );
  }

  renderPlatformSelect() {
    const { loading, selectedPlatform, showWarnings, buildInfo } = this.state;
    const selectedValue = selectedPlatform ? selectedPlatform.Title : '';

    return (
      <div className="flex flex-row flex-center">
        <div style={{ width: 250 }}>Select platform to build</div>
        <DropdownInput
          id={`dropdown_platforms`}
          style={{ width: 250, marginTop: 10, marginBottom: 10 }}
          placeholder="Select Platform"
          value={selectedValue}
          options={this.platformOptions}
          onSelect={this.onSelectPlatform}
          disabled={loading}
          showError={() => showWarnings && _.isEmpty(selectedValue)}
          errorMessage={'Platform is required'}
        />
      </div>
    );
  }

  renderIncrementVersion() {
    const { shouldIncrementVersion, loading } = this.state;

    return (
      <CheckBox
        key={'check_increment_version'}
        label="Increment Version"
        isActive={shouldIncrementVersion}
        onChange={() => this.setState({ shouldIncrementVersion: !shouldIncrementVersion })}
        style={{ marginBottom: 16 }}
        disabled={loading}
      />
    );
  }

  renderAutoSubmit() {
    const { shouldSubmit, loading } = this.state;

    return (
      <CheckBox
        key={'check_submit'}
        label="Submit"
        isActive={shouldSubmit}
        onChange={() => this.setState({ shouldSubmit: !shouldSubmit })}
        style={{ marginBottom: 16 }}
        disabled={loading}
      />
    );
  }

  render() {
    const { selectedApp, loading } = this.state;
    return (
      <div>
        {renderTitle('Build App')}
        <ol>
          {renderTextStep(
            <div>
              <AppSelector onSelected={this.onAppSelected} selected={selectedApp} includeSubApps disabled={loading} />
            </div>,
          )}
          {selectedApp
            ? renderTextStep(
                <div>
                  {this.renderPlatformSelect()}
                  {this.renderProgress()}
                  {this.renderStartButton()}
                </div>,
              )
            : null}
        </ol>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { automation } = state;
  return { activeWhiteLabel: automation.active };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(BuildApp);
