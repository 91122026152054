import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton, SideNavItem, Button, HubSidebar } from '../../components';
import { validateAccess } from '../../session';
import { eventsLoaded, eventSubmissionsLoaded, setAuth } from '../../actions';
import { eventActions } from '../../webapi';
import ListEvents from './ListEvents';
import EventSubmissions from './EventSubmissions';
import { COLOUR_BRANDING_OFF, COLOUR_BRANDING_ACTION, TEXT_LIGHT } from '../../js';
import SelectedEventDate from './SelectedEventDate';
import EventHubAnalytics from './EventHubAnalytics';
import { getPluralS, getUrlParams, isContentSource } from '../../helper';
import AvailableEvents from './AvailableEvents';
import { hasAvailableEvents } from '../../config';
import { Text } from '../../components/text';

class EventsHub extends Component {
  constructor(props) {
    super(props);

    const params = getUrlParams();
    let selectedSection = 'all';
    if (params.tab) {
      selectedSection = params.tab;
    }

    this.state = {
      selectedSection,
      selectedView: 'calendar',
      location: '',

      loadingEvents: false,
      loadingSubmissions: false,

      submissionEntries: [],

      events: [],
      upcomingEvents: [],
      pastEvents: [],
      now: moment.utc(),
      onlyFuture: true,
      search: '',
    };
  }

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
    if (Object.keys(this.props.events).length > 0) {
      var maxElem = _.maxBy(this.props.events, (event) => {
        if (!event) {
          return 0;
        }
        return event.Changed;
      });
      this.loadEvents(maxElem.Changed + 1);
    } else {
      this.loadEvents();
    }
    this.getSubmissions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const events = [];

    props.events.forEach((ev) => {
      if (ev != null && !ev.Deleted) {
        events.push(ev);
      }
    });

    const upcomingEvents = _.filter(events, (ev) => {
      if (ev.IsEveryday) {
        return true;
      }
      if (ev.RepeatedTimes) {
        if (
          _.some(ev.RepeatedTimes, (rep) => {
            return moment.utc(rep.Time).isSameOrAfter(this.state.now);
          })
        ) {
          return true;
        }
        return false;
      }
      return (
        moment.utc(ev.StartTime).isSameOrAfter(this.state.now) ||
        (ev.EndTime != null && ev.EndTime !== '' && moment.utc(ev.EndTime).isSameOrAfter(this.state.now))
      );
    });

    const pastEvents = _.reject(events, (item) =>
      _.find(upcomingEvents, (t) => {
        return t.Id === item.Id;
      }),
    );

    this.setState({ events, upcomingEvents, submissionEntries: props.submissions, pastEvents });
  }

  loadEvents(time) {
    this.setState({
      loadingEvents: true,
    });

    eventActions
      .getEvents(this.props.auth.site, time, true)
      .then((res) => {
        this.setState({
          loadingEvents: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.eventsLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loadingEvents: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  getSubmissions() {
    this.setState({
      loadingSubmissions: true,
    });

    eventActions
      .getEventSubmissions(this.props.auth.site)
      .then((res) => {
        this.setState({
          loadingSubmissions: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.eventSubmissionsLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loadingSubmissions: false });
      });
  }

  updateView(choice, useCache) {
    if (useCache && !_.isUndefined(this.props.auth.viewChoice)) {
      choice = this.props.auth.viewChoice;
    }
    this.setState({ selectedView: choice, selectedDate: null });
    if (choice !== 'calendar') {
      this.props.setAuth({ viewChoice: choice });
    }
  }

  selectDate(date) {
    this.setState({
      selectedDate: date,
    });
  }

  addNew = (action) => {
    if (
      validateAccess(this.props.auth.site, 'events', this.props.auth) ||
      validateAccess(this.props.auth.site, 'eventSubmit', this.props.auth)
    ) {
      if (action && typeof action === 'string') {
        this.props.history.push(`/events/event?action=${action}`);
      } else {
        this.props.history.push('/events/event');
      }
    }
  };

  canAddNew(isClass) {
    if (validateAccess(this.props.auth.site, 'events', this.props.auth)) {
      return isClass ? '' : true;
    }
    return isClass ? ' hub-sideContent-topButton--hide' : false;
  }

  renderSelectedDate() {
    return <SelectedEventDate date={this.state.selectedDate} onClose={this.selectDate.bind(this, null)} />;
  }

  renderLeftBar() {
    if (
      validateAccess(this.props.auth.site, 'eventSubmit', this.props.auth) &&
      !validateAccess(this.props.auth.site, 'events', this.props.auth) &&
      !validateAccess(this.props.auth.site, 'eventAttendance', this.props.auth)
    ) {
      return null;
    }

    const sectionItems = [];

    if (this.canAddNew()) {
      sectionItems.push({
        type: 'newButton',
        text: 'New Event',
        onClick: this.addNew,
      });
    }

    sectionItems.push({
      type: 'navItem',
      text: 'View Events',
      icon: 'eye',
      isFontAwesome: true,
      selected: this.state.selectedSection === 'all',
      onClick: () => {
        this.setState({ selectedSection: 'all' });
      },
    });

    sectionItems.push({
      type: 'navItem',
      text: 'Analytics',
      icon: 'line-chart',
      isFontAwesome: true,
      selected: this.state.selectedSection === 'Analytics',
      onClick: () => {
        this.setState({ selectedSection: 'Analytics' });
      },
    });

    if (validateAccess(this.props.auth.site, 'events', this.props.auth)) {
      let submissionText = 'View Submissions';
      if (!this.state.loadingSubmissions) {
        submissionText = `View Submission${getPluralS(this.state.submissionEntries.length)} (${this.state.submissionEntries.length})`;
      }
      sectionItems.push({
        type: 'navItem',
        text: submissionText,
        // icon: 'question-circle-o',
        // isFontAwesome: true,
        selected: this.state.selectedSection === 'submissions',
        onClick: () => {
          this.setState({ selectedSection: 'submissions' });
        },
      });
    }

    return (
      <HubSidebar
        sections={[
          {
            title: 'Events',
            items: sectionItems,
          },
        ]}
      />
    );
  }

  renderEventSubmitButton() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: 'fit-content' }}>
        <AddButton onClick={this.addNew.bind(this)} text="SUBMIT NEW EVENT" />
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
          <div className="emptyState" />
          <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
            <Text type="h3">Submit your new event here. You will be notified once your event has been approved.</Text>
          </div>
        </div>
      </div>
    );
  }

  renderRight() {
    if (
      validateAccess(this.props.auth.site, 'eventSubmit', this.props.auth) &&
      !validateAccess(this.props.auth.site, 'events', this.props.auth) &&
      !validateAccess(this.props.auth.site, 'eventAttendance', this.props.auth)
    ) {
      return this.renderEventSubmitButton();
    }
    if (this.state.selectedSection === 'Analytics') {
      return <EventHubAnalytics events={this.state.events} />;
    }
    if (this.state.selectedSection === 'submissions') {
      return <EventSubmissions />;
    }
    if (this.state.selectedSection === 'available') {
      return <AvailableEvents />;
    }
    return (
      <ListEvents
        filterOption={this.state.selectedSection}
        viewType={this.state.selectedView}
        updateView={this.updateView.bind(this)}
        selectDate={this.selectDate.bind(this)}
        selectedDate={this.state.selectedDate}
      />
    );
  }

  renderAddButton() {
    if (isContentSource(this.props.auth.site)) {
      return (
        <div className="topHeader-addButtonWithDropdown" style={{ position: 'relative' }}>
          <AddButton text="NEW EVENT" />
          <div
            className="hub-sideContent-topButton-dropdown"
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              top: 30,
              minWidth: 100,
              boxShadow: '0px 0px 16px rgba(106, 163, 216, 0.5)',
            }}
          >
            <div
              onClick={() => {
                this.addNew('global');
              }}
              className="fontRegular fontSize-16 subtleHover"
              style={{ padding: 8 }}
            >
              New global event
            </div>
            <div
              onClick={() => {
                this.addNew('template');
              }}
              className="fontRegular fontSize-16 subtleHover"
              style={{ padding: 8 }}
            >
              New event template
            </div>
          </div>
        </div>
      );
    }
    return <AddButton onClick={this.addNew.bind(this)} text="NEW EVENT" />;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderSelectedDate()}
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { events, submissions } = state.events;
  const { auth } = state;
  return {
    events,
    submissions,
    auth,
    paymentEnabled: auth.user && auth.user.paymentInfo && auth.user.paymentInfo.enabled,
  };
};

export default connect(mapStateToProps, { eventsLoaded, eventSubmissionsLoaded, setAuth })(EventsHub);
