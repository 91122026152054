import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import 'react-step-progress/dist/index.css';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { isTheBest, getApiError } from '../../session';

class AWSSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: {},
    };
  }

  canContinue = () => {
    const { activeWhiteLabel } = this.props;
    const { loading } = this.state;
    return !loading && activeWhiteLabel.AWSAccessKey && activeWhiteLabel.AWSSecretKey;
  };

  onMoveStep = (stepIndex) => {
    if (this.props.onMoveStep) this.props.onMoveStep(stepIndex);
  };

  onSetupAWS = () => {
    if (!this.canContinue()) return;

    const { message } = this.state;
    const { activeWhiteLabel } = this.props;

    const currentSection = activeWhiteLabel.Section;
    const step = activeWhiteLabel.AWSStep || 0;
    message[step] = 'Processing...';
    this.setState({ loading: true }, async () => {
      try {
        const { data } = await automationActions.setupAWS(activeWhiteLabel.RowId);
        message[step] = '';
        this.props.whiteLabelLoaded(data);
        const continuing = currentSection === data.Section;
        this.setState({ loading: false, message }, continuing ? () => setTimeout(this.onSetupAWS, 5000) : null);
      } catch (error) {
        message[step] = getApiError(error).message;
        const waiting = message[step].includes('please wait');
        this.setState({ loading: false, message }, waiting ? () => setTimeout(this.onSetupAWS, 10000) : null);
      }
    });
  };

  renderStep = (step, title, exists, content) => {
    const { message } = this.state;
    return (
      <tr key={step} style={{ fontSize: 11 }}>
        <td>{title}</td>
        <td>{exists ? content : null}</td>
        <td>{message[step]}</td>
        <td>{exists ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}</td>
      </tr>
    );
  };

  renderProgress = () => {
    const { activeWhiteLabel } = this.props;
    return (
      <div className="flex-1 automation">
        <Button style={{ width: 90 }} inline buttonType="primary" onClick={this.onSetupAWS} isActive={this.canContinue()}>
          Continue
        </Button>
        <Table className="plussTable" striped bordered condensed hover>
          <thead>
            <tr>
              <th>Step</th>
              <th>Values</th>
              <th>Message</th>
              <th>Completed</th>
            </tr>
          </thead>
          <tbody>
            {this.renderStep(
              0,
              'Create auto-verify function',
              !_.isEmpty(activeWhiteLabel.AutoVerifyArn),
              <div>
                Auto Verify Function ARN
                <br />
                <b>{activeWhiteLabel.AutoVerifyArn}</b>
              </div>,
            )}
            {this.renderStep(
              1,
              'Create user pool',
              !_.isEmpty(activeWhiteLabel.UserPoolId) && !_.isEmpty(activeWhiteLabel.UserPoolClientId),
              <div>
                <div>
                  User Pool Id
                  <br />
                  <b>{activeWhiteLabel.UserPoolId}</b>
                </div>
                <div>
                  User Pool Client Id
                  <br />
                  <b>{activeWhiteLabel.UserPoolClientId}</b>
                </div>
              </div>,
            )}
            {this.renderStep(
              2,
              'Create identity pool',
              !_.isEmpty(activeWhiteLabel.IdentityPoolId),
              <div>
                Identity Pool Id
                <br />
                <b>{activeWhiteLabel.IdentityPoolId}</b>
              </div>,
            )}
            {this.renderStep(
              3,
              'Create domain and certificate',
              !_.isEmpty(activeWhiteLabel.ApiHostedZoneId) && !_.isEmpty(activeWhiteLabel.ApiCertificateArn),
              <div>
                <div>
                  Hosted Zone Id
                  <br />
                  <b>{activeWhiteLabel.ApiHostedZoneId}</b>
                </div>
                <div>
                  Certificate ARN
                  <br />
                  <b>{activeWhiteLabel.ApiCertificateArn}</b>
                </div>
              </div>,
            )}
            {this.renderStep(
              4,
              'Create domain name for API gateway',
              !_.isEmpty(activeWhiteLabel.ApiDomainName) && !_.isEmpty(activeWhiteLabel.ApiGatewayName),
              <div>
                <div>
                  API Domain Name
                  <br />
                  <b>{activeWhiteLabel.ApiDomainName}</b>
                </div>
                <div>
                  API Gateway Name
                  <br />
                  <b>{activeWhiteLabel.ApiGatewayName}</b>
                </div>
              </div>,
            )}
            {this.renderStep(
              5,
              'Create deployment bucket',
              !_.isEmpty(activeWhiteLabel.DeploymentBucket),
              <div>
                Deployment Bucket
                <br />
                <b>{activeWhiteLabel.DeploymentBucket}</b>
              </div>,
            )}
            {this.renderStep(
              6,
              'Deploy lambda layers',
              !_.isEmpty(activeWhiteLabel.LayerPipelineId) && !_.isEmpty(activeWhiteLabel.LayerPipelineUrl),
              <div>
                <div>
                  Deploy Pipeline Id
                  <br />
                  <b>{activeWhiteLabel.LayerPipelineId}</b>
                </div>
                <div>
                  <a href={activeWhiteLabel.LayerPipelineUrl} target="_blank" rel="noopener noreferrer">
                    See Deploy Pipeline
                  </a>
                  {` - ${activeWhiteLabel.LayerPipelineStatus}`}
                </div>
              </div>,
            )}
            {this.renderStep(
              7,
              'Complete lambda layers setup',
              !_.isEmpty(activeWhiteLabel.Layers),
              <div>
                <div>Lambda Layers:</div>
                {activeWhiteLabel.Layers &&
                  activeWhiteLabel.Layers.map((layer) => {
                    return (
                      <div key={layer.LayerName}>
                        <b>{layer.LayerArn}</b>
                      </div>
                    );
                  })}
              </div>,
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    return this.renderProgress();
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(AWSSetup);
