import React, { Component } from 'react';
import queryString from 'querystring';
import { Button } from '../../components';
import { paymentActions } from '../../webapi';
import { paymentConfig } from '../../config';

export default class AddCardStripe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      customerId: '',
      clientSecret: '',
      errors: '',
      name: '',
    };
    this.params = [];
    this.hideWidgetInterval = null;
    this.stripe = null;
    this.cardNumber = null;
    this.cardExpiry = null;
    this.cardCvc = null;
  }

  UNSAFE_componentWillMount() {
    this.params = queryString.parse(this.props.location.search.replace(/^.*\?/, ''));
    // this.postMessage({ type: 'info', value: `configureSession - ${JSON.stringify(this.params)}` });

    this.configureStripeScript();
  }

  componentDidMount() {
    this.hideWidget();
    this.createPaymentSession();

    document.addEventListener('message', this.onMessage);
    window.addEventListener('message', this.onMessage);
  }

  hideWidget() {
    this.hideWidgetInterval = setInterval(() => {
      const widget = document.getElementById('tidio-chat');
      if (widget) {
        widget.hidden = true;
        if (!document.getElementById('tidio-chat')) {
          clearInterval(this.hideWidgetInterval);
        }
      }
    }, 100);
  }

  configureStripeScript = () => {
    const session = document.createElement('script');
    session.src = paymentConfig.stripeScript;
    session.async = true;
    session.onload = this.onStripeScriptLoaded;
    document.head.appendChild(session);
  };

  createPaymentSession = () => {
    this.setState({ loading: true }, async () => {
      try {
        const res = await paymentActions.createSession(this.params.auth);
        if (res.data) {
          this.setState({
            loading: false,
            customerId: res.data.customer,
            clientSecret: res.data.client_secret,
          });
        }
      } catch (error) {
        this.postMessage({
          type: 'error',
          value: `createPaymentSession error - ${error}`,
        });
        this.setState({ loading: false });
      } finally {
        this.postMessage({ type: 'session', value: true });
      }
    });
  };

  onStripeScriptLoaded = () => {
    this.configureSession();
  };

  onMessage = (e) => {
    // console.log(`received message from app - ${JSON.stringify(e.data)}`);
  };

  configureSession = () => {
    if (!this.params.pk) return;

    const classes = { base: 'genericInput padded', focus: 'focused' };
    const style = { base: { fontFamily: 'sf-regular, sans-serif', fontSize: '17px', color: '#3e4245' } };

    this.stripe = window.Stripe(this.params.pk);
    const elements = this.stripe.elements();
    this.cardNumber = elements.create('cardNumber', { classes, style });
    this.cardNumber.mount('#cardNumber-element');
    this.cardExpiry = elements.create('cardExpiry', { classes, style });
    this.cardExpiry.mount('#cardExpiry-element');
    this.cardCvc = elements.create('cardCvc', { classes, style });
    this.cardCvc.mount('#cardCvc-element');
  };

  postMessage = (message) => {
    switch (message.type) {
      case 'error':
        this.handleErrors(message.value);
        break;
      case 'loading':
        this.setState({ loading: message.value, errors: '' });
        break;
      default:
        break;
    }

    if (!window.ReactNativeWebView) return;
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  };

  handleErrors = (error) => {
    let errors = '';
    switch (error.code) {
      case 'parameter_invalid_empty':
        errors = 'Your cardholder name is incomplete.';
        break;
      default:
        errors = error.message;
    }
    this.setState({ errors });
  };

  onChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

  onAddCard = async () => {
    this.postMessage({ type: 'loading', value: true });
    this.setState({ loading: true }, async () => {
      try {
        const result = await this.stripe.confirmCardSetup(this.state.clientSecret, {
          payment_method: {
            card: this.cardNumber,
            billing_details: { name: this.state.name },
          },
        });
        // this.postMessage({ type: 'info', value: result });
        if (result.error) {
          this.postMessage({ type: 'error', value: result.error });
        } else if (result.setupIntent && result.setupIntent.payment_method) {
          this.setDefaultPaymentMethod(result.setupIntent.payment_method);
        } else {
          this.postMessage({ type: 'error', value: result });
        }
      } catch (error) {
        this.postMessage({
          type: 'error',
          value: `confirmCardSetup error - ${error}`,
        });
        this.setState({ loading: false });
      }
    });
  };

  setDefaultPaymentMethod = async (paymentMethodId) => {
    this.postMessage({ type: 'loading', value: true });
    this.setState({ loading: true }, async () => {
      try {
        const res = await paymentActions.setDefaultPaymentMethod(paymentMethodId, this.state.customerId, this.params.auth);
        // this.postMessage({ type: 'info', value: res.data });
        if (res && res.data) {
          this.postMessage({
            type: 'paymentMethod',
            value: {
              stripeCustomerId: res.data.id,
              paymentMethods: [res.data.invoice_settings.default_payment_method],
            },
          });
        }
        this.setState({ loading: false });
      } catch (error) {
        this.postMessage({
          type: 'error',
          value: `setDefaultPaymentMethod error - ${error}`,
        });
        this.setState({ loading: false });
      }
    });
  };

  renderErrors() {
    const { errors } = this.state;
    if (typeof errors === 'string') {
      return <p>{errors}</p>;
    }
    return errors.map((error, index) => {
      return <p key={index}>{error}</p>;
    });
  }

  render() {
    return (
      <div className="payment-container">
        <div className="payment-heading">Card Details</div>
        <div className="payment-error">{this.renderErrors()}</div>
        <div className="payment-field">
          <div className="label">Card Number</div>
          <div id="cardNumber-element"></div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="payment-field" style={{ width: '50%' }}>
            <div className="label">Expiry Date</div>
            <div id="cardExpiry-element"></div>
          </div>
          <div className="payment-field" style={{ paddingLeft: '50px' }}>
            <div className="label">CVV Number</div>
            <div id="cardCvc-element"></div>
          </div>
        </div>
        <div className="payment-field">
          <div className="label">Cardholder Name</div>
          <input
            className="genericInput"
            type="text"
            id="cardholder-name"
            title="cardholder name"
            aria-label="enter name on card"
            tabIndex="5"
            onChange={this.onChangeName}
          />
        </div>
        <Button
          buttonType="primary"
          buttonClassName="payment-button"
          onClick={this.onAddCard}
          isActive={!this.state.loading}
          style={{ backgroundColor: this.params.colour }}
        >
          Add Card
        </Button>
      </div>
    );
  }
}
