import { getUrl } from './helper';
import { authedFunction } from '../session';

export const infoActions = {
  getInfoPages: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('info', 'pages/get', { site }),
    });
  },
  getInfoPage: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('info', `pages/get/${id}`),
    });
  },
  addInfoPage: (site, title, text, order, attachments, notify) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('info', 'pages/add'),
      data: {
        site,
        title,
        text,
        order,
        attachments,
        notify,
      },
    });
  },
  editInfoPage: (site, id, title, text, order, attachments, notify) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('info', 'pages/edit'),
      data: {
        site,
        id,
        title,
        text,
        order,
        attachments,
        notify,
      },
    });
  },
  deleteInfoPage: (site, id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('info', 'pages/remove'),
      data: {
        site,
        id,
      },
    });
  },
  changeInfoPageOrder: (site, changes) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('info', 'pages/order'),
      data: {
        site,
        changes,
      },
    });
  },
  getWelcomePages: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('info', 'welcome/get', { site }),
    });
  },
  getWelcomePage: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('info', `welcome/get/${id}`),
    });
  },
  addWelcomePage: (site, title, text, images, audienceType, audienceTypeSelection, order) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('info', 'welcome/add'),
      data: {
        site,
        title,
        text,
        images,
        audienceType,
        audienceTypeSelection,
        order,
      },
    });
  },
  editWelcomePage: (site, id, title, text, images, audienceType, audienceTypeSelection) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('info', 'welcome/edit'),
      data: {
        site,
        id,
        title,
        text,
        images,
        audienceType,
        audienceTypeSelection,
      },
    });
  },
  deleteWelcomePage: (site, id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('info', 'welcome/remove'),
      data: {
        site,
        id,
      },
    });
  },
  changeWelcomePageOrder: (site, changes) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('info', 'welcome/order'),
      data: {
        site,
        changes,
      },
    });
  },
  copyWelcomePage: (site, source) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('info', 'welcome/copy'),
      data: {
        site,
        source,
      },
    });
  },
  getCurrentWeather: async (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('info', 'weather/current', { site }),
    });
  },
  getWeatherCity: async (query) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('info', 'weather/city', { q: query }),
    });
  },
  getWeatherCityFromGooglePlace: async (googlePlaceId, sessionToken) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('info', 'weather/city', { googlePlaceId, sessionToken }),
    });
  },
  placeAutocomplete: async (query, token) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('info', 'places/autocomplete', { q: query, token }),
    });
  },
};
