import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { withRouter } from 'react-router';
import FontAwesome from 'react-fontawesome';
import { availableEventsLoaded } from '../../actions';
import { eventActions } from '../../webapi';
import { checkLoggedIn } from '../../session';
import { COLOUR_BRANDING_OFF } from '../../js';

class AvailableEvents extends Component {
  state = {
    globalEvents: [],
    templateEvents: [],
    sortColumn: 'DateUnix', // column to sort by
    sortDesc: false, // if true, sort descending rather than ascending
    now: moment.utc(),
    onlyFuture: true,
    selectedId: '',
    checkingPublished: false,
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    eventActions.getAvailableEvents('global', null, null, moment.utc().valueOf()).then((res) => {
      console.log(res.data);
      this.setState({
        globalEvents: res.data,
      });
      this.props.availableEventsLoaded('global', res.data);
    });
    eventActions.getAvailableEvents('template').then((res) => {
      console.log(res.data);
      this.setState({
        templateEvents: res.data,
      });
      this.props.availableEventsLoaded('template', res.data);
    });
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  openContent = (event) => {
    this.setState({ checkingPublished: true, selectedId: event.RowId });
    eventActions.getPublishedAvailableEvents(this.props.auth.site, event.RowId).then((res) => {
      this.setState({ checkingPublished: false, selectedId: '' }, () => {
        if (res.data && res.data.length > 0) {
          if (!window.confirm(`This event has previously been published to this site. Are you sure you want to continue?`)) return;
        }
        this.props.history.push(`/events/event?sourceId=${event.RowId}&type=${event.IsGlobal ? 'global' : 'template'}`);
      });
    });
  };

  renderItem(n) {
    const { checkingPublished, selectedId } = this.state;
    return (
      <div className="newsTile newsTile-event" key={n.RowId}>
        <div className="newsTile_image" style={{ backgroundImage: `url(${n.Image})` }}></div>
        <div className="newsTile_bottom">
          <div className="newsTile_bottom_top">
            <a onClick={this.openContent.bind(this, n)} id={n.RowId} className="newsTile_title truncate">
              {n.Title}
            </a>
            <p className="newsTile_description">{n.Description}</p>
          </div>
          <div className="newsTile_tagDate">
            {n.IsGlobal && <p className="newsTile_date">{moment.utc(n.GlobalStartTime).local().format('ddd, D MMM YYYY • h:mma')}</p>}
          </div>
          {checkingPublished && selectedId === n.RowId && <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />}
        </div>
      </div>
    );
  }

  renderContent(source) {
    source = _.sortBy(source, this.state.sortColumn);
    if (this.state.sortDesc) {
      source.reverse();
    }
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });
    return source.map((n) => {
      return this.renderItem(n);
    });
  }

  render() {
    return (
      <div style={{ minWidth: '100%' }}>
        {!_.isEmpty(this.state.templateEvents) && (
          <div className="clearfix">
            <p className="fontMedium fontSize-36 text-dark">Templates</p>
            {this.renderContent(_.sortBy(this.state.templateEvents, 'Title'))}
          </div>
        )}
        {!_.isEmpty(this.state.globalEvents) && (
          <div className={`clearfix${!_.isEmpty(this.state.templateEvents) ? ' marginTop-16' : ''}`}>
            <p className="fontMedium fontSize-36 text-dark">Global Events</p>
            {this.renderContent(this.state.globalEvents)}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { news } = state.news;
  const { auth } = state;
  return {
    news,
    auth,
  };
};

const styles = {
  spinner: {
    marginLeft: 2,
    fontSize: 18,
    color: COLOUR_BRANDING_OFF,
  },
};

export default connect(mapStateToProps, { availableEventsLoaded })(withRouter(AvailableEvents));
