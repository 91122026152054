import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { usersLoaded, setAuth } from '../../actions';
import { checkLoggedIn, validateAccess } from '../../session';
import { typeActions, analyticsActions } from '../../webapi';
import { getPluralS, getPercentage, TIMESTAMPFORMAT } from '../../helper';
import { Button } from '../../components';
import LinkUserPopup from './LinkUsers/LinkUserPopup';
import { Text } from '../../components/text';

class UserAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sites: [],
      types: [],
    };
  }

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    if (!validateAccess(this.props.auth.site, 'userManagement', this.props.auth, true)) {
      this.props.history.push('/mastermenu');
    } else {
      this.getTypes();
      this.getSites();
      this.getStats();
      this.getFailedLogins();
    }
  }

  getTypes() {
    typeActions
      .getUserTypes(this.props.auth.site === 'hq' ? 'all' : this.props.auth.site)
      .then((res) => {
        if (this.props.auth.site !== 'hq') {
          this.props.setAuth({ userTypes: _.cloneDeep(res.data) });
        }
        res.data.forEach((e) => {
          e.Title = e.displayName;
          e.Key = e.typeName;
        });
        this.setState({
          types: res.data,
        });
      })
      .catch((error) => {});
  }

  getStats() {
    this.setState({
      loading: true,
      userToLink: null,
    });
    analyticsActions
      .getAggregateUserStats(this.props.auth.site)
      .then((res) => {
        this.setState({
          ...res.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
      });
  }

  getFailedLogins() {
    this.setState({
      loadingFailedLogins: true,
    });
    analyticsActions
      .getActivityLog(this.props.auth.site, 'auth', 'FailedLogin')
      .then((res) => {
        this.setState({
          failedLogins: res.data,
          loadingFailedLogins: false,
        });
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
      });
  }

  getSites() {
    typeActions
      .getSites()
      .then((res) => {
        res.data.forEach((e) => {
          e.Title = e.siteName;
          e.Key = e.Id;
        });
        this.setState({
          sites: res.data,
        });
      })
      .catch((error) => {});
  }

  getTypeTitle(user) {
    const typeObject = _.find(this.props.userTypes, (t) => {
      return t.typeName === user.type && t.site === user.site;
    });
    if (typeObject) {
      return typeObject.displayName;
    }
    if (user.type) {
      return user.type;
    }
    return '';
  }

  getSiteTitle(site) {
    const siteObject = _.find(this.state.sites, (t) => {
      return t.Id === site;
    });
    if (siteObject) {
      console.log(siteObject);
      return siteObject.siteName;
    }
    return site;
  }

  openNewUserLink(user) {
    this.setState({
      userToLink: user,
    });
  }

  closeLinkPopup() {
    this.setState({
      userToLink: null,
    });
  }

  renderUser(user) {
    const url = `/usershub/user/${encodeURIComponent(user.Id)}`;
    return (
      <tr key={user.Id}>
        <td className="table-TitleColumn">
          <Link to={url}>{user.displayName}</Link>
        </td>
        <td>{this.getTypeTitle(user)}</td>
        <td>No</td>
        <td style={{ width: 70 }}>
          <Button
            compact={true}
            buttonType="primary"
            onClick={this.openNewUserLink.bind(this, user)}
            isActive={true}
            buttonStyle={{ marginRight: 16 }}
          >
            Connect
          </Button>
        </td>
      </tr>
    );
  }

  renderFamilyUsers() {
    if (_.isEmpty(this.state.familyWithoutLinks)) {
      return null;
    }
    return (
      <div className="familyuserstable paddingTop-40">
        <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
          <thead>
            <tr>
              <th className="user-username" style={{ cursor: 'pointer', minwidth: 50 }}>
                User name
              </th>
              <th className="user-type" style={{ cursor: 'pointer', minwidth: 50 }}>
                Type
              </th>
              <th className="user-residentbool" style={{ cursor: 'pointer', minwidth: 50 }}>
                Connected to user
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {_.map(this.state.familyWithoutLinks, (user) => {
              return this.renderUser(user);
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  renderFailedLoginRow(row) {
    console.log(row);
    let name = <td className="table-TitleColumn">{row.Data.displayName}</td>;
    if (row.User.id) {
      name = (
        <td className="table-TitleColumn">
          <Link to={`/usershub/user/${encodeURIComponent(row.Data.id)}`}>{row.Data.displayName}</Link>
        </td>
      );
    }
    return (
      <tr key={row.Id}>
        <td>{moment.utc(row.Timestamp).local().format(TIMESTAMPFORMAT)}</td>
        {this.props.auth.site === 'hq' && <td>{this.getSiteTitle(row.Site)}</td>}
        {name}
        <td>{row.Data.enteredUserName}</td>
      </tr>
    );
  }

  renderFailedLogins() {
    if (_.isEmpty(this.state.failedLogins)) {
      return null;
    }
    return (
      <div className="useranalytics--overview paddingVertical-40">
        <Text type="formTitleSmall" className="marginBottom-16">
          Failed Login Attempts
        </Text>
        <div className="familyuserstable">
          <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
            <thead>
              <tr>
                <th className="user-username" style={{ cursor: 'pointer', minwidth: 50 }}>
                  Time
                </th>
                {this.props.auth.site === 'hq' && (
                  <th className="user-username" style={{ cursor: 'pointer', minwidth: 50 }}>
                    Site
                  </th>
                )}
                <th className="user-type" style={{ cursor: 'pointer', minwidth: 100 }}>
                  Name
                </th>
                <th className="user-type" style={{ cursor: 'pointer', minwidth: 100 }}>
                  Username entered
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(this.state.failedLogins, (user) => {
                return this.renderFailedLoginRow(user);
              })}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }

  renderNewUserLink() {
    if (!this.state.userToLink) {
      return null;
    }
    return <LinkUserPopup user={this.state.userToLink} onClose={this.closeLinkPopup.bind(this)} onSuccess={this.getStats.bind(this)} />;
  }

  renderStats() {
    if (this.state.loading) {
      return null;
    }
    return (
      <div>
        <div className="useranalytics--overview paddingTop-40">
          <Text type="formTitleSmall" className="marginBottom-16">
            Overview
          </Text>
          <div className="polloverview polloverview--userAnalytics">
            <div className="polloverview_section">
              <FontAwesome className="polloverview_icon" name="user" />
              <div className="polloverview_right">
                <p className="polloverview_count">{this.state.residentCount}</p>
                <p className="polloverview_text">
                  Primary user{getPluralS(this.state.residentCount)}
                  <br />
                  <span className="polloverview_subtext">({this.state.residentActiveCount} Active)</span>
                </p>
              </div>
            </div>
            <div className="polloverview_section">
              <FontAwesome className="polloverview_icon" name="user" />
              <div className="polloverview_right">
                <p className="polloverview_count">{this.state.staffCount}</p>
                <p className="polloverview_text">
                  Staff
                  <br />
                  <span className="polloverview_subtext">({this.state.staffActiveCount} Active)</span>
                </p>
              </div>
            </div>
            <div className="polloverview_section">
              <FontAwesome className="polloverview_icon" name="users" />
              <div className="polloverview_right">
                <p className="polloverview_count">{this.state.familyCount}</p>
                <p className="polloverview_text">
                  Linked user{getPluralS(this.state.familyCount)}
                  <br />
                  <span className="polloverview_subtext">({this.state.familyActiveCount} Active)</span>
                </p>
              </div>
            </div>
            <div className="polloverview_section">
              <FontAwesome className="polloverview_icon" name="user-plus" />
              <div className="polloverview_right">
                <p className="polloverview_count">{this.state.viaInviteCode}</p>
                <p className="polloverview_text">Sign up{getPluralS(this.state.viaInviteCode)} via invite code</p>
              </div>
            </div>
          </div>
          <div className="useranalytics--overview paddingTop-40">
            <Text type="formTitleSmall" className="marginBottom-16">
              Linked Users
            </Text>
            <div className="polloverview polloverview--userAnalytics">
              <div className="polloverview_section">
                <FontAwesome className="polloverview_icon" name="users" />
                <div className="polloverview_right">
                  <p className="polloverview_count">{this.state.residentsWithLinks}</p>
                  <p className="polloverview_text">
                    Primary user{getPluralS(this.state.residentsWithLinks)} connected
                    <br />
                    to active linked users
                  </p>
                </div>
              </div>
              <div className="polloverview_section">
                <FontAwesome className="polloverview_icon" name="line-chart" />
                <div className="polloverview_right">
                  <p className="polloverview_count">{getPercentage(this.state.residentsWithLinks, this.state.residentCount)}</p>
                  <p className="polloverview_text">
                    Percentage of primary users
                    <br />
                    connected to active linked users
                  </p>
                </div>
              </div>
              <div className="polloverview_section">
                <FontAwesome className="polloverview_icon" name="warning" />
                <div className="polloverview_right">
                  <p className="polloverview_count">{this.state.familyWithoutLinks.length}</p>
                  <p className="polloverview_text">
                    Linked user{getPluralS(this.state.familyWithoutLinks.length)} not connected to primary user
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ minWidth: '100%' }}>
        <p className="fontMedium fontSize-36 text-dark">Analytics</p>

        {this.renderStats()}
        {this.renderFamilyUsers()}
        {this.renderFailedLogins()}
        {this.renderNewUserLink()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, { usersLoaded, setAuth })(withRouter(UserAnalytics));
