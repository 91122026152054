import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { P60Icon } from '../../components';
import { getPluralS, isPaidItem } from '../../helper';

class EventCalendar extends Component {
  state = {
    shownMonth: new Date(),
    grid: [],
  };

  UNSAFE_componentWillMount() {
    this.onNewProps(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.onNewProps(nextProps);
  }

  onNewProps(nextProps, forceUpdate) {
    if (
      forceUpdate ||
      nextProps.selectedDate !== this.props.selectedDate ||
      nextProps.eventList.length !== this.props.eventList.length ||
      nextProps.view !== this.props.view
    ) {
      let dateToUse = nextProps.selectedDate;
      let selectedDate = dateToUse;
      if (!(dateToUse instanceof Date)) {
        if (!dateToUse) {
          dateToUse = this.state.shownMonth;
          selectedDate = null;
        } else {
          dateToUse = new Date(moment(dateToUse.dateKey, 'DD-MM-YYYY'));
          selectedDate = dateToUse;
        }
      }
      this.setState({
        shownMonth: dateToUse,
        shownWeek: moment(dateToUse).endOf('w').toDate(),
        selectedDate: selectedDate,
      });
      this.generateGrid(dateToUse, selectedDate, nextProps.eventList, nextProps.view);
    }
  }

  goToAnalytics(id, event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.history.push(`events/analytics/${id}`);
  }

  getShortDate() {
    if (!this.props.selectedDate) {
      return '';
    }
    return moment(this.props.selectedDate).format('DD/MM/YYYY');
  }

  getText() {
    if (this.props.view === 'week') {
      return `Week ${moment(this.state.shownWeek).format('W')} ${moment(this.state.shownWeek).startOf('w').format('YYYY')}`;
    }
    return moment(this.state.shownMonth).format('MMMM YYYY');
  }

  change = (change) => {
    if (this.props.view === 'week') {
      this.changeWeek(change);
    } else {
      this.changeMonth(change);
    }
  };

  changeWeek(change) {
    const newShownDate = moment(this.state.shownWeek).add(change, 'w').endOf('w').toDate();
    this.setState({
      shownWeek: newShownDate,
    });
    this.generateGrid(newShownDate, null, this.props.eventList);

    if (this.state.selectedDate) {
      setTimeout(() => {
        this.props.selectDate(null);
      }, 0);
    }
  }

  changeMonth(change) {
    const newShownDate = new Date(this.state.shownMonth.getFullYear(), this.state.shownMonth.getMonth() + 1 + change, 0);
    this.setState({
      shownMonth: newShownDate,
    });
    this.generateGrid(newShownDate, null, this.props.eventList);

    if (this.state.selectedDate) {
      setTimeout(() => {
        this.props.selectDate(null);
      }, 0);
    }
  }

  generateGrid(date, selectedDate, events, view) {
    const month = moment(date);
    const viewToUse = view || this.props.view;
    let dateToTarget = viewToUse === 'week' ? moment(date).endOf('week') : moment(date).endOf('month');
    let dateToModify = viewToUse === 'week' ? moment(date).startOf('week') : moment(date).startOf('month');
    const selectedDateToUse = selectedDate ? moment(selectedDate) : null;

    // set dateToModify to be Sunday of that week
    while (dateToModify.day() !== 0) {
      dateToModify = dateToModify.add(-1, 'day');
    }

    const rows = [[]];

    while (dateToTarget.day() !== 6) {
      dateToTarget = dateToTarget.add(1, 'day');
    }
    dateToTarget = dateToTarget.add(1, 'day'); // add an extra day to complete the week

    while (!dateToModify.isSame(dateToTarget, 'day')) {
      if (rows[rows.length - 1].length === 7) {
        // week has filled - add new row
        rows.push([]);
      }
      const thisDate = moment(dateToModify);
      const dateKey = thisDate.format('DD-MM-YYYY');
      rows[rows.length - 1].push({
        date: thisDate,
        dateKey: dateKey,
        display: thisDate.date(),
        isFaded: !thisDate.isSame(month, 'month'),
        isToday: thisDate.isSame(moment(), 'date'),
        isSelected: selectedDateToUse && thisDate.isSame(selectedDateToUse, 'date'),
        events: _.filter(events, (e) => {
          return e.DateKey === dateKey;
        }),
      });

      dateToModify = dateToModify.add(1, 'day');
    }

    this.setState({
      grid: rows,
    });
  }

  renderGrid() {
    return this.state.grid.map((row, index) => {
      return (
        <div className="eventCalendar__row" key={index}>
          {row.map((date, dateIndex) => {
            let classes = 'eventCalendar__date';
            if (date.isFaded) {
              classes += ' eventCalendar__date--faded';
            }
            if (date.isToday) {
              classes += ' eventCalendar__date--today';
            }
            if (date.isSelected) {
              classes += ' eventCalendar__date--selected';
            }
            if (index + 1 === this.state.grid.length) {
              classes += ' noBottomBorder';
            }
            if (dateIndex === 0) {
              classes += ' noLeftBorder';
            }
            if (dateIndex === 6) {
              classes += ' noRightBorder';
            }
            return (
              <div className={classes} key={date.dateKey}>
                <div className="eventCalendar__date__content">
                  <div className="eventCalendar__date__inner">{date.display}</div>
                  {date.events.map((ev, i) => {
                    if (i > 2 && this.props.view !== 'week') {
                      return null;
                    }
                    return (
                      <Link className="noUnderline" to={`/events/event/${ev.Id}`} key={i}>
                        <div className="eventCalendar__event">
                          <FontAwesome
                            onClick={($event) => {
                              this.goToAnalytics(ev.Id, $event);
                            }}
                            className="eventCalendar__analyticsButton"
                            name="bar-chart"
                          />
                          <span className="eventCalendar__eventTime">
                            {ev.AllDay ? 'All day' : `${ev.Time}${ev.EndTime ? ` - ${ev.EndTime}` : ''}`}
                          </span>
                          <span className="eventCalendar__eventTitle" alt={ev.Title}>
                            {isPaidItem(this.props.paymentEnabled, ev.Tickets) && this.props.view !== 'week' && (
                              <FontAwesome name="usd fa-fw" />
                            )}
                            {ev.Title}
                          </span>
                          {!_.isEmpty(ev.Location) && (
                            <span className="eventCalendar__eventLocation">
                              <br />
                              at {ev.Location}
                            </span>
                          )}
                        </div>
                      </Link>
                    );
                  })}
                  {date.events.length > 3 && (
                    <div
                      className="eventCalendar__moreEvents"
                      onClick={() => {
                        this.props.selectDate(date);
                      }}
                    >
                      {`+${date.events.length - 3} more event${getPluralS(date.events.length - 3)}`}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  }

  render() {
    return (
      <div
        className={`eventCalendar ${this.state.selectedDate != null ? 'eventCalendar--hasSelected' : ''}${
          this.props.view === 'week' ? ' eventCalendar--weekView' : ''
        }`}
      >
        <div className="eventCalendar__bottom">
          <div className="eventCalendar__monthSelector">
            <P60Icon
              className="eventCalendar__monthChevron"
              icon="chevron_left"
              onClick={() => {
                this.change(-1);
              }}
            />
            <p className="eventCalendar__selectedMonth">{this.getText()}</p>
            <P60Icon
              className="eventCalendar__monthChevron"
              icon="chevron_right"
              onClick={() => {
                this.change(1);
              }}
            />
          </div>
          <div className="eventCalendar__row marginTop-16">
            <p className="eventCalendar__colHeader noTopBorder noLeftBorder">Sun</p>
            <p className="eventCalendar__colHeader noTopBorder">Mon</p>
            <p className="eventCalendar__colHeader noTopBorder">Tue</p>
            <p className="eventCalendar__colHeader noTopBorder">Wed</p>
            <p className="eventCalendar__colHeader noTopBorder">Thu</p>
            <p className="eventCalendar__colHeader noTopBorder">Fri</p>
            <p className="eventCalendar__colHeader noTopBorder noRightBorder">Sat</p>
          </div>
          {this.renderGrid()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    paymentEnabled: auth.user && auth.user.paymentInfo && auth.user.paymentInfo.enabled,
  };
};

export default connect(mapStateToProps, {})(withRouter(EventCalendar));
