import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { validateAccess } from '../../session';
import { Header, Button, GenericInput, Popup } from '../../components';
import { Text } from '../../components/text';
import { paymentActions } from '../../webapi';
import { defaultProfileImage } from '../../config';
import { formatCurrency } from '../../helper';

const DEFAULT_MIN_TIME = moment().add(-3, 'months').valueOf();

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingAccs: false,
      loadingTrans: false,
      creating: false,
      deleting: false,
      onboarding: false,
      sortColumn: 'EndTime', // column to sort by
      sortDesc: true, // if true, sort descending rather than ascending
      connectedAccounts: [],
      transactions: [],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.loadConnectedAccounts();
      this.loadTransactions();
    }, 1000);
  }

  onCreateConnectedAccount = () => {
    if (!this.canManageAccounts()) return;

    this.setState({ creating: true }, async () => {
      try {
        const { data } = await paymentActions.createConnectedAccount(this.props.auth.site);
        // console.log('Connected Account', data);
        this.loadConnectedAccounts();
        window.location.href = data.url;
      } catch (error) {
        this.setState({ creating: false });
        console.log('onCreateConnectedAccount error', error);
      }
    });
  };

  onOnboardConnectedAccount = (accountId) => {
    if (!this.canManageAccounts()) return;

    this.setState({ onboarding: true }, async () => {
      try {
        const { data } = await paymentActions.getOnboardingLink(this.props.auth.site, accountId);
        window.location.href = data.url;
      } catch (error) {
        this.setState({ onboarding: false });
        console.log('getOnboardingLink error', error);
      }
    });
  };

  onDeleteConnectedAccount = (accountId) => {
    if (!this.canManageAccounts()) return;
    if (!window.confirm(`Are you sure you want to delete the connected account?`)) return;

    this.setState({ deleting: true }, async () => {
      try {
        await paymentActions.deleteConnectedAccount(this.props.auth.site, accountId);
        this.loadConnectedAccounts();
      } catch (error) {
        console.log('onDeleteConnectedAccount error', error);
        this.setState({ deleting: false });
      }
    });
  };

  onAccountClick = (accountId) => {
    if (!this.canManageAccounts()) return;

    this.setState({ loadingAccs: true }, async () => {
      try {
        const { data } = await paymentActions.getConnectedAccountLogin(this.props.auth.site, accountId);
        window.open(data.url, '_blank').focus();
      } catch (error) {
        console.log('onAccountClick error', error);
      } finally {
        this.setState({ loadingAccs: false });
      }
    });
  };

  handleChange(event) {
    var newState = {};
    newState[event.target.getAttribute('id')] = event.target.value;
    this.setState(newState);
  }

  openEditNickname = (account) => {
    this.setState({
      editingNickname: account.id,
      nicknameInput: account.nickname,
    });
  };

  closeEditNickname = () => {
    this.setState({
      editingNickname: null,
      nicknameInput: '',
    });
  };

  isReadyToSaveNickname = () => {
    return !_.isEmpty(this.state.nicknameInput);
  };

  saveEditNickname = () => {
    if (!this.isReadyToSaveNickname()) {
      return;
    }
    paymentActions.updateAccount(this.props.auth.site, this.state.editingNickname, this.state.nicknameInput).then((res) => {
      this.loadConnectedAccounts();
    });
    this.setState({ loadingAccs: true });
    this.closeEditNickname();
  };

  loadConnectedAccounts = () => {
    if (!this.canManageAccounts()) return;

    this.setState({ loadingAccs: true }, async () => {
      try {
        const { data } = await paymentActions.getConnectedAccounts(this.props.auth.site);
        // console.log('loadConnectedAccounts', data[0]);
        this.setState({ loadingAccs: false, connectedAccounts: data });
      } catch (error) {
        console.log('loadConnectedAccounts error', error);
        this.setState({ loadingAccs: false });
      }
    });
  };

  loadTransactions = () => {
    if (!this.canViewTransactions()) return;

    this.setState({ loadingTrans: true }, async () => {
      try {
        const { data } = await paymentActions.getSiteTransactions(this.props.auth.site, DEFAULT_MIN_TIME);
        // console.log('loadTransactions', data, moment(DEFAULT_MIN_TIME).format('DD/MM/YYYY hh:mm a'));
        this.setState({ loadingTrans: false, transactions: data });
      } catch (error) {
        console.log('loadTransactions error', error);
        this.setState({ loadingTrans: false });
      }
    });
  };

  canManageAccounts = () => {
    const { auth } = this.props;
    const result = validateAccess(auth.site, 'managePayments', auth);
    // console.log('canManageAccounts', result);
    return result;
  };

  canViewTransactions = () => {
    const { auth } = this.props;
    const result = this.canManageAccounts() || validateAccess(auth.site, 'viewPayments', auth);
    // console.log('canViewTransactions', result);
    return result;
  };

  sortIsActive = (col) => (col !== this.state.sortColumn ? '' : ' table--columnActive');

  sortByCol = (col) => {
    const { sortColumn, sortDesc } = this.state;
    if (sortColumn === col) {
      this.setState({ sortDesc: !sortDesc });
    } else {
      this.setState({ sortColumn: col, sortDesc: false });
    }
  };

  renderSort = (col) => {
    const { sortColumn, sortDesc } = this.state;
    if (col !== sortColumn) return null;
    return <FontAwesome style={{ marginLeft: 5 }} name={sortDesc ? 'chevron-up' : 'chevron-down'} />;
  };

  renderCreateButton = () => {
    const { loadingAccs, creating } = this.state;
    if (loadingAccs) return null;

    return (
      <a
        onClick={() => {
          if (!creating) this.onCreateConnectedAccount();
        }}
        className="flex flex-row flex-center marginLeft-10 text-brandingColour"
        style={{ cursor: 'pointer' }}
      >
        <FontAwesome style={{ fontSize: 20 }} name="plus-circle" />
        <div className="marginLeft-10 fontSize-16 fontHeavy">{creating ? 'Creating...' : 'Create a Connected Account'}</div>
      </a>
    );
  };

  renderOnboardButton = (acc) => {
    if (acc.onboarded) return null;
    const { onboarding } = this.state;

    return (
      <Button
        buttonType="outlined"
        onClick={() => {
          if (!onboarding) this.onOnboardConnectedAccount(acc.id);
        }}
        isActive
      >
        {onboarding ? 'Onboarding...' : 'Onboard'}
      </Button>
    );
  };

  renderDeleteButton = (acc) => {
    const { deleting } = this.state;

    return (
      <a
        onClick={() => {
          if (!deleting) this.onDeleteConnectedAccount(acc.id);
        }}
        style={{ cursor: 'pointer' }}
      >
        <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8 }} name={deleting ? 'spinner fa-pulse fa-fw' : 'minus-circle'} />
      </a>
    );
  };

  renderConnectedAccounts = () => {
    const { connectedAccounts, sortColumn, sortDesc, loadingAccs } = this.state;
    const source = _.orderBy(connectedAccounts, sortColumn, sortDesc ? 'desc' : 'asc');
    return (
      <Table className="plussTable" striped bordered condensed hover>
        <thead>
          <tr>
            <th style={{ width: 300 }}>Account ID</th>
            <th>Account Name</th>
            <th>Nickname</th>
            <th>Site</th>
            <th style={{ textAlign: 'center' }}>Progress</th>
            <th style={{ width: 150 }} />
            <th style={{ width: 50 }} />
          </tr>
        </thead>
        <tbody>
          {source.map((acc) => {
            return (
              <tr key={acc.id}>
                <td>
                  <a style={{ cursor: 'pointer' }} onClick={() => this.onAccountClick(acc.id)}>
                    {acc.id}
                  </a>
                  {loadingAccs ? <FontAwesome style={{ fontSize: 16, marginLeft: 10 }} name={'spinner fa-pulse fa-fw'} /> : null}
                </td>
                <td>{acc.name}</td>
                <td>
                  {acc.nickname}
                  <a
                    onClick={() => {
                      this.openEditNickname(acc);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8 }} name={'pencil'} />
                  </a>
                </td>
                <td>{acc.site}</td>
                <td style={{ textAlign: 'center' }}>{acc.completion}</td>
                <td>{this.renderOnboardButton(acc)}</td>
                <td>{this.renderDeleteButton(acc)}</td>
              </tr>
            );
          })}
          <tr>
            <td>
              {loadingAccs ? (
                <FontAwesome style={{ fontSize: 20, marginTop: 10 }} name={'spinner fa-pulse fa-fw'} />
              ) : (
                this.renderCreateButton()
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  renderPayments = () => {
    const { transactions, sortColumn, sortDesc } = this.state;
    const source = _.orderBy(transactions, sortColumn, sortDesc ? 'desc' : 'asc');
    return (
      <Table className="plussTable" striped bordered condensed hover>
        <thead>
          <tr>
            <th className={`${this.sortIsActive('User.name')}`} style={{ cursor: 'pointer' }} onClick={() => this.sortByCol('User.name')}>
              User{this.renderSort('User.name')}
            </th>
            <th
              className={`${this.sortIsActive('TransactionType')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => this.sortByCol('TransactionType')}
            >
              Transaction Type{this.renderSort('TransactionType')}
            </th>
            <th
              className={`${this.sortIsActive('Product.type')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => this.sortByCol('Product.type')}
            >
              Item Type{this.renderSort('Product.type')}
            </th>
            <th
              className={`${this.sortIsActive('Product.title')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => this.sortByCol('Product.title')}
            >
              Item Name{this.renderSort('Product.title')}
            </th>
            <th className={`${this.sortIsActive('Item.price')}`} style={{ cursor: 'pointer' }} onClick={() => this.sortByCol('Item.price')}>
              Price{this.renderSort('Item.price')}
            </th>
            <th
              className={`${this.sortIsActive('Item.quantity')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => this.sortByCol('Item.quantity')}
            >
              Quantity{this.renderSort('Item.quantity')}
            </th>
            <th className={`${this.sortIsActive('Fee.amount')}`} style={{ cursor: 'pointer' }} onClick={() => this.sortByCol('Fee.amount')}>
              Fee{this.renderSort('Fee.amount')}
            </th>
            <th className={`${this.sortIsActive('Amount')}`} style={{ cursor: 'pointer' }} onClick={() => this.sortByCol('Amount')}>
              Total{this.renderSort('Amount')}
            </th>
            <th
              className={`${this.sortIsActive('UnixTimestamp')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => this.sortByCol('UnixTimestamp')}
            >
              Created{this.renderSort('UnixTimestamp')}
            </th>
          </tr>
        </thead>
        <tbody>
          {source.map((tran) => {
            const tickets = tran.Item.categories;
            const priceContent = tickets ? tickets.map((t) => <div>{`${t.category}: ${formatCurrency(t.price)}`}</div>) : null;
            const quantityContent = tickets ? tickets.map((t) => <div>{`${t.category}: ${t.quantity}`}</div>) : null;
            return (
              <tr key={tran.RowId}>
                <td>
                  <img
                    style={{ height: 24, width: 24, borderRadius: 12, marginRight: 8 }}
                    src={tran.User.profilePic != null ? tran.User.profilePic : defaultProfileImage}
                    alt="user profilePic"
                  />
                  {tran.User.name}
                </td>
                <td>{tran.TransactionType}</td>
                <td>{tran.Product.type}</td>
                <td>{tran.Product.title}</td>
                <td>{priceContent}</td>
                <td>{quantityContent}</td>
                <td>{tran.Fee && tran.Fee.amount < 0 ? formatCurrency(Math.abs(tran.Fee.amount)) : ''}</td>
                <td>{formatCurrency(tran.Amount)}</td>
                <td>{moment(tran.UnixTimestamp).format('DD-MM-YY HH:mm')}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  renderEditNicknamePopup() {
    if (!this.state.editingNickname) {
      return null;
    }
    return (
      <Popup
        title={`Edit Nickname`}
        minWidth={400}
        maxWidth={600}
        hasPadding
        onClose={this.closeEditNickname}
        buttons={[
          {
            type: 'primary',
            onClick: this.saveEditNickname,
            isActive: this.isReadyToSaveNickname(),
            text: 'Save',
          },
          {
            type: 'tertiary',
            onClick: this.closeEditNickname,
            isActive: true,
            text: 'Cancel',
          },
        ]}
      >
        <GenericInput
          id="nicknameInput"
          type="text"
          label="Nickname"
          placeholder="Primary Account"
          value={this.state.nicknameInput}
          onChange={(e) => this.handleChange(e)}
          className="marginTop-40"
          isRequired
          isValid={this.isReadyToSaveNickname()}
          alwaysShowLabel
        />
      </Popup>
    );
  }

  render() {
    return (
      <div className="pageContainer relative flex flex-column">
        {this.renderEditNicknamePopup()}
        <Header />
        <div className="hub-contentWrapper paymentHub">
          <div className="flex flex-row flex-center marginBottom-32">
            <Text type="hubTitle">Payment by</Text>
            <div className="stripe-image" />
          </div>
          {this.canManageAccounts() ? (
            <div>
              <Text type="hubSubTitle">Connected Account</Text>
              {this.renderConnectedAccounts()}
            </div>
          ) : null}
          {this.canViewTransactions() ? (
            <div>
              <Text type="hubSubTitle">Transactions</Text>
              {this.renderPayments()}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(Payment);
