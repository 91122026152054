import { getCurrentUserSub, authedFunction } from '../session';
import { AWS_UPLOADS_BUCKET, baseUploadsUrl } from '../config';
import { Storage } from 'aws-amplify';
import CanvasImageUploader from '../helper/canvasImageUploader';
import $ from 'jquery';
import { generateImageName, randomString } from '../helper';
import { getUrl } from './helper';

export const fileActions = {
  uploadMediaAsync: async (file, filename) => {
    return new Promise((resolve, reject) => {
      const newFilename = filename.replace(/[^a-z0-9+.]+/gi, '').toLowerCase();
      getCurrentUserSub()
        .then((userId) => {
          const url = `uploads/users/${userId}/public/${randomString()}/${newFilename}`;
          Storage.put(url, file, {
            level: 'public',
            bucket: AWS_UPLOADS_BUCKET,
          })
            .then((res) => {
              console.log('uploaded file', `${baseUploadsUrl}${url}`);
              resolve(`${baseUploadsUrl}${url}`);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  readBase64: async (file) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
        //resolve(reader.result.split(',')[1]);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  },
  compressImage: (file, maxSize, quality, noCompress) => {
    return new Promise((resolve, reject) => {
      if (noCompress) {
        resolve(file);
        return;
      }
      var $canvas = $('<canvas>');
      $canvas.css('display', 'none');
      $('body').append($canvas);

      var canvasUploader = new CanvasImageUploader({
        maxSize: maxSize,
        jpegQuality: quality,
      });

      canvasUploader.readImageToCanvas(file, $canvas, function () {
        canvasUploader.saveCanvasToImageData($canvas[0]);
        console.log(file);
        resolve(fileActions.blobToFile(canvasUploader.getImageData(), file.name));
      });
    });
  },
  blobToFile: (theBlob, fileName) => {
    const file = new File([theBlob], fileName, { lastModified: new Date() });
    console.log(file);
    return file;
  },
  putCanvas: (canvas, filename) => {
    return new Promise((resolve, reject) => {
      const img = canvas.getTrimmedCanvas().toDataURL('image/png');
      const imageData = Buffer.from(img.replace(/^data:image\/\w+;base64,/, ''), 'base64');

      canvas.off();

      const imgName = generateImageName(filename);

      Storage.put(`images/${imgName}.png`, imageData, {
        contentType: 'image/png',
        level: 'public',
      })
        .then((res) => {
          console.log('put the image');
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  getMediaFolders: (site) => {
    // console.log('getMediaFolders', 'site', site);
    const query = { site };
    return authedFunction({
      method: 'GET',
      url: getUrl('media', 'folders', query),
    });
  },
  addMediaFolder: (site, name, published) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('media', 'addFolder'),
      data: {
        site,
        name,
        published,
      },
    });
  },
  updateMediaFolder: (id, site, name, published) => {
    // console.log('updateMediaFolder', 'id', id, 'site', site, 'name', name, 'published', published);
    return authedFunction({
      method: 'POST',
      url: getUrl('media', 'updateFolder'),
      data: {
        id,
        site,
        name,
        published,
      },
    });
  },
  deleteMediaFolder: (id, site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('media', 'deleteFolder'),
      data: {
        id,
        site,
      },
    });
  },
  addImagesToFolder: (id, site, images) => {
    // console.log('addImagesToFolder', 'id', id, 'site', site, 'images', images);
    return authedFunction({
      method: 'POST',
      url: getUrl('media', 'addImages'),
      data: {
        id,
        site,
        images,
      },
    });
  },
  deleteImagesFromFolder: (id, site, images) => {
    // console.log('deleteImagesFromFolder', 'id', id, 'site', site, 'images', images);
    return authedFunction({
      method: 'POST',
      url: getUrl('media', 'deleteImages'),
      data: {
        id,
        site,
        images,
      },
    });
  },
  getMediaLibrary: () => {
    return authedFunction({
      method: 'GET',
      url: 'https://pluss60.pluss60-api.com/media-demo/library',
    });
  },
};
