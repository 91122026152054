import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { usersLoaded } from '../../actions';
import { checkLoggedIn, isTheBest, validateAccess } from '../../session';
import { authActions, userActions, fileActions, typeActions, profileActions } from '../../webapi';
import {
  Button,
  ImageInput,
  DropdownInput,
  RadioButton,
  GenericInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  DatePicker,
  OptionsSection,
  AddOption,
  P60Icon,
  FileInput,
  CheckBox,
  MoreMenu,
  SVGIcon,
  Popup,
  Attachment,
  Tag,
} from '../../components';
import { safeReadParams, get1400, getFirstName, getFileName, toParagraphed } from '../../helper';
import { COLOUR_TANGERINE, COLOUR_BRANDING_MAIN, COLOUR_RED, COLOUR_BRANDING_OFF, COLOUR_DUSK_LIGHT } from '../../js';
import { defaultProfileImage, requiresEmail, baseClient } from '../../config';
import UserLinkInfo from './LinkUsers/User';
import relationships from '../../json/relationships.json';
import { Text } from '../../components/text';

class User extends Component {
  state = {
    showDate: false,
    loading: true,
    loadError: false,
    error: null,
    accountError: null,
    site: null,
    locations: [],
    types: [],
    typesLoaded: [],
    userId: null,
    newPassword: '',
    user: {
      profilePic: defaultProfileImage,
      displayName: '',
      email: '',
      phoneNumber: '',
      site: '',
      type: '',
      birthday: moment('1900-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD'),
      birthdayText: moment('01/01/1900', 'DD/MM/YYYY').format('DD/MM/YYYY'),
      unit: '',
    },
    newUser: {
      profilePic: defaultProfileImage,
      displayName: '',
      email: '',
      phoneNumber: '',
      site: '',
      type: '',
      birthday: moment('1900-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD'),
      birthdayText: moment('01/01/1900', 'DD/MM/YYYY').format('DD/MM/YYYY'),
      unit: '',
    },
    uploadingImage: false,
    showWarnings: false,
    selectedOption: 'emergencyContacts',
    emergencyContacts: [],
    deletedECs: [],
    pdFileNameInput: '',
    pdFileInput: '',
    personalDocuments: [],
    personalNotes: [],
    pnTitleInput: '',
    pnTextInput: '',
    pnFileInput: '',
    availableTags: {},
    userTags: {},

    now: moment.utc(),
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    this.setState({ loading: true, site: this.props.auth.site });
  }

  componentDidMount() {
    if (!validateAccess(this.props.auth.site, 'userManagement', this.props.auth, true)) {
      this.props.history.push('/mastermenu');
    } else {
      this.getSites();
      this.getUserTypes();
      this.getECs();
      this.onNewProps(this.props);
    }
  }

  getSites() {
    typeActions
      .getSites()
      .then((res) => {
        if (
          !_.some(res.data, (s) => {
            return s.Id === 'hq';
          })
        ) {
          res.data.push({
            Id: 'hq',
            active: true,
            siteName: 'HQ',
          });
        }
        if (
          isTheBest(this.props.auth, false) &&
          !_.some(res.data, (s) => {
            return s.Id === 'plussSpace';
          })
        ) {
          res.data.push({
            Id: 'plussSpace',
            active: true,
            siteName: 'PlussSpace',
          });
        }
        res.data.forEach((e) => {
          e.Title = e.siteName;
          e.Key = e.Id;
        });
        this.setState({
          locations: res.data,
        });
      })
      .catch((error) => {});
  }

  getUserTypes(site) {
    const siteToGet = site || (this.props.auth.site === 'hq' ? 'all' : this.props.auth.site);
    if (_.includes(this.state.typesLoaded, siteToGet)) {
      return;
    }
    typeActions
      .getUserTypes(siteToGet)
      .then((res) => {
        res.data.forEach((e) => {
          e.Title = e.displayName;
          e.Key = e.typeName;
        });
        const newTypes = _.uniqBy(_.union(this.state.types, res.data), (t) => {
          return `${t.site}_${t.typeName}`;
        });
        const newTypesLoaded = this.state.typesLoaded;
        newTypesLoaded.push(siteToGet);
        this.setState({
          types: _.uniqBy(newTypes, (t) => {
            return `${t.site}_${t.typeName}`;
          }),
          typesLoaded: newTypesLoaded,
        });
      })
      .catch((error) => {
        console.log('some error', error);
      });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState({ newUser: { ...this.state.newUser, ...stateChange } });
  }

  handleOnPhoneChange(value) {
    this.setState({ newUser: { ...this.state.newUser, phoneNumber: `+${value}` } });
  }

  handlePasswordChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleDateTextChange(value) {
    const newU = { birthdayText: value };
    const m = moment(value, 'DD/MM/YYYY');

    if (m.isValid() && m.year() > 1900) {
      newU.birthday = m.format('YYYY-MM-DD');
    }
    this.setState({
      newUser: { ...this.state.newUser, ...newU },
    });
  }

  handleDateChange(date) {
    var stateChange = {
      birthday: date,
      birthdayText: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    };
    this.setState({ newUser: { ...this.state.newUser, ...stateChange }, showDate: false });
  }

  sendWelcomeEmail() {
    this.setState({
      sendingWelcomeEmail: true,
    });
    userActions.sendWelcomeEmail(this.props.auth.site, this.state.user.Id).then((res) => {
      const newUser = this.state.user;
      newUser.WelcomeStatus = res.data.WelcomeStatus;
      this.setState({
        user: newUser,
        sendingWelcomeEmail: false,
      });
    });
  }

  sendWelcomeText() {
    this.setState({
      sendingWelcomeText: true,
    });
    userActions.sendWelcomeText(this.props.auth.site, this.state.user.Id).then((res) => {
      const newUser = this.state.user;
      newUser.WelcomeStatus = res.data.WelcomeStatus;
      this.setState({
        user: newUser,
        sendingWelcomeText: false,
      });
    });
  }

  getTypeTitle(role) {
    const typeObject = _.find(this.getTypeOptions(role.site), (t) => {
      return t.typeName === role.type && t.site === role.site;
    });
    if (typeObject) {
      return typeObject.displayName;
    }
    return '';
  }

  getSiteTitle(site) {
    const siteObject = _.find(this.state.locations, (t) => {
      return t.Id === site;
    });
    if (siteObject) {
      return siteObject.siteName;
    }
    return site;
  }

  onNewProps(props) {
    const userId = decodeURIComponent(safeReadParams(props, 'userId'));
    if (userId != null) {
      this.setState({ userId });
      this.fetchUser(userId);
      this.getECs(userId);
      //this.parseUser(userId);
    }
  }

  fetchUser(userId) {
    this.setState({ loading: true, loadError: false });
    userActions
      .fetchUser(this.props.auth.site, userId || this.state.userId)
      .then((res) => {
        const userData = _.cloneDeep(res.data.user);
        if (!_.isUndefined(userData.email) && userData.email === 'empty') {
          delete userData.email;
        }
        if (!_.isUndefined(userData.phoneNumber) && userData.phoneNumber === 'empty') {
          delete userData.phoneNumber;
        }
        this.parseUser(userData);
      })
      .catch((error) => {
        console.log(error);
        console.log('fail on action call');
        this.setState({ loading: false, loadError: true });
      });
  }

  parseUser(user, save) {
    user.birthdayText = moment(user.birthday).format('DD/MM/YYYY');
    if (!user.Roles) {
      user.Roles = [
        {
          site: user.site,
          type: user.type,
        },
      ];
    }
    this.setState({
      updating: false,
      loading: false,
      error: save ? 'Save successful' : null,
      loadError: false,
      user: { ...this.state.user, ...user },
      newUser: { ...this.state.newUser, ...user },
    });

    user.Roles.forEach((role) => {
      this.getUserTypes(role.site);
    });
  }

  selectOption = (o) => {
    switch (o) {
      case 'emergencyContacts':
        this.getECs(this.state.userId);
        break;
      case 'personalDocuments':
        this.getPDs(this.state.userId);
        break;
      case 'personalNotes':
        this.getPNs(this.state.userId);
        break;
      case 'userTags':
        this.getUserTags(this.state.userId);
        this.getAvailableUserTags();
        break;
      default:
        break;
    }
    this.setState({
      selectedOption: o,
      pdMenuOpen: null,
    });
  };

  getECs = async (userId) => {
    return new Promise((resolve) => {
      if (!userId) return resolve();

      profileActions.getECs(userId).then((res) => {
        this.setState({
          emergencyContacts: res.data,
        });
        resolve();
      });
    });
  };

  getPDs = async (userId) => {
    return new Promise((resolve) => {
      if (!userId) return resolve();

      profileActions.getPDs(userId).then((res) => {
        console.log(res.data);
        this.setState({
          personalDocuments: res.data,
        });
        resolve();
      });
    });
  };

  getPNs = async (userId) => {
    return new Promise((resolve) => {
      if (!userId) return resolve();

      profileActions.getPNs(userId).then((res) => {
        console.log(res.data);
        this.setState({
          personalNotes: res.data,
        });
        resolve();
      });
    });
  };

  getUserTags = async (userId) => {
    return new Promise((resolve) => {
      if (!userId) return resolve();

      profileActions.getUserTagsByUser(userId).then((res) => {
        this.setState({
          userTags: _.groupBy(res.data, (t) => {
            return t.Site;
          }),
        });
        resolve();
      });
    });
  };

  getAvailableUserTags = async () => {
    return new Promise((resolve) => {
      if (_.isEmpty(this.state.user.Roles)) {
        return;
      }
      this.state.user.Roles.forEach((role) => {
        profileActions.getUserTagsBySite(role.site).then((res) => {
          console.log(res.data);
          this.setState({
            availableTags: { ...this.state.availableTags, [role.site]: res.data },
          });
          resolve();
        });
      });
    });
  };

  getManageableSites = (permission) => {
    return _.map(
      _.filter(this.state.user.Roles, (role) => {
        return validateAccess(role.site, permission, this.props.auth);
      }),
      (role) => {
        return role.site;
      },
    );
  };

  startAddingTag = (site) => {
    this.setState({
      addingTag: site,
      tagInput: '',
    });
  };

  saveTag = () => {
    if (_.isEmpty(this.state.tagInput)) {
      return;
    }

    profileActions.addUserTag(this.state.addingTag, this.state.tagInput).then((res) => {
      this.setState({
        availableTags: {
          ...this.state.availableTags,
          [res.data.Site]: [...(this.state.availableTags[res.data.Site] || []), res.data],
        },
      });
    });

    this.setState({
      addingTag: null,
      tagInput: '',
    });
  };

  startEditingTag = (site) => {
    this.setState({
      editingTag: site,
    });
  };

  deleteTag = (tag) => {
    if (!window.confirm('Are you sure you want to delete that tag? This will remove the tag from all tagged users.')) {
      return;
    }
    profileActions.deleteUserTag(tag.Id);

    this.setState({
      availableTags: {
        ...this.state.availableTags,
        [tag.Site]: _.filter(this.state.availableTags[tag.Site], (t) => {
          return t.Id !== tag.Id;
        }),
      },
    });
  };

  attachTag = (tag) => {
    profileActions
      .attachUserTag(tag.Id, this.state.userId)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      userTags: {
        ...this.state.userTags,
        [tag.Site]: [...(this.state.userTags[tag.Site] || []), tag],
      },
    });
  };

  detachTag = (tag) => {
    profileActions
      .detachUserTag(tag.Id, this.state.userId)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      userTags: {
        ...this.state.userTags,
        [tag.Site]: _.filter(this.state.userTags[tag.Site], (t) => {
          return t.Id !== tag.Id;
        }),
      },
    });
  };

  saveECs = () => {
    return new Promise((resolve) => {
      const promises = [];
      this.state.emergencyContacts.forEach((ec) => {
        if (!ec.Id) {
          //add
          promises.push(profileActions.addEC(this.state.userId, ec.Name, ec.Phone, ec.Email, ec.Relationship));
        } else if (ec.Changed) {
          //edit
          promises.push(profileActions.editEC(ec.Id, this.state.userId, ec.Name, ec.Phone, ec.Email, ec.Relationship));
        }
      });
      this.state.deletedECs.forEach((ec) => {
        //delete
        promises.push(profileActions.deleteEC(ec.Id));
      });
      Promise.all(promises).then(async () => {
        await this.getECs();
        resolve();
      });
    });
  };

  getRelationshipTitle = (key) => {
    const relationship = _.find(relationships, (r) => {
      return r.Key === key;
    });
    if (relationship) {
      return relationship.Title;
    }
    return '';
  };

  selectECRelationship = (index, key) => {
    const newECs = [...this.state.emergencyContacts];
    newECs[index].Relationship = key;
    newECs[index].Changed = true;
    this.setState({
      emergencyContacts: newECs,
    });
  };

  addEmergencyContact = () => {
    const newECs = [...this.state.emergencyContacts];
    newECs.push({
      Name: '',
      Phone: '',
      Email: '',
    });
    this.setState({
      emergencyContacts: newECs,
    });
  };

  removeEC = (index) => {
    const newECs = [...this.state.emergencyContacts];
    const newDeletedECs = [...this.state.deletedECs];
    if (newECs[index].Id) {
      newDeletedECs.push(newECs[index]);
    }
    newECs.splice(index, 1);
    this.setState({
      emergencyContacts: newECs,
      deletedECs: newDeletedECs,
    });
  };

  handleECChange = (event, key, index) => {
    const newECs = [...this.state.emergencyContacts];
    newECs[index][key] = event.target.value;
    newECs[index].Changed = true;
    this.setState({
      emergencyContacts: newECs,
    });
  };

  openPDMenu = (index) => {
    if (this.state.pdMenuOpen === index) {
      this.setState({
        pdMenuOpen: null,
      });
    } else {
      this.setState({
        pdMenuOpen: index,
      });
    }
  };

  deletePD = (pd) => {
    if (!window.confirm('Are you sure you want to delete that document?')) {
      this.setState({
        pdMenuOpen: null,
      });
      return;
    }
    profileActions.deletePD(pd.Id);
    const newPDs = _.filter(this.state.personalDocuments, (doc) => {
      return doc.Id !== pd.Id;
    });
    this.setState({
      personalDocuments: newPDs,
      pdMenuOpen: null,
    });
  };

  openEditPD = (pd) => {
    this.pdFileInput && this.pdFileInput.getWrappedInstance().setValue(pd.Url);
    this.setState({
      savingPDFile: false,
      pdFileNameInput: pd.Name,
      pdFileInput: pd.Url,
      pdFilePublic: pd.IsPublic,
      editingPD: pd.Id,
      pdMenuOpen: null,
    });
  };

  cancelEditPDFile = () => {
    this.pdFileInput && this.pdFileInput.getWrappedInstance().setValue('');
    this.setState({
      savingPDFile: false,
      pdFileNameInput: '',
      pdFileInput: '',
      pdFilePublic: false,
      editingPD: null,
    });
  };

  setPDFile = (url) => {
    this.setState({
      pdFileInput: url,
      pdFileNameInput: getFileName(url),
    });
  };

  isReadyToSavePDFile = () => {
    if (_.isEmpty(this.state.pdFileInput)) {
      return false;
    }
    if (_.isEmpty(this.state.pdFileNameInput)) {
      return false;
    }
    if (this.state.savingPDFile) {
      return false;
    }
    return true;
  };

  savePDFile = () => {
    if (!this.isReadyToSavePDFile()) {
      return;
    }
    this.setState({
      savingPDFile: true,
    });
    if (this.state.editingPD) {
      profileActions
        .editPD(this.state.editingPD, this.state.userId, this.state.pdFileNameInput, this.state.pdFileInput, this.state.pdFilePublic)
        .then((res) => {
          const newPDs = [...this.state.personalDocuments];
          const newPDindex = _.findIndex(newPDs, (pd) => {
            return pd.Id === res.data.Id;
          });
          newPDs[newPDindex] = res.data;
          this.pdFileInput && this.pdFileInput.getWrappedInstance().setValue('');
          this.setState({
            savingPDFile: false,
            pdFileNameInput: '',
            pdFileInput: '',
            pdFilePublic: false,
            personalDocuments: newPDs,
            editingPD: null,
          });
        });
    } else {
      profileActions.addPD(this.state.userId, this.state.pdFileNameInput, this.state.pdFileInput, this.state.pdFilePublic).then((res) => {
        this.pdFileInput && this.pdFileInput.getWrappedInstance().setValue('');
        this.setState({
          savingPDFile: false,
          pdFileNameInput: '',
          pdFileInput: '',
          pdFilePublic: false,
          personalDocuments: [res.data, ...this.state.personalDocuments],
          editingPD: null,
        });
      });
    }
  };

  openPNMenu = (index) => {
    if (this.state.pnMenuOpen === index) {
      this.setState({
        pnMenuOpen: null,
      });
    } else {
      this.setState({
        pnMenuOpen: index,
      });
    }
  };

  deletePN = (pn) => {
    if (!window.confirm('Are you sure you want to delete that note?')) {
      this.setState({
        pnMenuOpen: null,
      });
      return;
    }
    profileActions.deletePN(pn.Id);
    const newPNs = _.filter(this.state.personalNotes, (doc) => {
      return doc.Id !== pn.Id;
    });
    this.setState({
      personalNotes: newPNs,
      pnMenuOpen: null,
    });
  };

  openEditPN = (pn) => {
    let url = null;
    if (!_.isEmpty(pn.Attachments)) {
      url = pn.Attachments[0].Url;
    }
    this.pnFileInput && this.pnFileInput.getWrappedInstance().setValue(url);
    this.setState({
      savingPNFile: false,
      pnTitleInput: pn.Title,
      pnTextInput: pn.Text,
      pnFileInput: url,
      editingPN: pn.Id,
      pnMenuOpen: null,
    });
  };

  cancelEditPNFile = () => {
    this.pnFileInput && this.pnFileInput.getWrappedInstance().setValue('');
    this.setState({
      savingPNFile: false,
      pnTitleInput: '',
      pnTextInput: '',
      pnFileInput: '',
      editingPN: null,
    });
  };

  viewPN = (pn) => {
    this.setState({
      viewingPN: pn,
    });
  };

  setPNFile = (url) => {
    this.setState({
      pnFileInput: url,
    });
  };

  isValidPNTitle = () => {
    return !_.isEmpty(this.state.pnTitleInput);
  };

  isValidPNText = () => {
    return !_.isEmpty(this.state.pnTextInput);
  };

  isReadyToSavePN = () => {
    if (!this.isValidPNTitle()) {
      return false;
    }
    if (!this.isValidPNText()) {
      return false;
    }
    if (this.state.savingPNFile) {
      return false;
    }
    return true;
  };

  savePNFile = () => {
    if (!this.isReadyToSavePN()) {
      return;
    }
    this.setState({
      savingPNFile: true,
    });
    const attachments = [];
    if (!_.isEmpty(this.state.pnFileInput)) {
      attachments.push({
        Url: this.state.pnFileInput,
      });
    }
    if (this.state.editingPN) {
      profileActions.editPN(this.state.editingPN, this.state.pnTitleInput, this.state.pnTextInput, attachments).then((res) => {
        const newPNs = [...this.state.personalNotes];
        const newPNindex = _.findIndex(newPNs, (pn) => {
          return pn.Id === res.data.Id;
        });
        newPNs[newPNindex] = res.data;
        this.pnFileInput && this.pnFileInput.getWrappedInstance().setValue('');
        this.setState({
          savingPNFile: false,
          pnTitleInput: '',
          pnTextInput: '',
          pnFileInput: '',
          personalNotes: newPNs,
          editingPN: null,
        });
      });
    } else {
      profileActions.addPN(this.state.userId, this.state.pnTitleInput, this.state.pnTextInput, attachments).then((res) => {
        this.pnFileInput && this.pnFileInput.getWrappedInstance().setValue('');
        this.setState({
          savingPNFile: false,
          pnTitleInput: '',
          pnTextInput: '',
          pnFileInput: '',
          personalNotes: [res.data, ...this.state.personalNotes],
          editingPN: null,
        });
      });
    }
  };

  renderEmergencyContact(ec, index) {
    return (
      <div className="emergencyContact" key={index}>
        <Text type="formQuestionNumber">{index + 1}</Text>
        <div className="emergencyContact_content">
          <GenericInput
            id={`emergencyContactName${index}`}
            type="text"
            label="Contact Name"
            placeholder="Enter name"
            value={ec.Name}
            onChange={(e) => this.handleECChange(e, 'Name', index)}
            alwaysShowLabel
            style={{ marginRight: 16, marginBottom: 0, flex: 1 }}
          />
          <GenericInput
            id={`emergencyContactNumber${index}`}
            type="text"
            label="Phone Number"
            placeholder="0411222333"
            value={ec.Phone}
            onChange={(e) => this.handleECChange(e, 'Phone', index)}
            alwaysShowLabel
            style={{ marginRight: 16, marginBottom: 0, maxWidth: 100 }}
          />
          <GenericInput
            id={`emergencyContactEmail${index}`}
            type="text"
            label="Email"
            placeholder="example@domain.com"
            value={ec.Email}
            onChange={(e) => this.handleECChange(e, 'Email', index)}
            alwaysShowLabel
            style={{ marginRight: 16, marginBottom: 0, flex: 1 }}
          />
          <DropdownInput
            id={`emergencyContactRelationship${index}`}
            label="Relationship"
            value={this.getRelationshipTitle(ec.Relationship)}
            options={this.getRelationshipOptions()}
            onSelect={(key) => {
              this.selectECRelationship(index, key);
            }}
            alwaysShowLabel
            style={{ marginRight: 16, marginBottom: 0, maxWidth: 120 }}
          />
          <P60Icon
            className="multichoiceoption_remove"
            icon="remove-circle"
            onClick={() => {
              this.removeEC(index);
            }}
          />
        </div>
      </div>
    );
  }

  renderEmergencyContacts() {
    return (
      <div className="emergencyContacts">
        {this.state.emergencyContacts.map((ec, index) => this.renderEmergencyContact(ec, index))}
        <AddOption onClick={this.addEmergencyContact} text={'Add New Emergency Contact'} />
      </div>
    );
  }

  renderPersonalDocument(pd, index) {
    return (
      <div className="personalDocument">
        <SVGIcon
          colour={COLOUR_DUSK_LIGHT}
          icon="more15"
          className="personalDocument_moreIcon"
          onClick={() => {
            this.openPDMenu(index);
          }}
        />
        <a href={pd.Url} target="_blank" className="personalDocument_link">
          <FontAwesome name="download" className="personalDocument_link_icon" />
          <p className="personalDocument_link_text">Download</p>
        </a>
        <p className="personalDocument_title">{pd.Name}</p>
        {this.state.pdMenuOpen === index && (
          <MoreMenu
            options={[
              {
                key: 'edit',
                text: 'Edit',
                onPress: () => {
                  this.openEditPD(pd);
                },
              },
              {
                key: 'delete',
                text: 'Delete',
                onPress: () => {
                  this.deletePD(pd);
                },
              },
            ]}
          />
        )}
      </div>
    );
  }

  renderPersonalDocuments() {
    return (
      <div className="personalDocuments">
        <div className="personalDocuments_left">
          <GenericInput
            id={`pdFileNameInput`}
            type="text"
            label="File Name"
            placeholder=""
            value={this.state.pdFileNameInput}
            onChange={(e) => {
              this.setState({ pdFileNameInput: e.target.value });
            }}
            alwaysShowLabel
          />
          <FileInput
            ref={(ref) => {
              this.pdFileInput = ref;
            }}
            style={{ height: 120 }}
            refreshCallback={this.setPDFile}
            accept="application/pdf"
            simpleStyle
          />
          <CheckBox
            className="marginTop-16"
            label={'Publish file to user in app'}
            isActive={this.state.pdFilePublic}
            onChange={() => {
              this.setState({ pdFilePublic: !this.state.pdFilePublic });
            }}
          />
          <div className="marginTop-16 flex-reverse">
            <Button inline buttonType="primary" onClick={this.savePDFile} isActive={this.isReadyToSavePDFile()}>
              Save
            </Button>
            {this.state.editingPD && (
              <Button inline buttonType="tertiary" onClick={this.cancelEditPDFile} isActive={!this.savingPDFile}>
                Cancel
              </Button>
            )}
          </div>
        </div>
        <div className="personalDocuments_fill">
          <Text type="formLabel">List of files</Text>
          <div className="personalDocuments_list">
            {this.state.personalDocuments.map((pd, index) => {
              return this.renderPersonalDocument(pd, index);
            })}
          </div>
        </div>
      </div>
    );
  }

  renderPersonalNote(pn, index) {
    return (
      <div className="personalNote">
        <SVGIcon
          colour={COLOUR_DUSK_LIGHT}
          icon="more15"
          className="personalNote_moreIcon"
          onClick={() => {
            this.openPNMenu(index);
          }}
        />
        <Button
          className="personalNote_button"
          buttonType="outlined"
          isActive
          onClick={() => {
            this.viewPN(pn);
          }}
          narrow
        >
          View
        </Button>
        <p className="personalNote_title">{pn.Title}</p>
        {this.state.pnMenuOpen === index && (
          <MoreMenu
            options={[
              {
                key: 'edit',
                text: 'Edit',
                onPress: () => {
                  this.openEditPN(pn);
                },
              },
              {
                key: 'delete',
                text: 'Delete',
                onPress: () => {
                  this.deletePN(pn);
                },
              },
            ]}
          />
        )}
      </div>
    );
  }

  renderPersonalNotes() {
    return (
      <div className="personalNotes">
        <div className="personalNotes_left">
          <GenericInput
            id={`pnTitleInput`}
            type="text"
            label="Title"
            placeholder="Enter title"
            value={this.state.pnTitleInput}
            onChange={(e) => {
              this.setState({ pnTitleInput: e.target.value });
            }}
            alwaysShowLabel
            isRequired
            isValid={this.isValidPNTitle()}
          />
          <GenericInput
            id={`pnTextInput`}
            type="textarea"
            label="Content"
            placeholder="Enter content"
            value={this.state.pnTextInput}
            onChange={(e) => {
              this.setState({ pnTextInput: e.target.value });
            }}
            inputStyle={{ minHeight: 80 }}
            alwaysShowLabel
            isRequired
            isValid={this.isValidPNText()}
          />
          <FileInput
            ref={(ref) => {
              this.pnFileInput = ref;
            }}
            style={{ height: 120 }}
            refreshCallback={this.setPNFile}
            accept="application/pdf"
            simpleStyle
          />
          <div className="marginTop-16 flex-reverse">
            <Button inline buttonType="primary" onClick={this.savePNFile} isActive={this.isReadyToSavePN()}>
              Save
            </Button>
            {this.state.editingPN && (
              <Button inline buttonType="tertiary" onClick={this.cancelEditPNFile} isActive={!this.savingPNFile}>
                Cancel
              </Button>
            )}
          </div>
        </div>
        <div className="personalNotes_fill">
          <Text type="formLabel">List of notes</Text>
          <div className="personalNotes_list">
            {this.state.personalNotes.map((pn, index) => {
              return this.renderPersonalNote(pn, index);
            })}
          </div>
        </div>
      </div>
    );
  }

  renderPNPopup() {
    if (!this.state.viewingPN) {
      return null;
    }
    return (
      <Popup
        onClose={() => {
          this.viewPN(null);
        }}
        title={this.state.viewingPN.Title}
        subtitle={toParagraphed(this.state.viewingPN.Text)}
        hasPadding
        minWidth={500}
        maxWidth={500}
      >
        {(this.state.viewingPN.Attachments || []).map((a, i) => {
          return (
            <div className="marginTop-16" key={i}>
              <Attachment source={a.Url} title={getFileName(a.Url)} />
            </div>
          );
        })}
      </Popup>
    );
  }

  renderSiteAssignedTags(site) {
    const tags = this.state.userTags[site] || [];
    return (
      <div key={site} className="userTagsSite">
        <Text type="formLabelSmall" className="font-bold userTagsSite_title">
          {this.getSiteTitle(site)}
        </Text>
        <div className="userTagsSite_tags">
          {_.isEmpty(tags) && (
            <Text type="body" className="text-italic">
              No tags yet
            </Text>
          )}
          {tags.map((t) => {
            return (
              <Tag
                key={t.Id}
                className="marginLeft-16"
                text={t.Title}
                rightIcon="remove"
                rightClick={() => {
                  this.detachTag(t);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }

  renderSiteAvailableTags(site, canManage) {
    const attachedTags = this.state.userTags[site] || [];
    const tags = _.filter(this.state.availableTags[site] || [], (t) => {
      return !_.some(attachedTags, (at) => {
        return at.Id === t.Id;
      });
    });
    const isEditingThisTag = this.state.editingTag === site;
    return (
      <div key={site} className="userTagsAvailableSite">
        <div className="flex flex-between flex-center">
          <Text type="formLabelSmall" className="font-bold">
            {this.getSiteTitle(site)}
          </Text>
          {canManage && this.state.editingTag !== site && (
            <div
              className="iconTextButton iconTextButton-action"
              onClick={() => {
                this.startEditingTag(site);
              }}
            >
              <FontAwesome name="pencil" className="iconTextButton_icon" />
              <p className="iconTextButton_text">Edit Tags</p>
            </div>
          )}
          {canManage && isEditingThisTag && (
            <Button
              inline
              buttonType="primary"
              onClick={() => {
                this.startEditingTag(undefined);
              }}
              isActive
              narrow
            >
              Save
            </Button>
          )}
        </div>
        <div className="userTagsAvailableSite_tags">
          {canManage && !this.state.addingTag && !isEditingThisTag && (
            <Tag
              className="tag-outlined tag-minSize"
              centerClass="tag_center-spaceAround"
              text="Add Tag"
              onClick={() => {
                this.startAddingTag(site);
              }}
              leftIcon="plus"
              leftIconStyle={{ paddingTop: 2 }}
            />
          )}
          {this.state.addingTag === site && !isEditingThisTag && (
            <Tag className="tag-outlined tag-minSize" rightIcon="check-circle" rightIconStyle={{ fontSize: 16 }} rightClick={this.saveTag}>
              <GenericInput
                value={this.state.tagInput}
                id={`tagInput`}
                onChange={(e) => {
                  this.setState({ tagInput: e.target.value });
                }}
                autofocus
                className="tag_input"
              />
            </Tag>
          )}
          {tags.map((t) => {
            return (
              <Tag
                key={t.Id}
                className="marginLeft-16 marginTop-5 marginBottom-5"
                text={t.Title}
                onClick={
                  isEditingThisTag
                    ? undefined
                    : () => {
                        this.attachTag(t);
                      }
                }
                rightIcon={isEditingThisTag ? 'trash' : undefined}
                rightClick={
                  isEditingThisTag
                    ? () => {
                        this.deleteTag(t);
                      }
                    : undefined
                }
              />
            );
          })}
        </div>
      </div>
    );
  }

  renderUserTags() {
    const manageableSites = this.getManageableSites('userTagManagement');
    const assignableSites = _.sortBy(_.uniq([...manageableSites, ...this.getManageableSites('userTagAssignment')]), (site) => {
      return this.getSiteTitle(site);
    });
    return (
      <div className="optionsContent_bottom">
        <Text type="formLabel">Assigned User Tags</Text>
        {assignableSites.map((site) => {
          return this.renderSiteAssignedTags(site);
        })}
        <Text type="formLabel" className="marginTop-32">
          Available User Tags
        </Text>
        {assignableSites.map((site) => {
          return this.renderSiteAvailableTags(site, _.includes(manageableSites, site));
        })}
      </div>
    );
  }

  renderSelectedOption() {
    switch (this.state.selectedOption) {
      case 'emergencyContacts':
        return this.renderEmergencyContacts();
      case 'personalDocuments':
        return this.renderPersonalDocuments();
      case 'personalNotes':
        return this.renderPersonalNotes();
      case 'userTags':
        return this.renderUserTags();
      default:
        return null;
    }
  }

  renderOptionsSection() {
    const options = [
      {
        key: 'emergencyContacts',
        icon: 'exclamation',
        text: 'Emergency Contacts',
      },
      {
        key: 'personalDocuments',
        icon: 'folder',
        text: 'Documents',
      },
      {
        key: 'personalNotes',
        icon: 'pencil-o',
        text: 'Notes',
      },
    ];
    if (!_.isEmpty(this.getManageableSites('userTagManagement')) || !_.isEmpty(this.getManageableSites('userTagAssignment'))) {
      options.push({
        key: 'userTags',
        icon: 'usergroup',
        text: 'User Tags',
      });
    }
    return (
      <OptionsSection options={options} selected={this.state.selectedOption} selectOption={this.selectOption}>
        {this.renderSelectedOption()}
      </OptionsSection>
    );
  }

  renderThingy() {
    if (this.state.loadError) {
      return (
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <div style={{ color: COLOUR_TANGERINE, fontSize: 20 }}>Error fetching User</div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div onClick={this.fetchUser.bind(this, this.state.userId)} style={styles.retry}>
              RETRY
            </div>
          </div>
        </div>
      );
    }
    if (this.state.loading) {
      return (
        <div className="padding-60 paddingVertical-40" style={{ textAlign: 'center' }}>
          <FontAwesome style={{ fontSize: 30, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />
        </div>
      );
    }
    if (this.state.updating) {
      return (
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <div style={{ color: COLOUR_BRANDING_MAIN, fontSize: 20 }}>Saving user data...</div>
        </div>
      );
    }
    return (
      <div>
        <div style={{ paddingBottom: 40 }}>
          {this.renderBasicInfo()}
          {this.renderAccountInfo()}
        </div>
        {this.renderOptionsSection()}
      </div>
    );
  }

  selectSite(index, key, e) {
    const newRoles = _.cloneDeep(this.state.newUser.Roles);
    const previousSite = newRoles[index].site;
    newRoles[index].site = key;
    if (previousSite !== key) {
      this.getUserTypes(key);
      newRoles[index].type = '';
      this.setState({
        newUser: { ...this.state.newUser, Roles: newRoles },
      });
    }
  }

  addSite() {
    const newRoles = _.cloneDeep(this.state.newUser.Roles);
    newRoles.push({
      site: '',
      type: '',
    });
    this.setState({
      newUser: { ...this.state.newUser, Roles: newRoles },
    });
  }

  removeSite(index) {
    this.state.newUser.Roles.splice(index, 1);
    const newRoles = _.cloneDeep(this.state.newUser.Roles);
    this.setState({
      newUser: { ...this.state.newUser, Roles: newRoles },
    });
  }

  selectType(index, key, e) {
    const newRoles = _.cloneDeep(this.state.newUser.Roles);
    newRoles[index].type = key;
    this.setState({
      newUser: { ...this.state.newUser, Roles: newRoles },
    });
  }

  setImage(image) {
    this.setState({
      newUser: { ...this.state.newUser, profilePic: get1400(image) },
    });
  }

  getSiteOptions(site) {
    const options = _.filter(this.state.locations, (s) => {
      return (
        !_.some(this.state.newUser.Roles, (r) => {
          return r.site === s.Id;
        }) || s.Id === site
      );
    });
    return options;
  }

  getRelationshipOptions() {
    return _.map(relationships, (r) => {
      return {
        Title: r.Title,
        Key: r.Key,
      };
    });
  }

  getTypeOptions(site) {
    const options = _.filter(this.state.types, (t) => {
      return t.site === site;
    });
    if (validateAccess('hq', 'master', this.props.auth)) {
      if (
        !_.some(options, (t) => {
          return t.typeName === 'master';
        })
      ) {
        options.push({
          typeName: 'master',
          displayName: 'Master',
          Title: 'Master',
          Key: 'master',
          site: site,
        });
      }
    } else {
      const index = _.findIndex(options, (t) => {
        return t.typeName === 'master';
      });
      if (index > -1) {
        options.splice(index, 1);
      }
    }
    return options;
  }

  validateRoles() {
    if (_.isEmpty(this.state.newUser.Roles)) {
      return false;
    }
    let anyInvalid = false;
    this.state.newUser.Roles.forEach((r) => {
      if (_.isEmpty(r.site) || _.isEmpty(r.type)) {
        anyInvalid = true;
      }
    });
    return !anyInvalid;
  }

  validateInfoForm() {
    if (_.isEmpty(this.state.newUser.displayName)) {
      return false;
    }
    if (requiresEmail && _.isEmpty(this.state.newUser.email)) {
      return false;
    }
    if (!this.validateRoles()) {
      return false;
    }
    if (!this.state.newUser.type) {
      return false;
    }
    return true;
  }

  validateAccountForm() {
    if (requiresEmail && _.isEmpty(this.state.newUser.email)) {
      return false;
    }
    return true;
  }

  isAResident() {
    return _.some(this.state.newUser.Roles, (r) => {
      return _.some(this.state.types, (t) => {
        return r.site === t.site && r.type === t.typeName && t.category === 'resident';
      });
    });
  }

  renderAddRole() {
    if (!validateAccess('hq', 'userManagement', this.props.auth)) {
      return null;
    }
    return (
      <div className="user_addSite" onClick={this.addSite.bind(this)}>
        Add additional site
      </div>
    );
  }

  renderRole(role, index) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
        <DropdownInput
          id="siteSelect"
          label="Site"
          placeholder="Site"
          value={this.getSiteTitle(role.site)}
          options={this.getSiteOptions(role.site)}
          onSelect={this.selectSite.bind(this, index)}
          style={{ marginRight: 16, marginBottom: 0 }}
          disabled={!validateAccess('hq', 'userManagement', this.props.auth)}
        />
        <DropdownInput
          id="typeSelect"
          label="Type"
          placeholder="Type"
          value={this.getTypeTitle(role)}
          options={this.getTypeOptions(role.site)}
          onSelect={this.selectType.bind(this, index)}
          style={{ marginBottom: 0 }}
          disabled={
            !validateAccess('hq', 'userManagement', this.props.auth) && !validateAccess(role.site, 'userManagement', this.props.auth)
          }
        />
        {validateAccess('hq', 'userManagement', this.props.auth) && this.state.newUser.Roles.length > 1 && (
          <a
            onClick={() => {
              this.removeSite(index);
            }}
          >
            <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 24, cursor: 'pointer' }} name="minus-circle" />
          </a>
        )}
      </div>
    );
  }

  renderRoles() {
    return (
      <div style={{ marginTop: 16, marginBottom: 32 }}>
        {_.map(this.state.newUser.Roles, (r, i) => {
          return this.renderRole(r, i);
        })}
        {this.renderAddRole()}
      </div>
    );
  }

  renderBasicInfo() {
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleLarge" className="marginBottom-24">
            User Profile
          </Text>
          <GenericInput
            id="displayName"
            type="text"
            label="Name"
            placeholder="John Doe"
            value={this.state.newUser.displayName}
            onChange={(e) => this.handleChange(e)}
            isRequired
            large
            alwaysShowLabel
          />
          <div style={{ width: 'fit-content' }}>
            {this.renderRoles()}
            {this.isAResident() && (
              <GenericInput
                id="unit"
                type="text"
                label="Address"
                placeholder="Unit 111"
                value={this.state.newUser.unit || ''}
                onChange={(e) => this.handleChange(e)}
                alwaysShowLabel
              />
            )}
            <GenericInput
              id="birthdayText"
              label="Birthday"
              placeholder="20/12/1960"
              value={this.state.newUser.birthdayText}
              onChange={(e) => this.handleDateTextChange(e.target.value)}
              onClick={(e) => this.setState({ showDate: !this.state.showDate })}
              alwaysShowLabel
            />
            {this.state.showDate && (
              <DatePicker
                selectedDate={this.state.newUser.birthday}
                // selectedDate={moment(this.state.newUser.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')}
                selectDate={this.handleDateChange.bind(this)}
              />
            )}
          </div>
          <div>
            <Text type="formTitleSmall" className="marginTop-32 marginBottom-16">
              Profile Picture
            </Text>
            <ImageInput ref="imageInput" hasDefault={this.state.newUser.profilePic} refreshCallback={this.setImage.bind(this)} noMenu />
          </div>
        </div>
      </div>
    );
  }

  renderAccountInfo() {
    return (
      <div className="padding-60 paddingVertical-40">
        <Text type="formTitleSmall" className="marginBottom-16">
          Profile Details
        </Text>
        <p style={{ marginBottom: 16 }} className={'text-sectionTitle fontSize-13 text-mid'}>
          Editing this information will affect user log in.
        </p>

        <div style={{ marginBottom: 24 }}>
          <div className={'fieldLabel marginBottom-5'}>Phone number</div>
          <PhoneInput
            id="phoneNumber"
            country={'au'}
            value={this.state.newUser.phoneNumber}
            onChange={(e) => this.handleOnPhoneChange(e)}
            countryCodeEditable={false}
            specialLabel={null}
            masks={{ au: '... ... ...' }}
          />
          <div className="genericInput-help">Must use +61 format - example: +61 400 000 000</div>
        </div>
        <GenericInput
          id="email"
          type="text"
          label="Email"
          placeholder="user@example.com"
          value={this.state.newUser.email}
          onChange={(e) => this.handleChange(e)}
          alwaysShowLabel
        />
      </div>
    );
  }

  handleFileChange(event) {
    const file = event.target.files[0];
    if (!file || this.state.uploadingImage) {
      return;
    }
    this.setState({
      uploadingImage: true,
    });
    fileActions
      .uploadMediaAsync(file, file.name)
      .then((fileRes) => {
        this.setState({
          newUser: { ...this.state.newUser, profilePic: get1400(fileRes) },
          uploadingImage: false,
        });
      })
      .catch((uploadErrorRes) => {
        this.setState({
          uploadingImage: false,
        });
      });
  }

  handleSubmit() {
    if (!this.validateInfoForm()) {
      this.setState({ showError: true });
      return;
    }

    const obj = {
      profilePic: this.state.newUser.profilePic,
      displayName: this.state.newUser.displayName,
      site: this.state.newUser.Roles[0].site,
      type: this.state.newUser.Roles[0].type,
      birthday: this.state.newUser.birthday,
      unit: this.state.newUser.unit,
      Roles: this.state.newUser.Roles,
    };

    if (_.isUndefined(obj.profilePic) || _.isEmpty(obj.profilePic)) {
      obj.profilePic = null;
    }

    if (!this.isAResident()) {
      obj.unit = '';
    }

    obj.phoneNumber = this.state.newUser.phoneNumber;
    obj.email = this.state.newUser.email.toLowerCase();

    if (obj.email === this.state.user.email) {
      delete obj.email;
    } else if (_.isEmpty(obj.email) && _.isEmpty(this.state.user.email)) {
      delete obj.email;
    } else if (_.isEmpty(obj.email)) {
      obj.email = null;
    }

    // check if removing phoneNumber entirely.
    if (this.state.newUser.phoneNumber.length < 4 && !_.isEmpty(this.state.user.phoneNumber)) {
      obj.phoneNumber = null;
    } else if (_.isEmpty(obj.phoneNumber) || obj.phoneNumber.length < 12 || obj.phoneNumber === this.state.user.phoneNumber) {
      delete obj.phoneNumber;
    } else if (!_.isEmpty(obj.phoneNumber)) {
      obj.phoneNumber = String(obj.phoneNumber).replace(/[^\d]/g, '');
      if (obj.phoneNumber.charAt(0) !== '+') {
        obj.phoneNumber = '+' + obj.phoneNumber;
      }
    }

    this.setState({ updating: true, loadError: false, error: null, showError: false });

    const extraPromises = [];
    extraPromises.push(this.saveECs());
    Promise.all(extraPromises);

    userActions
      .updateUser(this.props.auth.site, this.state.userId, obj)
      .then((res) => {
        if ((!_.isUndefined(res.data.success) && !res.data.success) || res.data.userCreationFail) {
          console.log('FAIL');
          if (res.data.message) {
            this.setState({ updating: false, error: res.data.message });
          } else {
            this.setState({ updating: false, error: 'Error on user edit' });
          }
        } else {
          this.parseUser(res.data.user, true);
          this.props.usersLoaded([res.data.user]);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log('fail on action call');
        this.setState({ updating: false, error: 'auth change on server fail -- try again' });
      });
  }

  handleAccountSubmit() {
    if (!this.validateAccountForm()) {
      return;
    }

    const obj = {
      email: this.state.newUser.email.toLowerCase(),
      phoneNumber: this.state.newUser.phoneNumber,
      // password: this.state.newPassword
    };

    if (obj.email === this.state.user.email) {
      delete obj.email;
    } else if (_.isEmpty(obj.email) && _.isEmpty(this.state.user.email)) {
      delete obj.email;
    } else if (_.isEmpty(obj.email)) {
      obj.email = null;
    }

    // if (_.isEmpty(obj.password) || obj.email === this.state.user.email) {
    //     delete obj.password;
    // }

    // check if removing phoneNumber entirely.
    if (this.state.newUser.phoneNumber.length === 1 && !_.isEmpty(this.state.user.phoneNumber)) {
      obj.phoneNumber = null;
    } else if (_.isEmpty(obj.phoneNumber) || obj.phoneNumber.length < 12 || obj.phoneNumber === this.state.user.phoneNumber) {
      delete obj.phoneNumber;
    } else if (!_.isEmpty(obj.phoneNumber)) {
      obj.phoneNumber = String(obj.phoneNumber).replace(/[^\d]/g, '');
      if (obj.phoneNumber.charAt(0) !== '+') {
        obj.phoneNumber = '+' + obj.phoneNumber;
      }
    }

    this.setState({ updating: true, loadError: false, error: null, accountError: null });

    userActions
      .updateUser(this.props.auth.site, this.state.userId, obj)
      .then((res) => {
        console.log('save success??? ');
        if ((!_.isUndefined(res.data.success) && !res.data.success) || res.data.userCreationFail) {
          console.log('FAIL');
          console.log(res.data);
          if (res.data.message) {
            this.setState({ updating: false, accountError: res.data.message });
          } else {
            this.setState({ updating: false, accountError: 'Error on user edit' });
          }
        } else {
          this.parseUser(res.data.user, true);
          this.props.usersLoaded([res.data.user]);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log('fail on action call');
        this.setState({ updating: false, accountError: 'fetch on server save -- try again' });
      });
  }

  renderInfoSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div className="flex flex-center">
        {this.state.error != null && (
          <div className="fontRegular" style={{ fontSize: 18, color: COLOUR_RED, fontWeight: 'bold', marginRight: 24 }}>
            {this.state.error}
          </div>
        )}
        <div>
          <Button
            inline
            buttonType="tertiary"
            onClick={() => {
              window.history.back();
            }}
            isActive
            style={{ marginRight: 16 }}
          >
            Back
          </Button>
          <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateInfoForm()}>
            Save
          </Button>
        </div>
      </div>
    );
  }

  renderAccountSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div className="flex flex-center">
        {this.state.accountError != null && (
          <div className="fontRegular" style={{ fontSize: 18, color: COLOUR_RED, fontWeight: 'bold', marginRight: 24 }}>
            {this.state.accountError}
          </div>
        )}
        <Button inline buttonType="primary" onClick={this.handleAccountSubmit.bind(this)} isActive={this.validateAccountForm()}>
          Save
        </Button>
      </div>
    );
  }

  removeUser() {
    if (this.state.submitting || _.isEmpty(this.state.userId) || !validateAccess(this.props.auth.site, 'disableUser', this.props.auth)) {
      return;
    }

    if (
      window.confirm(`Are you sure you want to disable this user?
                This will perform the following:
                User will not be searchable.
                Remove them from all future event slots they are assigned to.
                Remove all users from their contacts list.
                Remove this user from other users contact list.
                Remove user from all personal events.
        Remove user from all group chats.`)
    ) {
      this.setState({ showWarnings: false, error: false, submitting: true, goowoos: null });
      authActions
        .disableUser(this.state.userId, this.props.auth.site)
        .then((res) => {
          this.setState({
            submitting: false,
            goowoos: res.data,
          });
          this.props.usersLoaded([{ Id: this.state.userId, Deleted: true }]);
          window.history.back();
        })
        .catch((error) => {
          this.setState({
            submitting: false,
            accountError: 'Failed to disable user',
          });
        });
    }
  }

  renderDisableUser() {
    if (_.isUndefined(this.state.user)) {
      return null;
    }
    return (
      <div className="padding-32 paddingVertical-40 bottomDivideBorder">
        <div className="flex flex-between flex-center">
          <Text type="formTitleSmall" className="marginBottom-16">
            Disable User
          </Text>
          <Button
            buttonType="outlined"
            isActive={!this.state.loadingLinks && !this.state.viewCreator}
            style={{ marginBottom: 0 }}
            onClick={() => {
              this.removeUser();
            }}
          >
            Disable user
          </Button>
        </div>
        <div style={{ marginTop: 16, maxWidth: 360 }} className={'text-sectionTitle fontSize-13 text-mid'}>
          Warning: This action cannot be reversed.
        </div>
        <div style={{ maxWidth: 350 }} className={'text-sectionTitle fontSize-13 text-mid'}>
          This account will be disabled and the user will be removed from all upcoming events, all group conversations and will not be
          findable within the app.
        </div>
      </div>
    );
  }

  renderStatusSetting(status, type) {
    if (this.state[`sendingWelcome${type}`]) {
      return <FontAwesome style={{ fontSize: 30, color: COLOUR_BRANDING_OFF, marginTop: 10 }} name="spinner fa-pulse fa-fw" />;
    }
    if (!status || !status[type]) {
      return (
        <div style={{ maxWidth: 350 }} className={'welcomeemail fontSize-13 text-mid'}>
          <p className="text-sectionTitle fontSize-13 text-dark">
            <b>Welcome {type}</b>
          </p>
          <p>
            <FontAwesome className="fa-status-unknown userStatusIcon" name="question-circle" /> Welcome {type} status is unknown.
          </p>
          <p className="sendWelcome" onClick={type === 'Email' ? this.sendWelcomeEmail.bind(this) : this.sendWelcomeText.bind(this)}>
            Send Welcome {type}
          </p>
        </div>
      );
    }
    if (!status[type].Success) {
      return (
        <div style={{ maxWidth: 350 }} className={'welcomeemail fontSize-13 text-mid'}>
          <p className="text-sectionTitle fontSize-13 text-dark">
            <b>Welcome {type}</b>
          </p>
          <p>
            <FontAwesome className="fa-status-false userStatusIcon" name="times-circle" /> Welcome {type} failed to deliver to{' '}
            {status[type].Value}. Attempted at {moment.utc(status[type].Timestamp).local().format('D MMM YYYY h:mma')}.
          </p>
          <p className="sendWelcome" onClick={type === 'Email' ? this.sendWelcomeEmail.bind(this) : this.sendWelcomeText.bind(this)}>
            Send Welcome {type}
          </p>
        </div>
      );
    }
    return (
      <div style={{ maxWidth: 350 }} className={'welcomeemail fontSize-13 text-mid'}>
        <p className="text-sectionTitle fontSize-13 text-dark">
          <b>Welcome {type}</b>
        </p>
        <p>
          <FontAwesome className="fa-status-true userStatusIcon" name="check-circle" /> Welcome {type} sent to {status[type].Value} at{' '}
          {moment.utc(status[type].Timestamp).local().format('D MMM YYYY h:mma')}.
        </p>
        <p className="sendWelcome" onClick={type === 'Email' ? this.sendWelcomeEmail.bind(this) : this.sendWelcomeText.bind(this)}>
          Send Welcome {type}
        </p>
      </div>
    );
  }

  renderMessageStat() {
    return (
      <div className="padding-32 paddingVertical-40 bottomDivideBorder">
        <Text type="formTitleSmall" className="marginBottom-16">
          Status
        </Text>
        {this.renderStatusSetting(this.state.user.WelcomeStatus, 'Email')}
        {this.renderStatusSetting(this.state.user.WelcomeStatus, 'Text')}
        <div style={{ maxWidth: 350 }} className={'welcomeemail fontSize-13 text-mid'}>
          <p className="text-sectionTitle fontSize-13 text-dark">
            <b>Joined</b>
          </p>
          <p>
            <FontAwesome className="fa-status-unknown userStatusIcon" name="clock-o" />{' '}
            {moment.utc(this.state.user.signedUp).local().format('D MMM YYYY h:mma')}.
          </p>
          {this.state.user.invitingUser && (
            <p>
              <FontAwesome className="fa-status-unknown userStatusIcon" name="user-plus" /> Via invite code from{' '}
              <Link to={`/usershub/user/${this.state.user.invitingUser.id}`}>{this.state.user.invitingUser.displayName}</Link>.
            </p>
          )}
        </div>
        <div style={{ maxWidth: 350 }} className={'welcomeemail fontSize-13 text-mid'}>
          <p className="text-sectionTitle fontSize-13 text-dark">
            <b>Accepted Terms</b>
          </p>
          <p>
            <FontAwesome
              className={`fa-status-${this.state.user.onBoardingSeen} userStatusIcon`}
              name={this.state.user.onBoardingSeen ? 'check-circle' : 'times-circle'}
            />{' '}
            {this.state.user.onBoardingSeenTime
              ? moment.utc(this.state.user.onBoardingSeenTime).local().format('D MMM YYYY h:mma')
              : this.state.user.onBoardingSeen
              ? 'Yes'
              : 'Not yet accepted'}
          </p>
        </div>
        <div style={{ maxWidth: 350 }} className={'welcomeemail fontSize-13 text-mid'}>
          <p className="text-sectionTitle fontSize-13 text-dark">
            <b>Initial Password</b>
          </p>
          <p>
            <FontAwesome className={`fa-status-unknown userStatusIcon`} name="unlock-alt" />{' '}
            {this.state.user.initialPassword
              ? `The initial password is ${this.state.user.initialPassword}`
              : `The initial password is ${baseClient}${getFirstName(this.state.user.displayName)}`}
            .<br />
            Note: The password may have been changed since.
          </p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        {this.renderPNPopup()}
        <OverlayPageContents>
          <OverlayPageSection className="pageSectionWrapper--newPopup">{this.renderThingy()}</OverlayPageSection>
          {!this.state.loadError &&
            !this.state.loading &&
            !this.state.updating &&
            (this.isAResident() || validateAccess(this.props.auth.site, 'disableUser', this.props.auth)) && (
              <OverlayPageSection className="pageSectionWrapper--newPopupSide">
                {validateAccess(this.props.auth.site, 'disableUser', this.props.auth) && this.renderDisableUser()}

                {this.renderMessageStat()}
                <UserLinkInfo
                  types={this.state.types}
                  user={this.state.user}
                  userId={this.state.userId}
                  isAResident={this.isAResident.bind(this)}
                />
                {/* } */}
              </OverlayPageSection>
            )}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderInfoSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const styles = {
  retry: {
    color: '#fff',
    backgroundColor: COLOUR_BRANDING_MAIN,
    padding: 5,
    width: 120,
    textAlign: 'center',
    borderRadius: 10,
  },
  profilePic: {
    height: 120,
    width: 120,
    borderRadius: 60,
    marginRight: 20,
    marginBottom: 20,
    border: '1px solid #aaa',
    backgroundColor: '#ddd',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
};

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { usersLoaded })(withRouter(User));
