import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { userActions, linkedUserActions } from '../../../webapi';
import { getFirstName, isEmail } from '../../../helper';
import {
  DropdownInput,
  Button,
  GenericInput,
  DatePicker,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
} from '../../../components';
import { COLOUR_RED, LINEGREY } from '../../../js';
import Relationships from '../../../json/relationships.json';

class NewUserLink extends Component {
  state = {
    userName: '',

    showDate: false,
    userBirthday: moment('1900-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD'),
    birthdayText: moment('01/01/1900', 'DD/MM/YYYY').format('DD/MM/YYYY'),

    userPhone: '',
    userEmail: '',

    type: '',
    types: [],

    relationships: [],
    userRelationship: '',
    linkedRelationships: [],
    linkedUserRelationship: '',
    submitting: false,

    showNewWarnings: false,

    PageError: '',
  };

  UNSAFE_componentWillMount() {
    this.setTypes(this.props);
    this.loadRelationships();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setTypes(nextProps);
  }

  setTypes(props) {
    const newState = { types: [] };
    props.types.forEach((ev) => {
      if (!_.isUndefined(ev.category) && ev.category === 'family') {
        newState.types.unshift(ev);
      }
    });
    if (newState.types.length > 0) {
      if (this.state.type === '') {
        newState.type = newState.types[0].Key;
      }
      this.setState(newState);
    }
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  validateNewLink() {
    if (this.state.submitting) {
      return false;
    }
    if (this.state.userRelationship.length < 2) {
      return false;
    }
    if (this.state.linkedUserRelationship.length < 2) {
      return false;
    }
    if (this.state.userName.length < 2) {
      return false;
    }
    if (_.isEmpty(this.state.type)) {
      return false;
    }

    if (this.state.userEmail.length > 0 && !isEmail(this.state.userEmail)) {
      return false;
    }
    return true;
  }

  submitToUserInCreation(user, userExtra) {
    /**
     * Haven't quite thought this one out yet.
     *  Do second.
     */
  }

  submitToExistingResident(user, userExtra) {
    /***
     * If user.Id
     *      Pass new user data along for regular user creation.
     *      Pass along userToLink object and detect on backend.
     *      Perform regular linking procedure and refresh fetchLinkedUsers from props.
     */

    userActions
      .createNewUser(user, userExtra)
      .then((res) => {
        if (res.data.userCreationFail) {
          this.setState({
            PageError: res.data.message,
            submitting: false,
          });
        } else {
          linkedUserActions
            .addLink(
              res.data.user.Id,
              this.state.userRelationship,
              {
                id: this.props.userId,
                displayName: this.props.user.displayName,
                profilePic: !_.isUndefined(this.props.user.profilePicThumb) ? this.props.user.profilePicThumb : this.props.user.profilePic,
              },
              this.state.linkedUserRelationship,
            )
            .then((res) => {
              setTimeout(() => {
                this.props.onSuccess();
                this.props.close();
              }, 500);
            })
            .catch((error) => {
              console.log('error response - fetching');
              console.log(error);
              this.setState({
                PageError:
                  'An error occurred while linking new user.  Please close this window and try to link from the existing users option.',
                submitting: false,
              });
            });
        }
      })
      .catch((error) => {
        this.setState({ submitting: false, PageError: 'Something went wrong. Please try again.' });
        console.log(error);
        // console.log(error.response.data.error.message)
      });
  }

  handleSubmit() {
    if (!this.validateNewLink()) {
      this.setState({ showNewWarnings: true });
      return;
    }

    this.setState({ showNewWarnings: false, submitting: true, PageError: '' });
    var user = {
      profilePic: null,
      displayName: this.state.userName,
    };
    if (!_.isEmpty(this.state.userPhone) && this.state.userPhone !== '+') {
      user.phoneNumber = this.state.userPhone ? String(this.state.userPhone).replace(/[^\d]/g, '') : '';
      if (user.phoneNumber.charAt(0) !== '+') {
        user.phoneNumber = '+' + user.phoneNumber;
      }
    }
    if (!_.isEmpty(this.state.userEmail) && this.isEmail(this.state.userEmail)) {
      user.email = this.state.userEmail.toLowerCase();
      user.emailVerified = false;
    }
    var userExtra = {
      type: this.state.type,
      location: this.props.auth.site,
      birthday: this.state.userBirthday,
    };

    if (!_.isUndefined(this.props.user.Id) && !_.isEmpty(this.props.user.Id)) {
      // Create new user then submit linking
      this.submitToExistingResident(user, userExtra);
    } else {
      // Havn't thought out yet.  Start as a v2
      this.submitToUserInCreation(user, userExtra);
    }
  }

  handleDateTextChange(value) {
    const newU = { birthdayText: value };
    const m = moment(value, 'DD/MM/YYYY');

    if (m.isValid() && m.year() > 1900) {
      newU.userBirthday = m.format('YYYY-MM-DD');
    }
    this.setState(newU);
  }

  handleDateChange(date) {
    var stateChange = {
      userBirthday: date,
      birthdayText: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      showDate: false,
    };
    this.setState(stateChange);
  }

  handleOnPhoneChange(value) {
    this.setState({
      userPhone: `+${value}`,
    });
  }

  isEmail(email) {
    const atpos = email.indexOf('@');
    const dotpos = email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
      return false;
    }
    return true;
  }

  selectNewLink(user) {
    this.setState({ newLinkUser: user });
  }

  loadRelationships() {
    this.setState({ relationships: _.values(Relationships) });
  }

  selectUserRelationship(key, e) {
    const selected = _.find(this.state.relationships, { Key: key });
    this.setState({ userRelationship: selected.Title, linkedRelationships: selected.Options, linkedUserRelationship: '' });
  }

  selectLinkedUserRelationship(key, e) {
    const selected = _.find(this.state.linkedRelationships, { Key: key });
    this.setState({ linkedUserRelationship: selected.Title });
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="primary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            this.props.close();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button inline buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateNewLink()}>
          {`Create new user and link to ${getFirstName(this.props.user.displayName)}`}
        </Button>
      </div>
    );
  }

  getTypeTitle() {
    const typeObject = _.find(this.state.types, (t) => {
      return t.typeName === this.state.type;
    });
    if (typeObject) {
      return typeObject.displayName;
    }
    return '';
  }

  selectType(key, e) {
    this.setState({ type: key });
  }

  renderUserInfo() {
    return (
      <div style={{ flex: 1 }}>
        <div className="fontMedium fontSize-16 marginBottom-16">Basic User Information</div>
        <GenericInput
          id="userName"
          type="text"
          label={`User name`}
          placeholder="Robert Davis"
          value={this.state.userName}
          onChange={(e) => this.handleChange(e)}
          isRequired
          isValid={() => {
            return this.state.userName.length > 1;
          }}
          showError={() => {
            return this.state.showNewWarnings && this.state.userName.length < 2;
          }}
          // help={`${this.state.newLinkUser.displayName} may be a family member (Son, Daughter) or Carer to the resident.  `}
          disabled={this.state.submitting}
          alwaysShowLabel
        />
        <DropdownInput
          id="typeSelect"
          label="Type"
          placeholder="Type"
          value={this.getTypeTitle()}
          // isValid={() => { return this.validateCategory(); }}
          showError={() => {
            return this.state.showNewWarnings && _.isEmpty(this.state.type);
          }}
          options={this.state.types}
          onSelect={this.selectType.bind(this)}
        />
        <GenericInput
          id="birthdayText"
          label="Birthday"
          alwaysShowLabel
          placeholder="20/12/1960"
          value={this.state.birthdayText}
          onChange={(e) => this.handleDateTextChange(e.target.value)}
          onClick={(e) => this.setState({ showDate: !this.state.showDate })}
        />
        {this.state.showDate && (
          <DatePicker
            selectedDate={this.state.userBirthday}
            // selectedDate={moment(this.state.newUser.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')}
            selectDate={this.handleDateChange.bind(this)}
          />
        )}
        <p className="text-sectionTitle marginTop-32">CONTACT DETAILS</p>
        <div style={{ marginBottom: 8 }}>
          <div className={'fieldLabel marginBottom-5'}>Phone number - Must use +61 format example: +61 400 000 000</div>
          <PhoneInput
            id="userPhone"
            country={'au'}
            // value={this.state.userPhone}
            onChange={(e) => this.handleOnPhoneChange(e)}
            countryCodeEditable={false}
            specialLabel={null}
            masks={{ au: '... ... ...' }}
          />
        </div>
        <GenericInput
          id="userEmail"
          type="text"
          label="Email"
          placeholder="Email"
          value={this.state.userEmail}
          onChange={(e) => this.handleChange(e)}
          showError={() => {
            return this.state.showNewWarnings && this.state.userEmail.length > 0 && !isEmail(this.state.userEmail);
          }}
          errorMessage="Not a valid email"
        />
      </div>
    );
  }

  renderLinkingInfo() {
    return (
      <div style={{ flex: 1 }}>
        <div className="fontMedium fontSize-16 marginBottom-16">Linking Information</div>
        <DropdownInput
          id="userRelationship"
          label={`Relationship to ${getFirstName(this.props.user.displayName)}`}
          value={this.state.userRelationship}
          options={this.state.relationships}
          onSelect={this.selectUserRelationship.bind(this)}
          disabled={this.state.submitting}
        />
        <DropdownInput
          id="linkedUserRelationship"
          label={`${getFirstName(this.props.user.displayName)}'s relationship to new user`}
          value={this.state.linkedUserRelationship}
          options={this.state.linkedRelationships}
          onSelect={this.selectLinkedUserRelationship.bind(this)}
          disabled={this.state.submitting}
        />
      </div>
    );
  }

  renderForm() {
    return (
      <div style={{ padding: 32 }}>
        <div className="fontHeavy fontSize-18" style={{ textAlign: 'center', marginBottom: 32 }}>
          Add new user link to {this.props.user.displayName}
        </div>
        <div className="flex flex-around">
          {this.renderUserInfo()}
          <div style={{ width: 2, margin: '0 32px', height: 'auto', backgroundColor: LINEGREY }} />
          {this.renderLinkingInfo()}
        </div>
      </div>
    );
  }

  renderError() {
    return (
      <div style={{ marginTop: 24 }}>
        <div className="fontRegular" style={{ fontSize: 18, color: COLOUR_RED, fontWeight: 'bold', textAlign: 'center' }}>
          {this.state.PageError}
        </div>
      </div>
    );
  }

  render() {
    return (
      <OverlayPage style={{ width: '100%' }} backgroundStyle={{ backgroundColor: 'rgba(63,66,100,0.8)' }}>
        <OverlayPageContents style={{ margin: '0 auto', width: 800, backgroundColor: '#fff' }}>
          <OverlayPageSection className="pageSectionWrapper--newPopup" style={{ width: '100%' }}>
            {this.renderForm()}
            {this.renderError()}
            {/* {this.renderSuccess()} */}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons style={{ margin: '0 auto', width: 800 }}>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

// const styles = {
// };

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, {})(withRouter(NewUserLink));
