import { authedFunction } from '../session';
import { getUrl } from './helper';
import { automationConfig } from '../config';

export const automationActions = {
  getEnvironments: (repo) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getEnvironments', { repo }),
    });
  },
  createLiveEnvironment: (repo, name, base) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'createLiveEnvironment/create'),
      data: { repo, name, base },
    });
  },
  hasLiveEnvironment: (repo, name) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'createLiveEnvironment/exist'),
      data: { repo, name },
    });
  },
  deleteLiveEnvironment: (repo, name) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'deleteLiveEnvironment'),
      data: { repo, name },
    });
  },
  getEnvironmentConfigs: (repo, environment) => {
    return authedFunction({
      method: 'GET',
      url: encodeURI(getUrl('automation', 'getEnvironmentConfigs', { repo, environment })),
    });
  },
  getEnvironmentSites: (clientCode) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performEnvironmentTask/sites'),
      data: { clientCode },
    });
  },
  getBranchConfig: (repo, branch) => {
    return authedFunction({
      method: 'GET',
      url: encodeURI(getUrl('automation', 'getBranchConfig', { repo, branch })),
    });
  },
  updateEnvironmentConfigs: (repo, environment, configs) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'updateEnvironmentConfigs'),
      data: { repo, environment, configs },
    });
  },
  startDeployment: (data = { environment: '', repo: '', stack: '', merge: false }) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performBuildTask/start'),
      data,
    });
  },
  stopDeployment: (repo, buildId, pipelineId = '') => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performBuildTask/stop'),
      data: { repo, buildId, pipelineId },
    });
  },
  restartDeployment: (buildId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performBuildTask/restart'),
      data: { buildId },
    });
  },
  getLatestDeploy: (repo, environment) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getLatestDeploy', { repo, environment }),
    });
  },
  getDeployments: (repo) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getDeployments', { repo }),
    });
  },
  getAwsCoreStacks: (environment) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getAwsStacks', { environment, type: 'core' }),
    });
  },
  getAwsStacks: (environment) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getAwsStacks', { environment }),
    });
  },
  getWhiteLabel: (clientCode, rowId = '') => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWhiteLabelTask/get'),
      data: { clientCode, rowId },
    });
  },
  getAppInfo: (clientCode) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWhiteLabelTask/getapp', null, {
        url: automationConfig.whiteLabelUrl,
        stage: automationConfig.whiteLabelStage,
      }),
      data: { clientCode },
    });
  },
  getWhiteLabelList: () => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWhiteLabelTask/list'),
    });
  },
  updateWhiteLabel: (update) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWhiteLabelTask/update'),
      data: { update },
    });
  },
  deleteWhiteLabel: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWhiteLabelTask/delete'),
      data: { rowId },
    });
  },
  getBuildList: () => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getBuildQueue'),
    });
  },
  getBuild: (buildId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getBuildQueue', { buildId }),
    });
  },
  createAWSAccount: (rowId, name, email) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'createAWSAccount'),
      data: { rowId, name, email },
    });
  },
  setupAWS: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'setupAWS'),
      data: { rowId },
    });
  },
  getSourceImage: (repo, branch, path) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performRepositoryTask/image'),
      data: { repo, branch, path },
    });
  },
  setupWeb: (rowId, action) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', `performWebTask/${action}`),
      data: { rowId },
    });
  },
  initialiseTestUsers: (rowId, clientCode) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWebTask/initUsers'),
      data: { rowId, clientCode },
    });
  },
  resetTestUser: (rowId, clientCode, emailPrefix) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWebTask/resetUser'),
      data: { rowId, clientCode, emailPrefix },
    });
  },
  startAppBuild: (rowId, appClientCode, platform, submit = true, incrementVersion = true) => {
    const endPoint = platform === 'ios' ? 'performAppTaskIOS' : 'performAppTaskAndroid';
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', `${endPoint}/startBuild`),
      data: { rowId, appClientCode, submit, incrementVersion },
    });
  },
  checkAppBuild: (rowId, appClientCode, platform) => {
    const endPoint = platform === 'ios' ? 'performAppTaskIOS' : 'performAppTaskAndroid';
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', `${endPoint}/checkBuild`),
      data: { rowId, appClientCode },
    });
  },
  stopAppBuild: (rowId, appClientCode, platform) => {
    const endPoint = platform === 'ios' ? 'performAppTaskIOS' : 'performAppTaskAndroid';
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', `${endPoint}/stopBuild`),
      data: { rowId, appClientCode },
    });
  },
  setupGoogleService: (rowId, appClientCode, googleService) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskAndroid/googleService'),
      data: { rowId, appClientCode, googleService },
    });
  },
  setupCloudMessage: (rowId, appClientCode, cloudMessageToken) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskAndroid/cloudMessage'),
      data: { rowId, appClientCode, cloudMessageToken },
    });
  },
  listExtensions: (repo, branch) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performExtension/list'),
      data: { repo, branch },
    });
  },
  addExtension: (repo, branch, packageName, version, alias) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performExtension/add'),
      data: { repo, branch, packageName, version, alias },
    });
  },
  removeExtension: (repo, branch, packageName, alias) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performExtension/remove'),
      data: { repo, branch, packageName, alias },
    });
  },
  getEmailSender: (rowId, email) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getEmail/email', { rowId, email }),
    });
  },
  verifyEmailSender: (rowId, email) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'updateEmail/verify'),
      data: { rowId, email },
    });
  },
  getEmailService: (rowId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getEmail/service', { rowId }),
    });
  },
  requestEmailService: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'updateEmail/request'),
      data: { rowId },
    });
  },
};
