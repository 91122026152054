import { CHANGE_SITE, SET_AUTH, UPDATE_PROFILE } from '../actions/types';
import { setChatUser } from '../js';
import _ from 'lodash';
import { clearLocalStorage, readStorageWithCookie, setLocalStorage } from '../helper';

const INITIAL_STATE = {
  code: readStorageWithCookie('code'),
  access: readStorageWithCookie('access'),
  user: readStorageWithCookie('user', true),
  auth: readStorageWithCookie('auth'),
  site: readStorageWithCookie('site') || readStorageWithCookie('originalSite'),
  features: readStorageWithCookie('features', true),
  permissions: readStorageWithCookie('permissions', true),
  originalSite: readStorageWithCookie('originalSite'),
  chooken: readStorageWithCookie('chooken', true),
  viewChoice: readStorageWithCookie('viewChoice'),
  siteSettings: {},
  siteBranding: {},
  hidden: [],
  userTypes: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTH:
      Object.keys(action.payload).forEach((key) => {
        if (action.payload[key] == null) {
          clearLocalStorage(key);
        } else {
          if (key === 'user') {
            const clonedUser = _.cloneDeep(action.payload[key]);
            // delete clonedUser.Roles;
            setLocalStorage(key, clonedUser, true);
          } else {
            setLocalStorage(key, action.payload[key], typeof action.payload[key] === 'object');
          }
        }
      });
      const newState = {
        ...state,
        ...action.payload,
      };
      setChatUser(newState);
      return newState;
    case CHANGE_SITE:
      const changes = {
        ...action.payload,
        userTypes: [],
        features: [],
        permissions: [],
        siteBranding: {},
      };
      Object.keys(changes).forEach((key) => {
        setLocalStorage(key, changes[key], typeof changes[key] === 'object');
      });
      return {
        ...state,
        ...changes,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    default:
      return state;
  }
};
