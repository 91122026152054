import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { setNavData, setAuthUser } from '../actions';
import { Text } from './text';
import { ProfilePic } from './ProfilePic';
import { getCurrentUserType } from '../session';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { versionNumber } from '../config';

class SideMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  toggleSideMenu = () => {
    this.props.setNavData({ isSideMenuOpen: !this.props.isSideMenuOpen });
  };

  getTypeTitle = () => {
    return getCurrentUserType(this.props.auth).displayName;
  };

  logout = () => {
    this.props.setAuthUser(null, null, null);
    this.toggleSideMenu();

    Auth.signOut().then(() => {
      this.props.history.replace('/');
    });
  };

  renderSideMenuButton() {
    return (
      <div className={`sideMenuButton${this.props.isSideMenuOpen ? ' sideMenuButton-open' : ''}`} onClick={this.toggleSideMenu}>
        <div className="sideMenuButton_bar"></div>
        <div className="sideMenuButton_bar"></div>
        <div className="sideMenuButton_bar"></div>
      </div>
    );
  }

  render() {
    if (!this.props.auth || !this.props.auth.auth || this.props.hideSideMenu) {
      return null;
    }
    return (
      <div className={`sideMenu${this.props.isSideMenuOpen ? ' sideMenu-open' : ''}`}>
        <div className="sideMenu_overlay" onClick={this.toggleSideMenu}></div>
        <div className="sideMenu_menu">
          <div className="sideMenu_top">
            <Text type="h5">MENU</Text>
            <ProfilePic
              className="sideMenu_profilePic"
              size={60}
              image={this.props.auth && this.props.auth.user ? this.props.auth.user.profilePic : null}
            />
            <Text type="h3" className="sideMenu_name">
              {this.props.auth && this.props.auth.user ? this.props.auth.user.displayName : null}
            </Text>
            <Text type="h6" className="sideMenu_userType">
              {this.getTypeTitle()}
            </Text>
            <Link to="/profile" onClick={this.toggleSideMenu} className="text-h4 text-link sideMenu_link marginTop-60">
              Profile
            </Link>
            <Link to="/support" onClick={this.toggleSideMenu} className="text-h4 text-link sideMenu_link marginTop-20">
              Support
            </Link>
            {/* <Link to="/subscriptions" onClick={this.toggleSideMenu} className="text-h4 text-link sideMenu_link marginTop-20">
              Subscriptions
            </Link> */}
            <Button buttonType="outlined" onClick={this.logout} isActive className="marginTop-60">
              Log out
            </Button>
          </div>
          <div className="sideMenu_pattern">
            <div className="sideMenu_pattern_inner"></div>
            <p className="sideMenu_pattern_debugText">
              {this.props.auth.site}
              <br />
              {versionNumber}
            </p>
          </div>
        </div>
        {this.renderSideMenuButton()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, isSideMenuOpen: state.nav.isSideMenuOpen, hideSideMenu: state.nav.hideSideMenu };
};

const exportObj = connect(mapStateToProps, { setNavData, setAuthUser })(withRouter(SideMenu));

export { exportObj as SideMenu };
