import React, { Component } from 'react';
import queryString from 'querystring';
import _ from 'lodash';
import { Button } from '../../components';
import { paymentActions } from '../../webapi';
import { paymentConfig } from '../../config';

export default class AddCardCommBank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      sessionId: '',
      token: '',
      errors: '',
    };
    this.params = [];
    this.hideWidgetInterval = null;
  }

  UNSAFE_componentWillMount() {
    this.params = queryString.parse(this.props.location.search.replace(/^.*\?/, ''));
    // this.postMessage({ type: 'info', value: `configureSession - ${JSON.stringify(this.params)}` });
  }

  componentDidMount() {
    this.hideWidget();
    this.createPaymentSession();

    document.addEventListener('message', this.onMessage);
    window.addEventListener('message', this.onMessage);
  }

  hideWidget() {
    this.hideWidgetInterval = setInterval(() => {
      const widget = document.getElementById('tidio-chat');
      if (widget) {
        widget.hidden = true;
        if (!document.getElementById('tidio-chat')) {
          clearInterval(this.hideWidgetInterval);
        }
      }
    }, 100);
  }

  configureSessionScript = () => {
    const session = document.createElement('script');
    session.src = paymentConfig.commBankScript;
    session.async = true;
    session.onload = this.onSessionScriptLoaded;
    document.head.appendChild(session);
  };

  configureAntiClickjack = () => {
    const antiClickjack = document.createElement('style');
    antiClickjack.id = 'antiClickjack';
    antiClickjack.async = true;
    antiClickjack.innerHTML = 'body { display: none !important; }';
    document.head.appendChild(antiClickjack);

    const configureScript = document.createElement('script');
    configureScript.type = 'text/javascript';
    configureScript.async = true;
    configureScript.innerHTML =
      "if (self === top) { var antiClickjack = document.getElementById('antiClickjack'); antiClickjack.parentNode.removeChild(antiClickjack); } else { top.location = self.location; }";
    document.body.appendChild(configureScript);
  };

  createPaymentSession = () => {
    this.setState({ loading: true }, async () => {
      try {
        const res = await paymentActions.createSession(this.params.auth);
        if (res.data) {
          this.setState(
            {
              loading: false,
              sessionId: res.data.sessionId,
            },
            () => {
              this.configureSessionScript();
              this.configureAntiClickjack();
            },
          );
        }
      } catch (error) {
        this.postMessage({
          type: 'error',
          value: `createPaymentSession error - ${error}`,
        });
        this.setState({ loading: false });
      } finally {
        this.postMessage({ type: 'session', value: true });
      }
    });
  };

  onSessionScriptLoaded = () => {
    this.configureSession();
  };

  onMessage = (e) => {
    // console.log(`received message from app - ${JSON.stringify(e.data)}`);
  };

  configureSession = () => {
    if (!this.state.sessionId) return;
    window.PaymentSession.configure({
      session: this.state.sessionId,
      fields: {
        card: {
          number: '#card-number',
          securityCode: '#security-code',
          expiryMonth: '#expiry-month',
          expiryYear: '#expiry-year',
          nameOnCard: '#cardholder-name',
        },
      },
      frameEmbeddingMitigation: ['javascript'],
      callbacks: {
        initialized: this.onSessionInitialised,
        formSessionUpdate: this.onSessionUpdated,
      },
      interaction: {
        displayControl: {
          invalidFieldCharacters: 'REJECT',
        },
      },
    });
  };

  postMessage = (message) => {
    // console.log(message, 'ReactNativeWebView:', window.ReactNativeWebView);
    switch (message.type) {
      case 'error':
        this.handleErrors(message.value);
        break;
      case 'loading':
        this.setState({ loading: message.value, errors: '' });
        break;
      default:
        break;
    }

    if (!window.ReactNativeWebView) return;
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  };

  onSessionInitialised = (response) => {
    this.postMessage({ type: 'session', value: JSON.stringify(response) });
    if (response && response.status === 'ok') {
      this.setState({ token: this.params.token, auth: this.params.auth });
    }
  };

  onSessionUpdated = (response) => {
    this.postMessage({ type: 'loading', value: false });
    if (response.status) {
      if ('ok' === response.status) {
        this.savePaymentToken(response.sourceOfFunds.provided.card);
      } else if ('fields_in_error' === response.status) {
        this.postMessage({ type: 'error', value: response.errors });
      } else if ('request_timeout' === response.status) {
        this.postMessage({ type: 'error', value: response.errors.message });
      } else if ('system_error' === response.status) {
        this.postMessage({ type: 'error', value: response.errors.message });
      }
    } else {
      this.postMessage({ type: 'error', value: response });
    }
  };

  handleErrors = (errors) => {
    if (typeof errors === 'object') {
      let errorList = _.uniq(
        Object.keys(errors).map((value) => {
          switch (value) {
            case 'cardNumber':
              return `Card Number is ${errors[value]}`;
            case 'expiryMonth':
            case 'expiryYear':
              return `Expiry Date is ${errors[value]}`;
            default:
              return value;
          }
        }),
      );
      this.setState({ errors: errorList });
    } else {
      this.setState({ errors });
    }
  };

  onAddCard = () => {
    this.postMessage({ type: 'loading', value: true });
    window.PaymentSession.updateSessionFromForm('card');
  };

  savePaymentToken = async (card) => {
    const { sessionId, token, auth } = this.state;
    this.setState({ loading: true });
    try {
      this.postMessage({ type: 'loading', value: true });
      // this.postMessage({ type: 'savePaymentToken', value: `sessionId:${sessionId}, token:${token}, card:${card}, auth:${auth}` });
      const res = await paymentActions.savePaymentToken(sessionId, token, card, auth);
      if (res && res.data && res.data.token) {
        this.setState({
          loading: false,
          token: res.data.token,
        });
        this.postMessage({ type: 'token', value: res.data.token });
      }
    } catch (error) {
      this.postMessage({
        type: 'error',
        value: `savePaymentToken error - ${error}`,
      });
      this.setState({ loading: false });
    } finally {
      this.postMessage({ type: 'loading', value: false });
    }
  };

  renderErrors() {
    const { errors } = this.state;
    if (typeof errors === 'string') {
      return <p>{errors}</p>;
    }
    return errors.map((error, index) => {
      return <p key={index}>{error}</p>;
    });
  }

  render() {
    return (
      <div className="payment-container">
        <div className="payment-heading">Card Details</div>
        <div className="payment-error">{this.renderErrors()}</div>
        <div className="payment-field">
          <div className="label">Card Number</div>
          <input
            className="genericInput"
            type="text"
            id="card-number"
            title="card number"
            aria-label="enter your card number"
            value=""
            tabIndex="1"
            readOnly
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div className="payment-field" style={{ width: '50%' }}>
            <div className="label">Expiry Date</div>
            <div>
              <input
                className="genericInput"
                type="text"
                id="expiry-month"
                title="expiry month"
                aria-label="two digit expiry month"
                value=""
                tabIndex="2"
                readOnly
              />
              <span className="separator">&#160;&#160;&#160;&#47;&#160;&#160;</span>
              <input
                className="genericInput"
                type="text"
                id="expiry-year"
                title="expiry year"
                aria-label="two digit expiry year"
                value=""
                tabIndex="3"
                readOnly
              />
            </div>
          </div>
          <div className="payment-field">
            <div className="label">CVV Number</div>
            <div>
              <input
                className="genericInput"
                type="text"
                id="security-code"
                title="security code"
                aria-label="three digit CCV security code"
                value=""
                tabIndex="4"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="payment-field">
          <div className="label">Cardholder Name</div>
          <input
            className="genericInput"
            type="text"
            id="cardholder-name"
            title="cardholder name"
            aria-label="enter name on card"
            value=""
            tabIndex="5"
            readOnly
          />
        </div>
        <Button
          buttonType="primary"
          buttonClassName="payment-button"
          onClick={this.onAddCard}
          isActive={!this.state.loading}
          style={{ backgroundColor: this.params.colour }}
        >
          Add Card
        </Button>
      </div>
    );
  }
}
