import React, { Component } from 'react';
import { Auth } from 'aws-amplify'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { PageContainer } from '../../components';
import { setAuthUser } from '../../actions';

class Logout extends Component {
    componentDidMount() {
        setTimeout(() => {
            this.props.setAuthUser(null, null, null);
            Auth.signOut().then(() => {
                this.props.history.replace('/')
            });
        }, 500);
    }

    render() {
        return (
            <div>
                <PageContainer>
                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', flex: 1, fontSize: 30, marginTop: 80 }}>
                        logging out...
                    </div>
                </PageContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, { setAuthUser })(withRouter(Logout));
